// noinspection JSUnusedGlobalSymbols

export enum SearchKeys {
    // TurtleTrace
    // -------------

    // Format     : [platform]-[id]
    // Examples   : DOFFIN-2023-619507, TED-181265-2023
    noticeID = "noticeID",

    // Format     : [nation]-[id]
    // Examples   : BRREG-920472338, SE-202100-4284, DK-21262498
    orgNumber = "orgNumber",

    // Format     :
    // Examples   : "01", "01,02"
    cpvCategories = "cpvCategories",

    // Format     : enum PlatformID
    // Examples   : "TED", "TED,DOFFIN"
    excludedNoticePlatforms = "excludedNoticePlatforms",

    // Format     : enum ProcurementPlatformID
    // Examples   : "MERCELL", "EUSUPPLY,TENDSIGN"
    excludedProcurementPlatforms = "excludedProcurementPlatforms",

    // Format     : enum OrgRoleType
    // Examples   : "WINNER", "WINNER,BUYER"
    excludedRoles = "excludedRoles",

    // Format     : enum ContractType
    // Examples   : "SERVICES", "WORKS,SUPPLIES"
    excludedContractTypes = "excludedContractTypes",

    // Doffin Search
    // -------------

    // Format     :
    // Examples   :
    searchString = "searchString",

    // Format     : pagination
    // Examples   : 1
    pageNotices = "pageNotices",

    // Format     :
    // Examples   : 50
    // Doffin API : numHitsPerPage
    rowsPerPageNotices = "rowsPerPageNotices",

    // Format     :
    // Examples   : DEADLINE, RELEVANCE, PUBLICATION_DATE_ASC, PUBLICATION_DATE_DESC, ESTIMATED_VALUE_ASC, ESTIMATED_VALUE_DESC
    sortBy = "sortBy",

    // Format     :
    // Examples   : PLANNING, NOTICE_ON_BUYER_PROFILE, ADVISORY_NOTICE, PRE_ANNOUNCEMENT, COMPETITION,
    // Examples   : ANNOUNCEMENT_OF_COMPETITION, DYNAMIC_PURCHASING_SCHEME, QUALIFICATION_SCHEME, RESULT,
    // Examples   : ANNOUNCEMENT_OF_INTENT, ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT, CHANGE_OF_CONCLUSION_OF_CONTRACT,
    // Examples   : CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT
    type = "type",

    // Format     : ProcurementStatus
    // Examples   : ACTIVE, EXPIRED, AWARDED, CANCELLED
    status = "status",

    // Format     :
    // Examples   : SERVICES
    contractNature = "contractNature",

    // Format     :
    // Examples   : 72000000, 72200000
    // Doffin API : cpvCode
    cpvCodesId = "cpvCodesId",

    // Format     :
    // Examples   : anyw
    location = "location",

    // Format     : yyyy-mm-dd
    // Examples   :
    issueDateFrom = "issueDateFrom",

    // Format     : yyyy-mm-dd
    // Examples   :
    issueDateTo = "issueDateTo",

    // Format     :
    // Examples   :
    estimatedValueFrom = "estimatedValueFrom",

    // Format     :
    // Examples   :
    estimatedValueTo = "estimatedValueTo",
}

export function toSearchKey(needle: string): SearchKeys | null {
    for (let item in SearchKeys) {
        if (needle === item) return item as SearchKeys
    }
    return null
}
