import {Card, CardContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {orgName, OrganizationRecord} from 'src/types/OrganizationRecordList';
import * as React from "react";
import {BrregCard} from "./BrregCard";
import {ProffNoChip} from "./ProffNoChip";
import {OrgNumber} from "../../types/ProcurementNoticeList";

function OrganizationDescriptionCard(props: { organization: OrganizationRecord, orgNumber?: OrgNumber | null }) {
    const organization = props.organization;
    let id = organization.nationalID?.replace(organization.registerID+"-", "")
    return (
        <Card variant="outlined">
            <CardContent>
                <Stack sx={{
                    alignItems: "left",
                    gap: 1, wordBreak: "break-word"
                }}>
                    {organization.country && <>
                        <Typography>
                            Country : {organization.country}
                        </Typography>
                    </>}
                    {props.orgNumber?.registerID === "BRREG" &&
                        <BrregCard/>
                    }
                    {props.orgNumber?.registerID !== "BRREG" &&
                        <>
                            <Typography>
                                Name : {orgName(organization)}
                            </Typography>
                            {organization.town &&
                                <Typography variant="body1">
                                    Address : {organization.street} {organization.postcode} {organization.town}
                                </Typography>
                            }
                        </>
                    }
                    {id &&
                        <ProffNoChip orgNumber={props.orgNumber}/>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
}

export default OrganizationDescriptionCard;