import React, {useEffect} from "react";

import {Box} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import {CpvCategories} from "./CpvCategories";
import {Notices} from "./Notices";
import {Orgs} from "./Orgs";
import {TabList, TabPanel} from "@mui/lab";
import {PageKeys} from "../types/PageKeys";
import {useLocation, useNavigate, useSearchParams} from "react-router";

enum TabID {
    Notices = "Notices",
    Orgs = "Orgs",
    Cpvs = "Cpvs"
}

export default function Home() {
    const [tabID, setTabID] = React.useState(TabID.Notices);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    // Navigate to the new page
    useEffect(() => {
        switch(tabID) {
            case TabID.Notices:
                navigate(`/${PageKeys.notices}?${params.toString()}`)
                break
            case TabID.Orgs:
                navigate(`/${PageKeys.orgs}?${params.toString()}`)
                break
            case TabID.Cpvs:
                navigate(`/${PageKeys.cpvs}?${params.toString()}`)
                break
        }
    }, [tabID, navigate, params])

    // Update the tab to make it active
    useEffect(() => {
        switch(location.pathname) {
            case '/':
                setTabID(TabID.Notices)
                break
            case `/${PageKeys.orgs}`:
                setTabID(TabID.Orgs)
                break
            case `/${PageKeys.notices}`:
                setTabID(TabID.Notices)
                break
            case `/${PageKeys.cpvs}`:
                setTabID(TabID.Cpvs)
                break
        }
    }, [location])

    return (<Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={tabID}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList
                        onChange={(_event: React.SyntheticEvent, newValue: TabID) => {
                            setTabID(newValue);
                        }}
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="fullWidth"
                        aria-label="tabs"
                    >
                        <Tab
                            icon={<StorefrontTwoToneIcon/>}
                            label="Orgs"
                            value={TabID.Orgs}
                        />
                        <Tab
                            icon={<AnnouncementTwoToneIcon/>}
                            label="Notices"
                            value={TabID.Notices}
                        />
                        <Tab
                            icon={<SellTwoToneIcon/>}
                            label="CPVs"
                            value={TabID.Cpvs}
                        />
                    </TabList>
                </Box>
                <TabPanel value={TabID.Orgs}>
                    <Orgs/>
                </TabPanel>
                <TabPanel value={TabID.Notices}>
                    <Notices/>
                </TabPanel>
                <TabPanel value={TabID.Cpvs}>
                    <CpvCategories/>
                </TabPanel>
            </TabContext>
        </Box>);
}
