import {Autocomplete, Avatar, Box, Chip, Stack, TextField, Tooltip, useTheme} from "@mui/material";
import * as React from "react";
import {useParams} from "../../hooks/searchParams.hooks";
import {SearchKeys} from "../../types/SearchKeys";
import Typography from "@mui/material/Typography";
import {
    getProcurementPlatformsFromString, procurementPlatform, ProcurementPlatform,
    ProcurementPlatformID,
    procurementPlatforms
} from "../../types/ProcurementPlatform";
import CloudOffTwoToneIcon from "@mui/icons-material/CloudOffTwoTone";

export function getProcurementPlatformIcon(platformID: ProcurementPlatformID, size: number, isDarkMode: boolean, link: string | null = null) {
    let platform = procurementPlatform(platformID);
    let iconUrl = platform.icon
    let borderColor = platform.borderColor
    let foregroundColor = isDarkMode ? 'black' : 'white';
    let backgroundColor = platform.backgroundColor;
    let borderWidth = '1.2px';
    if (iconUrl && link) {
        return (
            <Avatar
                src={iconUrl}
                sx={{
                    bgcolor: backgroundColor,
                    width: size,
                    height: size
                }}
                style={{
                    border: borderWidth + ' solid ' + borderColor
                }}
                component="a"
                target="_blank"
                href={link ?? ""}
            />
        )
    } else if (iconUrl && !link) {
        return (
            <Avatar
                src={iconUrl}
                sx={{
                    bgcolor: backgroundColor,
                    width: size,
                    height: size
                }}
                style={{
                    border: borderWidth + ' solid ' + borderColor
                }}
            />
        )
    } else {
        return (
            <Avatar
                sx={{
                    width: size,
                    height: size,
                    bgcolor: backgroundColor,
                }}
                style={{
                    border: borderWidth + ' solid ' + borderColor,
                    color: foregroundColor
                }}
            >
                <CloudOffTwoToneIcon sx={{fontSize: 18}}/>
            </Avatar>
        )
    }
}

export function ProcurementPlatformField() {
    const [platforms, setPlatforms, clearPlatforms] = useParams<string>(SearchKeys.excludedProcurementPlatforms, null);
    const selectedValues = React.useMemo(
        () => getProcurementPlatformsFromString(platforms),
        [platforms],
    );
    const isDarkTheme = useTheme().palette.mode === 'dark';

    function changePlatforms(ids: ProcurementPlatform[] | null) {
        if (!ids || ids.length === 0) {
            clearPlatforms()
        } else {
            let procurementPlatformID = new Set(ids.map(value => value.id))
            setPlatforms(Array.from(procurementPlatformID).join(','))
        }
    }

    return <Autocomplete
        key="ProcurementPlatformField"
        sx={{
            flex: 5,
            minWidth: 0,
            alignSelf: "stretch",
            "& .MuiOutlinedInput-root": {
                paddingRight: "15px!important",
            },
        }}
        multiple
        disableClearable
        id="procurement-platforms"
        options={procurementPlatforms}
        // Controlled Autocomplete needs to deal with value
        // https://mui.com/material-ui/react-autocomplete/#controlled-states
        value={selectedValues}
        onChange={(_event, selectedRecords: ProcurementPlatform[] | null) => {
            changePlatforms(selectedRecords)
        }}
        defaultValue={getProcurementPlatformsFromString(platforms)}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.title}
        renderTags={(value: readonly ProcurementPlatform[], getTagProps) =>
            value.map((option: ProcurementPlatform, index: number) => {
                const {key, ...tagProps} = getTagProps({index});
                return (
                    <Tooltip title={option.title} key={option.id} arrow>
                        <Chip
                            {...tagProps}
                            key={option.id}
                            variant="outlined"
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    padding: "1px",
                                    alignSelf: "center",
                                },
                                justifyContent: 'unset'
                            }}
                            avatar={ getProcurementPlatformIcon(option.id, 30, isDarkTheme)}
                        />
                    </Tooltip>
                );
            })
        }
        renderOption={(props, option) => {
            const {key, ...optionProps} = props;
            return (
                <Box
                    {...optionProps}
                    key={option.id}
                    component="li"
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            alignItems: "left",
                            gap: 1,
                        }}
                    >
                        { getProcurementPlatformIcon(option.id, 30, isDarkTheme)}
                        <Typography style={{alignSelf: "center"}}>
                            {option.title}
                        </Typography>
                    </Stack>
                </Box>
            );
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label={(platforms) ? "Excluded Procurement Platforms" : "All Proc. Platforms"}
                placeholder="Search"
            />
        )}
    />
}
