import React from "react";
import {Avatar, Tooltip} from "@mui/material";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import AgricultureTwoToneIcon from "@mui/icons-material/AgricultureTwoTone";
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import BrushTwoToneIcon from '@mui/icons-material/BrushTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import SportsMmaTwoToneIcon from '@mui/icons-material/SportsMmaTwoTone';
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import ShuffleTwoToneIcon from '@mui/icons-material/ShuffleTwoTone';
import FastRewindTwoToneIcon from '@mui/icons-material/FastRewindTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import CleaningServicesTwoToneIcon from '@mui/icons-material/CleaningServicesTwoTone';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import MilitaryTechTwoToneIcon from '@mui/icons-material/MilitaryTechTwoTone';
import WindowTwoToneIcon from '@mui/icons-material/WindowTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import WindPowerTwoToneIcon from '@mui/icons-material/WindPowerTwoTone';
import {
    blueGrey, cyan, indigo,
    lightGreen, pink, teal,
    yellow
} from "@mui/material/colors";

export function isAward(formTypes: string[]) {
    if (formTypes.includes("CONTRACT_AWARD")) return true
    if (formTypes.includes("CONCESSION_AWARD_NOTICE")) return true
    if (formTypes.includes("PUBLIC_WORKS_CONCESSION_AWARD")) return true
    return formTypes.includes("UTILITIES_CONTRACT_AWARD_NOTICE");
}

export function FormTypeAvatar(props: { formTypes: string[], size?: number }) {

    function formType() {
        const formTypes = props.formTypes
        if (formTypes.length === 0) return "";
        return formTypes[0].replace(/_/g, " ")
    }

    function getAvatarColor() {
        const formType = props.formTypes[0]

        let contractNoticeColor = lightGreen[600];
        let contractAwardColor = yellow[700];
        let infoColor = blueGrey[600];
        let corrColor = indigo[600];

        let dpsColor = teal[500];
        let qsColor = cyan[900];

        switch (formType) {
            case "CONTRACT_NOTICE":
                return contractNoticeColor
            case "CONTRACT_AWARD":
                return contractAwardColor
            case "ADDITIONAL_INFORMATION_CORRIGENDUM":
                return corrColor
            case "PREQUALIFICATION":
                return infoColor
            case "NOTICE":
                return contractNoticeColor
            case "DESIGN_CONTEST":
                return contractNoticeColor
            case "DESIGN_CONTEST_RESULT":
                return contractAwardColor
            case "DYNAMIC_PURCHASING_SYSTEM":
                return dpsColor
            case "QUALIFICATION_SYSTEM_WITH_COMPETITION":
                return qsColor
            case "GENERAL_INFORMATION":
                return infoColor
            case "QUALIFICATION_SYSTEM_WITHOUT_COMPETITION":
                return qsColor
            case "PERIODIC_INDICATIVE_NOTICE_WITH_COMPETITION":
                return contractNoticeColor
            case "PERIODIC_INDICATIVE_NOTICE_WITHOUT_COMPETITION":
                return infoColor
            case "PRIOR_INFORMATION_NOTICE_WITH_COMPETITION":
                return contractNoticeColor
            case "PRIOR_INFORMATION_NOTICE_WITHOUT_COMPETITION":
                return infoColor
            case "EUROPEAN_COMPANY":
                return infoColor
            case "PUBLIC_WORKS_CONCESSION":
                return contractNoticeColor
            case "CONCESSION_NOTICE":
                return contractNoticeColor
            case "PUBLIC_WORKS_CONCESSION_AWARD":
                return contractAwardColor
            case "SERVICES_CONCESSION":
                return contractNoticeColor
            case "CALL_FOR_EXPRESSION_OF_INTEREST":
                return infoColor
            case "CONCESSION_AWARD_NOTICE":
                return contractAwardColor
            case "VOLUNTARY_EX_ANTE_TRANSPARENCY_NOTICE":
                return contractAwardColor
            case "BUYER_PROFILE":
                return infoColor
            case "MODIFICATION_OF_CONTRACT":
                return infoColor
            case "UTILITIES_CONTRACT_NOTICE":
                return contractNoticeColor
            case "UTILITIES_CONTRACT_AWARD_NOTICE":
                return contractAwardColor
            case "UTILITIES_PRIOR_INFORMATION_NOTICE_WITHOUT_COMPETITION":
                return infoColor
            case "UTILITIES_PRIOR_INFORMATION_NOTICE_WITH_COMPETITION":
                return contractNoticeColor
            case "UNKNOWN":
                return pink[600]
            default:
                return pink[500]
        }
    }

    function formTypeIcon() {
        const formTypes = props.formTypes
        const iconSize = "medium"
        const type = formTypes[0]
        if (formTypes.length === 0) return <QuestionMarkTwoToneIcon fontSize={iconSize}/>;
        if (type === "CONTRACT_NOTICE") return <DescriptionTwoToneIcon fontSize={iconSize}/>
        if (type === "CONTRACT_AWARD") return <EmojiEventsTwoToneIcon fontSize={iconSize}/>
        if (type === "ADDITIONAL_INFORMATION_CORRIGENDUM") return <InfoTwoToneIcon fontSize={iconSize}/>
        if (type === "PREQUALIFICATION") return <AgricultureTwoToneIcon fontSize={iconSize}/>
        if (type === "NOTICE") return <UpdateTwoToneIcon fontSize={iconSize}/>
        if (type === "DESIGN_CONTEST") return <BrushTwoToneIcon fontSize={iconSize}/>
        if (type === "DESIGN_CONTEST_RESULT") return <DesignServicesTwoToneIcon fontSize={iconSize}/>
        if (type === "DYNAMIC_PURCHASING_SYSTEM") return <ShoppingCartTwoToneIcon fontSize={iconSize}/>
        if (type === "QUALIFICATION_SYSTEM_WITH_COMPETITION") return <SportsMmaTwoToneIcon fontSize={iconSize}/>
        if (type === "GENERAL_INFORMATION") return <MedicalInformationTwoToneIcon fontSize={iconSize}/>
        if (type === "QUALIFICATION_SYSTEM_WITHOUT_COMPETITION") return <PostAddTwoToneIcon fontSize={iconSize}/>
        if (type === "PERIODIC_INDICATIVE_NOTICE_WITH_COMPETITION") return <SportsMmaTwoToneIcon fontSize={iconSize}/>
        if (type === "PERIODIC_INDICATIVE_NOTICE_WITHOUT_COMPETITION") return <ShuffleTwoToneIcon fontSize={iconSize}/>
        if (type === "PRIOR_INFORMATION_NOTICE_WITH_COMPETITION") return <SportsMmaTwoToneIcon fontSize={iconSize}/>
        if (type === "PRIOR_INFORMATION_NOTICE_WITHOUT_COMPETITION") return <FastRewindTwoToneIcon fontSize={iconSize}/>
        if (type === "EUROPEAN_COMPANY") return <PublicTwoToneIcon fontSize={iconSize}/>
        if (type === "PUBLIC_WORKS_CONCESSION") return <GroupTwoToneIcon fontSize={iconSize}/>
        if (type === "CONCESSION_NOTICE") return <ReceiptLongTwoToneIcon fontSize={iconSize}/>
        if (type === "PUBLIC_WORKS_CONCESSION_AWARD") return <GroupTwoToneIcon fontSize={iconSize}/>
        if (type === "SERVICES_CONCESSION") return <CleaningServicesTwoToneIcon fontSize={iconSize}/>
        if (type === "CALL_FOR_EXPRESSION_OF_INTEREST") return <AddIcCallTwoToneIcon fontSize={iconSize}/>
        if (type === "CONCESSION_AWARD_NOTICE") return <MilitaryTechTwoToneIcon fontSize={iconSize}/>
        if (type === "VOLUNTARY_EX_ANTE_TRANSPARENCY_NOTICE") return <WindowTwoToneIcon fontSize={iconSize}/>
        if (type === "BUYER_PROFILE") return <AccountBoxTwoToneIcon fontSize={iconSize}/>
        if (type === "MODIFICATION_OF_CONTRACT") return <HistoryEduTwoToneIcon fontSize={iconSize}/>
        if (type === "UTILITIES_CONTRACT_NOTICE") return <WindPowerTwoToneIcon fontSize={iconSize}/>
        if (type === "UTILITIES_CONTRACT_AWARD_NOTICE") return <WindPowerTwoToneIcon fontSize={iconSize}/>
        if (type === "UTILITIES_PRIOR_INFORMATION_NOTICE_WITHOUT_COMPETITION") return <WindPowerTwoToneIcon fontSize={iconSize}/>
        if (type === "UTILITIES_PRIOR_INFORMATION_NOTICE_WITH_COMPETITION") return <WindPowerTwoToneIcon fontSize={iconSize}/>
        if (type === "UNKNOWN") return <QuestionMarkTwoToneIcon fontSize={iconSize}/>
        return <QuestionMarkTwoToneIcon fontSize={iconSize}/>
    }

    const chipSize = props.size ?? 32
    return (
        <Tooltip title={formType()} arrow>
            <Avatar
                alt={formType()}
                sx={{
                    bgcolor: getAvatarColor(),
                    width: chipSize,
                    height: chipSize,
                }}
            >
                {formTypeIcon()}
            </Avatar>
        </Tooltip>
    )
}
