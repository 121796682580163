import React, {useEffect, useState} from 'react';
import {Avatar, AvatarGroup, Box, Card, CardContent, CardHeader, LinearProgress, Link, Stack} from "@mui/material";
import {getNotice} from "../api/ProcurementSystemApi";
import {
    nameFromBuyer, formatLocalizedString,
    getNoticeBuyerAddress,
    getNoticeID,
    getOfficialBuyerAddress,
    getProfileBuyerAddress, noticePlatformLink,
    ProcurementNotice, toNoticeID
} from "../types/ProcurementNoticeList";
import Typography from "@mui/material/Typography";
import {getBuyerIcon} from "../components/EventIcon";
import LotsCard, {hasLots} from "../components/Cards/LotsCard";
import AmountsCard from "../components/Cards/AmountsCard";
import CpvCodesCard from "../components/Cards/CpvCodesCard";
import DescriptionCard, {hasDescription} from "../components/Cards/DescriptionCard";
import Grid from '@mui/material/Grid';
import WinnerCard, {hasWinners} from "../components/Cards/WinnerCard";
import TimelineCard from "../components/Cards/TimelineCard";
import {AddressCard, hasAddress} from "../components/Cards/AddressCard";
import {ContactPointCard, hasContactPoint} from "../components/Cards/ContactPointCard";
import LinksCard, {hasLinks} from "../components/Cards/LinksCard";
import {FlagAvatar} from "../components/Icons/FlagAvatar";
import {FormTypeAvatar} from "../components/Icons/FormTypeAvatar";
import {SearchKeys} from "../types/SearchKeys";
import {PageKeys} from "../types/PageKeys";
import {useParams} from "../hooks/searchParams.hooks";
import {ContractTypeAvatar} from "../components/Icons/ContractTypeAvatar";
import {StatusTypeAvatar} from "../components/Icons/StatusTypeAvatar";
import {ContractingSystemAvatar} from "../components/Icons/ContractingSystemAvatar";

function getBuyerOrgNumber(result: ProcurementNotice) {
    const buyer = result.buyers[0]
    const nationalID = buyer.id.nationalID
    if (!nationalID) return null
    if (!nationalID?.registerID) return null
    if (!nationalID?.id) return null
    return nationalID?.registerID + "-" + nationalID?.id
}

function Notice() {
    const [noticeId] = useParams<string>(SearchKeys.noticeID, null);
    const [orgNumber] = useParams<string>(SearchKeys.orgNumber, null);

    const [searchInProgress, setSearchInProgress] = React.useState<boolean>(true);
    const [result, setResult] = useState<ProcurementNotice | undefined>(undefined);

    useEffect(() => {
        // Start search call if we haven't gotten an answer, and we haven't already asked
        if (noticeId && !result && searchInProgress) {
            getNotice(noticeId)
                .then((data) => {
                    let notice = data.hits[0];
                    setResult(notice);

                })
                .finally(() => {
                    // Search is no longer in progress
                    setSearchInProgress(false)
                });
        }
    }, [noticeId, result, searchInProgress]);

    let nameOfBuyer = nameFromBuyer(result?.buyers[0]);

    let buyerOrgNumber = (result) ? getBuyerOrgNumber(result) : null;
    let noticeID = toNoticeID(noticeId);
    let href = noticePlatformLink(noticeID);
    return (
        <div>
            {searchInProgress && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
            {!searchInProgress && !result && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    {href && (
                        <Stack
                            sx={{
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography>
                                Notice not available on TurtleTrace yet, try on {noticeID!!.platform}
                            </Typography>
                            <Link href={href} underline="always" target="_blank">
                                {noticeId}
                            </Link>
                        </Stack>
                    )}
                    {!href && (
                        <Typography sx={{alignSelf: 'center'}}>
                            Notice not recognized: '{noticeId}'
                        </Typography>
                    )}
                </Box>
            )}
            {result && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Card variant={"outlined"}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    alt={nameOfBuyer}
                                    src={getBuyerIcon(nameOfBuyer)}>
                                    {nameOfBuyer.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            title={
                                <Typography component={"p"} sx={{wordBreak: "break-word"}} variant={"h4"}>
                                    {getNoticeID(result)} : {formatLocalizedString(result.title, false).at(0)}
                                </Typography>
                            }
                            // subheader={`${nameOfBuyer}`}
                            subheader={
                                <>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center"
                                        }}
                                    >
                                        {buyerOrgNumber && (
                                            <Link href={`/${PageKeys.organization}?${SearchKeys.orgNumber.toString()}=${buyerOrgNumber}`} underline="always">
                                                {`${nameOfBuyer}`}
                                            </Link>
                                        )}
                                        {!buyerOrgNumber && (
                                            <Typography>
                                                {`${nameOfBuyer}`}
                                            </Typography>
                                        )}
                                        <AvatarGroup spacing="small" max={99}>
                                            <ContractingSystemAvatar metaData={result.metaData} key={"contracting-system"}/>
                                            <StatusTypeAvatar status={result.status} role={null} key={"status"}/>
                                            <FormTypeAvatar formTypes={result.metaData.formTypes} key={"form-type"}/>
                                            <ContractTypeAvatar metaData={result.metaData} key={"contract-type"}/>
                                        </AvatarGroup>
                                        <FlagAvatar countryCode={result.country}/>
                                    </Stack>
                                </>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={7}>
                                    <Stack gap={2} direction="column">
                                        {hasDescription(result) &&
                                            <DescriptionCard notice={result}></DescriptionCard>
                                        }
                                        {hasLots(result.noticeLots) &&
                                            <LotsCard lots={result.noticeLots!!}></LotsCard>
                                        }
                                        {hasWinners(result.noticeLots)  &&
                                            <WinnerCard winners={result.noticeLots!!} orgNumber={orgNumber}/>
                                        }
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2.5}>
                                    <Stack gap={2} direction={"column"}>
                                        <TimelineCard result={result}/>
                                        {hasLinks(result) &&
                                            <LinksCard notice={result}/>
                                        }
                                        {hasAddress(result) && (
                                            <AddressCard buyerOfficialAddress={getOfficialBuyerAddress(result)}
                                                         buyerNoticeAddress={getNoticeBuyerAddress(result)}
                                                         buyerProfileAddress={getProfileBuyerAddress(result)}/>
                                        )}
                                        {hasContactPoint(result) && (
                                            <ContactPointCard contactPoint={result.buyers[0].contactPoint!!}/>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2.5} gap={2} zeroMinWidth>
                                    <Stack gap={2} direction={"column"}>
                                        {result.amounts &&
                                            <AmountsCard amounts={result.amounts}/>
                                        }
                                        {result.productClassification &&
                                            <CpvCodesCard classification={result.productClassification}/>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </div>
    );
}

export default Notice;