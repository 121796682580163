import * as React from 'react';
import {useEffect, useState} from 'react';

import {
    DataGrid,
    GridColDef,
    gridFilteredSortedRowIdsSelector,
    gridPageCountSelector,
    gridRowCountSelector,
    GridToolbarContainer,
    GridToolbarProps,
    PaginationPropsOverrides,
    useGridApiRef,
    useGridSelector
} from '@mui/x-data-grid';
import {
    AvatarGroup,
    LabelDisplayedRowsArgs,
    Link,
    Pagination,
    SpeedDial,
    SpeedDialAction,
    Stack,
    TablePagination,
    TablePaginationProps,
    Tooltip,
    useTheme
} from "@mui/material";
import {
    Buyer,
    nameFromBuyer,
    compareProductClassifications,
    ContractType,
    CpvCode,
    cpvCodes,
    cpvUniqueByLabel,
    formatLocalizedString,
    getContractTypes,
    getContractTypesFromString,
    getDescription,
    getLanguageString,
    getOrgRoleTypesFromString,
    nameOrgNumber,
    NoticeID,
    noticeID,
    noticeIdName,
    NoticeLot,
    NoticeMetaData,
    OrgRoleType,
    PlatformID,
    ProcurementNotice,
    ProductClassification,
    toNumber
} from "../types/ProcurementNoticeList";
import useMediaQuery from "@mui/material/useMediaQuery";
import {BuyerAvatar} from "./BuyerAvatar";
import {FormTypeAvatar, isAward} from "./Icons/FormTypeAvatar";
import {FlagAvatar} from "./Icons/FlagAvatar";
import {getHighAmount} from "./Cards/AmountsCard";
import {CpvButton} from "./Icons/CpvButton";
import {StatusTypeAvatar} from "./Icons/StatusTypeAvatar";
import {PageKeys} from "../types/PageKeys";
import {SearchKeys} from "../types/SearchKeys";
import {useSearchParams} from "react-router";
import {useParams} from "../hooks/searchParams.hooks";
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone';
import DataArrayTwoToneIcon from '@mui/icons-material/DataArrayTwoTone';
import DataObjectTwoToneIcon from '@mui/icons-material/DataObjectTwoTone';
import ShareIcon from '@mui/icons-material/Share';
import {SearchField} from "./Input/SearchField";
import {OrgSearchField} from "./Input/OrgSearchField";
import {CpvCategoryField} from "./Input/CpvCategoryField";
import {copyUrl, exportCsv, exportJson} from "../lib/exporter";
import {CpvCategory, getCpvCategoriesFromString} from "../types/CpvCategory";
import {StatusField} from "./Input/StatusField";
import {noticeIdLink, PlatformAvatar} from "./Icons/PlatformAvatar";
import {ProcurementPlatformAvatar, procurementPlatformLink} from "./Icons/ProcurementPlatformAvatar"
import {isPlatformIncluded, PlatformToggleButton} from "./Buttons/PlatformToggleButton";
import {RoleToggleButton} from "./Buttons/RoleToggleButton";
import {ContractToggleButton} from "./Buttons/ContractToggleButton";
import {ContractTypeAvatar} from "./Icons/ContractTypeAvatar";
import {ContractingSystemAvatar} from "./Icons/ContractingSystemAvatar";
import {ProcurementPlatformField} from "./Input/ProcurementPlatformField";
import {getProcurementPlatform, isProcurementPlatformIncluded} from "../types/ProcurementPlatform";
import {ProcurementStatusID} from "../types/ProcurementStatus";

export const MOBILE_COLUMNS = {
    id: false,
    platform: false,
    status: false,
    buyer: true,
    heading: true,
    description: false,
    amount: false,
    currency: false,
    type: false,
    issueDate: false,
    cpvs: false,
    country: false,
    applyLink: false
};

export const ALL_COLUMNS = {
    id: false,
    platform: true,
    status: true,
    buyer: true,
    heading: true,
    description: true,
    amount: true,
    currency: true,
    type: true,
    issueDate: true,
    cpvs: true,
    country: true,
    applyLink: true
};

enum ExportAction {
    SAVE_CSV,
    SAVE_JSON,
    COPY_URL
}

function containsCpv(cpvs: ProductClassification | null, cpvCategories: CpvCategory[]) {
    let prefixes = cpvCategories.flatMap((it) => it.prefixes)
    if (cpvCategories.length === 0) return true
    let mainCpvs = cpvs?.mainCpv ?? []
    let additional = cpvs?.additionalCpvs ?? []
    let all = [...mainCpvs, ...additional]
        .map((it) => it.id.code)
        .map((it) => it.slice(0, 2))

    let codeSet = Array.from(new Set(all))
    let prefixSet = Array.from(new Set(prefixes))

    for (const index in prefixSet) {
        const prefix = prefixSet[index]
        if (codeSet.includes(prefix)) {
            return true
        }
    }

    return false
}

function wonBy(lots: NoticeLot[] | null): string[] {
    if (!lots) return []
    return lots
        .flatMap((it) => it.winner)
        .filter((it) => it != null)
        .map((it) => nameOrgNumber(it.id.nationalID))
        .filter((it) => it != null)
}

function wasWonBy(winnerOrgNumbers: string[], orgNumber: string | null) {
    if (!orgNumber) return false
    if (!winnerOrgNumbers) return false
    if (winnerOrgNumbers.length === 0) return false

    for(let i=0; i<winnerOrgNumbers.length; i++){
        let it = winnerOrgNumbers[i];
        if (it === orgNumber) {
            return true
        }
    }
    return false
}

function wasBoughtBy(buyer: Buyer, orgNumber: string | null) {
    let nationalID = buyer.id.nationalID;
    let buyerOrgNumber = nameOrgNumber(nationalID);

    if (!orgNumber) return false
    if (!nationalID) return false

    return (buyerOrgNumber === orgNumber)
}

function noticeStatus(notice: ProcurementNotice): ProcurementStatusID {
    if (isAward(notice.metaData.formTypes)) return ProcurementStatusID.AWARDED
    if (notice.status) return notice.status
    return ProcurementStatusID.UNKNOWN
}

interface Transformed {
    id: string;
    platform: NoticeID;
    status: ProcurementStatusID;
    buyer: Buyer;
    heading: string | undefined;
    description: string | undefined;
    amount: string | number | null;
    currency: string | null | undefined;
    type: NoticeMetaData;
    issueDate: string | null | undefined;
    cpvs: ProductClassification | null;
    country: string | null;
    applyLink: string | null;
    wonBy: string[];
}

function filterRoles(it: Transformed, orgNumber: string | null, roles: OrgRoleType[]) {
    function keepBuyer(buyer: Buyer, orgNumber: string | null, roles: OrgRoleType[]): boolean {
        if (!roles.includes(OrgRoleType.BUYER)) return false
        return nameOrgNumber(buyer.id.nationalID) === orgNumber
    }

    function keepWonBy(winners: string[], orgNumber: string | null, roles: OrgRoleType[]): boolean {
        if (!roles.includes(OrgRoleType.WINNER)) return false
        for (let i = 0; i < winners.length; i++) {
            let it = winners[i];
            if (it === orgNumber) {
                return true
            }
        }
        return false
    }

    if (roles.length === 0) return true
    if (!orgNumber) return true

    let filterForBuyer = keepBuyer(it.buyer, orgNumber, roles);
    let filterForWinner = keepWonBy(it.wonBy, orgNumber, roles);
    return filterForBuyer || filterForWinner;
}

function filterStatus(it: Transformed, selectedStatuses: ProcurementStatusID[]) {
    if (selectedStatuses.length === 0) return true
    return selectedStatuses.includes(it.status);
}

function getUrl(applyLink: string | null, heading: string | null, buyer: Buyer | null) {
    if (applyLink && applyLink.length > 0) return applyLink
    let buyerName = (buyer) ? nameFromBuyer(buyer) : null;
    if (heading?.includes("TransQ") === true) return "https://www.achilles.com/"
    if (buyerName?.includes("ACHILLES") === true) return "https://www.achilles.com/"
    return null
}

export default function ProcurementNoticeTable(props: { notices: ProcurementNotice[] }) {
    const [searchParams] = useSearchParams();
    const [orgNumber] = useParams<string>(SearchKeys.orgNumber, null);
    const [excludedPlatforms,] = useParams<string>(SearchKeys.excludedNoticePlatforms, null);
    const [excludeProcurementPlatforms,] = useParams<string>(SearchKeys.excludedProcurementPlatforms, null);
    const [excludedRoles] = useParams<string>(SearchKeys.excludedRoles, null);
    const [contractTypes] = useParams<string>(SearchKeys.excludedContractTypes, null);
    const [pageParamInitial] = useParams<number>(SearchKeys.pageNotices, 0);
    const [rowsPerPageParamInitial] = useParams<number>(SearchKeys.rowsPerPageNotices, 10);
    const [searchString] = useParams<string>(SearchKeys.searchString, null);
    const [noticeIdParam, _setNoticeIdParam, clearNoticeIdParam] = useParams<string>(SearchKeys.noticeID, null);

    const [statuses] = useParams<string>(SearchKeys.status, null);
    const [selectedStatuses, setSelectedStatuses] = useState<ProcurementStatusID[]>([])

    const [categories] = useParams<string>(SearchKeys.cpvCategories, null);
    const [selectedCategories, setSelectedCategories] = useState<CpvCategory[]>([])

    const apiRef = useGridApiRef();

    // TODO: figure out why eslint is so mad about this
    // eslint-disable-next-line
    useEffect(() => {clearNoticeIdParam()}, [noticeIdParam])

    // TODO need to figure out how to ignore these
    useEffect(() => {}, [_setNoticeIdParam])

    React.useEffect(() => {
        setSelectedCategories(getCpvCategoriesFromString(categories))
    }, [categories]);

    React.useEffect(() => {
        let list = statuses?.split(",") ?? [];
        setSelectedStatuses(list as ProcurementStatusID[])
    }, [statuses]);

    const [transformedRows, setTransformedRows] = useState<Transformed[]>([])

    useEffect(() => {
        if (props.notices.length !== 0 && transformedRows.length === 0) {
            let items = props.notices.map((hit) => {
                let published = (hit.doffinID) ? hit.doffinID?.published : hit.tedID?.published;
                let localizedString = formatLocalizedString(hit.title, false);
                let heading = (localizedString && localizedString.length > 0) ? localizedString[0] : "";
                let buyer = hit.buyers[0];
                let applyLink = procurementPlatformLink(hit.links);
                let url = getUrl(applyLink, heading, buyer);
                return ({
                    id: noticeIdName(noticeID(hit)),
                    platform: noticeID(hit),
                    status: noticeStatus(hit),
                    buyer: buyer,
                    heading: heading,
                    description: getLanguageString(getDescription(hit)),
                    amount: getAmount(hit),
                    currency: getCurrency(hit),
                    type: hit.metaData,
                    issueDate: published,
                    cpvs: hit.productClassification,
                    country: hit.country,
                    applyLink: url,
                    wonBy: wonBy(hit.noticeLots)
                } as Transformed);
            });
            setTransformedRows(items)
        }
    }, [transformedRows, props.notices]);

    let rows = filterRows();

    function roles() {
        let roleList = [OrgRoleType.WINNER, OrgRoleType.BUYER]
        let excluded = getOrgRoleTypesFromString(excludedRoles)
        return roleList.filter((it) => !excluded.includes(it))
    }

    function filterContractTypes(it: Transformed, types: string | null) {
        let excluded = getContractTypesFromString(types)
        if (excluded.length === 0) return true
        let contractTypeList = getContractTypes(it.type.contractType)
        if (contractTypeList.length === 0) return true
        let list = contractTypeList.filter((it) => !excluded.includes(it))
        return list.length !== 0
    }

    function filterRows() {
        return transformedRows.filter((it) => {
            let includePlatform = isPlatformIncluded(it.platform.platform, excludedPlatforms);
            let includeProcurementPlatforms = isProcurementPlatformIncluded(getProcurementPlatform(it.applyLink), excludeProcurementPlatforms)
            let roleFilter = filterRoles(it, orgNumber, roles())
            let contractTypeFilter = filterContractTypes(it, contractTypes)
            let statusFilter = filterStatus(it, selectedStatuses);
            let filterForCpv = containsCpv(it.cpvs, selectedCategories);
            return (
                includePlatform &&
                includeProcurementPlatforms &&
                filterForCpv &&
                statusFilter &&
                roleFilter &&
                contractTypeFilter
            )
        });
    }

    function getAmount(hit: ProcurementNotice) {
        let amounts = hit.amounts;
        if (!amounts) return ""
        let highAmount = getHighAmount(amounts);
        return (highAmount) ? highAmount.value : amounts.text
    }

    function getCurrency(hit: ProcurementNotice) {
        let amounts = hit.amounts;
        if (!amounts) return ""
        let highAmount = getHighAmount(amounts);
        return highAmount?.currency
    }

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Notice id', flex: 0.2,
        },
        {
            field: 'status', headerName: 'Status', flex: 0.2,
            renderCell: (params) => {
                let winnerRole = wasWonBy(params.row.wonBy, orgNumber) ? OrgRoleType.WINNER : null
                let buyerRole = wasBoughtBy(params.row.buyer, orgNumber) ? OrgRoleType.BUYER : null
                let role = winnerRole ?? buyerRole;
                return (
                <StatusTypeAvatar
                    status={params.row.status}
                    role={role}
                />
            )},
            sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            valueGetter: (_value, row) => {
                let winnerRole = wasWonBy(row.wonBy, orgNumber) ? OrgRoleType.WINNER : null
                let buyerRole = wasBoughtBy(row.buyer, orgNumber) ? OrgRoleType.BUYER : null
                let status = (row.status) ? row.status : "Missing status";
                if (winnerRole) return "WON (" + status + ")"
                if (buyerRole) return buyerRole + " (" + status + ")"
                return status;
            },
            // sortComparator: (v1: string, v2: string, cellParams1, cellParams2) => v1.localeCompare(v2),
        },
        {
            field: 'buyer', headerName: 'Buyer', flex: 0.2,
            renderCell: (params) => {return (<BuyerAvatar buyer={params.row.buyer}/>)},
            sortComparator: (v1: Buyer, v2: Buyer) => nameFromBuyer(v1).localeCompare(nameFromBuyer(v2)),
            valueFormatter: (value: Buyer) => `${nameFromBuyer(value)}`,
        },
        {field: 'issueDate', headerName: 'Issued', flex: 0.4},
        {
            field: 'heading', headerName: 'Title', flex: 1.5, renderCell: (params) => {
                let url = `/${PageKeys.notice}?${SearchKeys.noticeID}=${params.row.id}&${searchParams.toString()}`;
                return (
                    <Tooltip title={params.row.heading}>
                        <Link href={url} underline="always">
                            {params.row.heading}
                        </Link>
                    </Tooltip>
                )
            }
        },
        {field: 'description', headerName: 'Description', flex: 1.5},
        {
            field: 'applyLink', headerName: 'Apply', flex: 0.2,
            renderCell: (params) => {
                let noticeIdKey = params.row.id!!;
                return (<ProcurementPlatformAvatar link={params.row.applyLink} noticeID={noticeIdKey}/>)
            },
            sortComparator: (v1, v2) => {
                if (!v1 && !v2) return 0
                if (!v1) return -1
                if (!v2) return 1
                let left = getProcurementPlatform(v1);
                let right = getProcurementPlatform(v2);
                let comparative = left.localeCompare(right);
                if (comparative !== 0) return comparative;
                return v1.localeCompare(v2);
            },
        },
        {
            field: 'platform', headerName: 'Notice link', flex: 0.2,
            renderCell: (params) => {return (<PlatformAvatar noticeID={params.row.platform}/>)},
            valueFormatter: (value: NoticeID) => `${noticeIdLink(value)}`,
            sortComparator: (v1: NoticeID, v2: NoticeID) => noticeIdName(v1).localeCompare(noticeIdName(v2)),
        },
        {
            field: 'type', headerName: 'Type', flex: 0.4,
            renderCell: (params) => {return (
                <AvatarGroup spacing="small" max={99}>
                    <ContractingSystemAvatar metaData={params.row.type} key={"contracting-system"}/>
                    <ContractTypeAvatar metaData={params.row.type} key={"contract-type"}/>
                    <FormTypeAvatar formTypes={params.row.type.formTypes} key={"form-type"}/>
                </AvatarGroup>
            )},
            sortComparator: (v1: NoticeMetaData, v2: NoticeMetaData) => {
                let left = v1.contractingSystems[0] + " " + v1.contractType[0] + " " + v1.formTypes[0];
                let right = v2.contractingSystems[0] + " " + v2.contractType[0] + " " + v2.formTypes[0];
                return left.localeCompare(right);
            },
            valueFormatter: (value: NoticeMetaData) => {
                return `${value.contractingSystems[0]},${value.contractType[0]},${value.formTypes[0]}`;
            },
        },
        {field: 'amount', headerName: 'Value', flex: 0.4},
        {field: 'currency', headerName: 'Currency', flex: 0.2},
        {
            field: 'country', headerName: 'Country', flex: 0.2,
            renderCell: (params) => {return (<FlagAvatar countryCode={params.row.country}/>)},
            sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
        },
        {
            field: 'cpvs', headerName: 'CPV Codes', flex: 0.5,
            // filterOperators: ?? TODO add cpv codes to quick filter?
            sortComparator: (v1: ProductClassification, v2: ProductClassification) => {
                return compareProductClassifications(v1, v2)
            },
            valueFormatter: (value: ProductClassification) => {
                return cpvCodes(value).map((code) => code.id.code)
            },
            renderCell: (params) => {
                let cpvList = cpvUniqueByLabel(params.row.cpvs)
                return (
                    <>
                        {cpvList && (
                            <Stack direction="row">
                                <AvatarGroup spacing="small" max={99}>
                                    {cpvList!!.slice(0, 5).map((cpv: CpvCode) => {
                                        return (
                                            <CpvButton code={cpv.id.code} title={getLanguageString(cpv.label)} key={cpv.id.code}/>
                                        );
                                    })}
                                </AvatarGroup>
                            </Stack>
                        )}
                    </>
                )
            },
        },
    ];

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS);

    React.useEffect(() => {
        const newColumns = matches ? ALL_COLUMNS : MOBILE_COLUMNS;
        setColumnVisible(newColumns);
    }, [matches]);

    const CustomToolbar = (props: GridToolbarProps) => {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.up("sm"));
        const [showToolbar, setShowToolbar] = React.useState(true);
        React.useEffect(() => {
            setShowToolbar(matches)
        }, [matches]);
        return (
            <>
                {showToolbar && (
                    <GridToolbarContainer
                        {...props}
                        sx={{
                            alignItems: 'stretch',
                            padding: 1,
                            gap: 1
                        }}
                    >
                        <StatusField key="status"/>
                        <OrgSearchField key="org"/>
                        <RoleToggleButton roleType={OrgRoleType.WINNER} key="winner"/>
                        <RoleToggleButton roleType={OrgRoleType.BUYER} key="buyer"/>
                        <ContractToggleButton contractType={ContractType.SERVICES} key={"services"}/>
                        <ContractToggleButton contractType={ContractType.WORKS} key={"works"}/>
                        <ContractToggleButton contractType={ContractType.SUPPLIES} key={"supplies"}/>
                        <ProcurementPlatformField key="procurement-platform"/>
                        <PlatformToggleButton platformID={PlatformID.DOFFIN} key="doffin"/>
                        <PlatformToggleButton platformID={PlatformID.TED} key="ted"/>
                        <SearchField key="search"/>
                        <CpvCategoryField key="cpv-category"/>
                        {/*<CustomExportButton/>*/}
                        {/*Bad interaction with columnVisibilityModel: https://github.com/mui/mui-x/issues/11656*/}
                        {/*<GridToolbarColumnsButton/>*/}
                        {/*<GridToolbarFilterButton/>*/}
                        {/*<GridToolbarDensitySelector/>*/}
                    </GridToolbarContainer>
                )}
                {!showToolbar && (
                    <GridToolbarContainer
                        {...props}
                        sx={{
                            alignItems: 'stretch',
                            justifyContent: 'center',
                            padding: 1,
                            gap: 1
                        }}
                    >
                        <SearchField/>
                    </GridToolbarContainer>
                )}
            </>
        );
    }

    const actions = [
        { icon: <ShareIcon color={"secondary"} />, name: 'Copy URL', action: ExportAction.COPY_URL },
        { icon: <DataObjectTwoToneIcon color={"primary"} />, name: 'Save JSON', action: ExportAction.SAVE_JSON },
        { icon: <DataArrayTwoToneIcon color={"primary"} />, name: 'Save CSV', action: ExportAction.SAVE_CSV },
        // { icon: <PrintIcon />, name: 'Print' },
        // { icon: <ShareIcon />, name: 'Share' },
    ];

    function invokeAction(exportAction: ExportAction) {
        switch (exportAction) {
            case ExportAction.COPY_URL: copyUrl(); break;
            case ExportAction.SAVE_CSV: exportCsv(apiRef, 'turtleTraceNotices.csv'); break;
            case ExportAction.SAVE_JSON: exportJson(apiRef, 'turtleTraceNotices.json'); break;
        }
    }

    function CustomPagination({ rowsPerPageOptions, labelRowsPerPage}: Partial<TablePaginationProps> & PaginationPropsOverrides) {

        const FALLBACK_PAGE: number = 0;
        const FALLBACK_PAGE_SIZE: number = 10;
        const [pageParam, setPageParam, clearPageParam] = useParams<number>(SearchKeys.pageNotices, FALLBACK_PAGE);
        const [rowsPerPageParam, setRowsPerPageParam, clearRowsPerPageParam] = useParams<number>(SearchKeys.rowsPerPageNotices, FALLBACK_PAGE_SIZE);

        // const pageSel = useGridSelector(apiRef, gridPageSelector);
        const rowCountSel = useGridSelector(apiRef, gridRowCountSelector);
        const pageCountSel = useGridSelector(apiRef, gridPageCountSelector);
        // const pageSizeSel = useGridSelector(apiRef, gridPageSizeSelector);
        // const quickFilterValues = useGridSelector(apiRef, gridQuickFilterValuesSelector);
        const filteredRows = useGridSelector(apiRef,gridFilteredSortedRowIdsSelector)

        const [internalPage, setInternalPage] = useState<number>(pageParam ?? FALLBACK_PAGE)
        const [internalPageSize, setInternalPageSize] = useState<number>(rowsPerPageParam ?? FALLBACK_PAGE_SIZE)
        const [internalRowCount, setInternalRowCount] = useState<number>(pageCountSel)

        useEffect(() => {
            if (internalPage.toString() !== pageParam?.toString()) {
                if (internalPage !== FALLBACK_PAGE) {
                    setPageParam(internalPage);
                } else {
                    clearPageParam()
                }
            }
        }, [internalPage, pageParam, setPageParam, clearPageParam]);

        useEffect(() => {
            if (internalPageSize.toString() !== rowsPerPageParam?.toString()) {
                if (internalPageSize !== FALLBACK_PAGE_SIZE) {
                    setRowsPerPageParam(internalPageSize);
                } else {
                    clearRowsPerPageParam()
                }
            }
        }, [internalPageSize, rowsPerPageParam, setRowsPerPageParam, clearRowsPerPageParam]);

        useEffect(() => {
            let currentRow = internalPage * internalPageSize;
            if (filteredRows.length < internalRowCount || currentRow > filteredRows.length) {
                apiRef.current.setPage(0);
                setInternalPage(0)
            }
            setInternalRowCount(filteredRows.length)
        }, [filteredRows, internalRowCount, setInternalRowCount, internalPage, setInternalPage, internalPageSize]);

        const handleChangePage = (_event: any, newPage: number) => {
            apiRef.current.setPage(newPage);
            setInternalPage(newPage);
        };

        const onRowsPerPageChange = (event: { target: { value: string; }; }) => {
            //     // Calculate where we should be after changing the page size
            //     // We were at oldPageSize * oldPage
            //     // New location = (oldPageSize * oldPage) / newPageSize
            //     // Big to small: (100 * 8) / 50  = 800 / 50  = 16
            //     // Small to big: (50 * 16) / 100 = 800 / 100 = 8
            //     // Very small to very big (10 * 40) / 100 = 400 / 100 = 4
            //     // Very big to very small (100 * 4) / 10  = 400 / 10  = 40
            //     const oldPageSize = rowsPerPageParam!!
            //     const oldPage = pageParam!!
            //     const newPageSize = toNumber(event.target.value)
            //     const newPage = Math.floor((oldPageSize * oldPage) / newPageSize)
            let eventSize = event.target.value;
            let updatedPageSize = parseInt(eventSize, 10);

            apiRef.current.setPageSize(updatedPageSize);
            apiRef.current.setPage(0);

            setInternalPageSize(updatedPageSize)
            setInternalPage(0)
        };

        // { from, to, count, page }
        function getLabelDisplayedRows({ from, to }: LabelDisplayedRowsArgs) {
            let low = from
            let high = toNumber(internalPageSize.toString()) + (low - 1);
            let total = filteredRows.length
            let endPage = (total > 0) ? Math.min(high, total) : to;
            let totalLabel = (total > 0) ? `of ${total}` : ""
            return `${low}–${endPage} ${totalLabel}`;
        }

        // Inspired by https://github.com/MaikEight/ExaltAccountManager/blob/main/src/components/GridComponents/CustomPagination.jsx
        return (
            <TablePagination
                component="div"
                count={rowCountSel}
                page={internalPage as number}
                rowsPerPage={internalPageSize as number}
                rowsPerPageOptions={ rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50, 100] }
                onPageChange={handleChangePage}
                onRowsPerPageChange={onRowsPerPageChange}
                labelDisplayedRows={getLabelDisplayedRows}
                labelRowsPerPage={labelRowsPerPage}
                ActionsComponent={() => {
                    return (
                        <Pagination
                            style={{flex: '1 0 auto', paddingLeft: '1rem'}}
                            color="primary"
                            // variant="outlined"
                            // shape="rounded"
                            size="large"
                            showFirstButton
                            showLastButton
                            page={toNumber(internalPage.toString()) + 1}
                            count={pageCountSel}
                            onChange={(event, page) => {
                                handleChangePage(event, page - 1)
                            }}
                        />
                    );
                }}
            />
        );
    }

    // Seriously, don't ask me, I don't know, they turn into strings somewhere
    const initialPageSize = parseInt(rowsPerPageParamInitial!!.toString(), 10)
    const initialPage = parseInt(pageParamInitial!!.toString(), 10)

    return (
        <>
            <DataGrid
                apiRef={apiRef}
                sortingOrder={['desc', 'asc']}
                resetPageOnSortFilter
                localeText={{
                    toolbarQuickFilterPlaceholder: "Filter by text",
                    toolbarExportLabel: "",
                    toolbarExport: <DownloadForOfflineTwoToneIcon fontSize="large"/>,
                }}
                sx={{
                    '& .MuiDataGrid-cell': {
                        alignContent: 'center'
                    },
                }}
                rows={rows}
                columns={columns}
                slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination
                }}
                disableColumnMenu
                ignoreDiacritics
                initialState={{
                    sorting: {
                        sortModel: [{field: 'issueDate', sort: 'desc'}],
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: initialPageSize!!,
                            page: initialPage!!
                        }
                    },
                    filter: {
                        filterModel: {
                            items: [],
                            // set by apiRef.current.setQuickFilterValues
                            quickFilterValues: searchString ? [searchString] : undefined,
                            // quickFilterValues: [orgNumber, categories].filter((value) => value !== null),
                            // https://mui.com/x/react-data-grid/filtering/quick-filter/#including-hidden-columns
                            quickFilterExcludeHiddenColumns: false,
                        },
                    },
                }}
                columnVisibilityModel={columnVisible}
            />
            <SpeedDial
                ariaLabel="SpeedDial for exporting table"
                // sx={{position: 'absolute', bottom: 16, right: 16}}
                sx={{position: 'fixed', bottom: 16, left: 16}}
                // icon={<SpeedDialIcon/>}
                icon={<DownloadForOfflineTwoToneIcon fontSize="large"/>}
                // openIcon={<DownloadingTwoToneIcon fontSize="large"/>}
                // icon={
                //     <SpeedDialIcon
                //         icon={<DownloadForOfflineTwoToneIcon fontSize="large"/>}
                //         openIcon={<ArrowCircleDownTwoToneIcon fontSize="large"/>}
                //     />
                // }
                direction="right"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        onClick={() => invokeAction(action.action)}
                        icon={action.icon}
                        slotProps={{
                            tooltip: {
                                title: action.name,
                                // open: true,
                                arrow: true
                            }
                        }}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
