import * as React from "react";
import {useParams} from "../../hooks/searchParams.hooks";
import {SearchKeys} from "../../types/SearchKeys";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import PlumbingTwoToneIcon from '@mui/icons-material/PlumbingTwoTone';
import {Tooltip} from "@mui/material";
import {ContractType} from "../../types/ProcurementNoticeList";

export function getContractTypeIdIcon(roleID: ContractType, toggled: Boolean,  disabled: Boolean) {
    function getColor() {
        if (disabled)
            return "disabled"
        switch (roleID) {
            case ContractType.SERVICES:
                return "secondary";
            case ContractType.WORKS:
                return "success";
            case ContractType.SUPPLIES:
                return "info";
        }
    }

    const color = (toggled) ? getColor() : "disabled"

    switch (roleID) {
        case ContractType.SERVICES:
            return <DesignServicesTwoToneIcon fontSize="medium" color={color}/>;
        case ContractType.WORKS:
            return <PlumbingTwoToneIcon fontSize="medium" color={color}/>;
        case ContractType.SUPPLIES:
            return <CategoryTwoToneIcon fontSize="medium" color={color}/>;
    }
}

export function isContractTypeIncluded(roleID: ContractType, excludedContractTypes: string | null) {
    if (!excludedContractTypes) return true
    return !excludedContractTypes.split(",").includes(roleID);
}

export function ContractToggleButton(props: { contractType: ContractType }) {
    const [excludedContractTypes, setExcludedContractTypes, clearExcludedContractTypes] = useParams<string>(SearchKeys.excludedContractTypes, null);
    const [toggled, setToggled] = useState(isContractTypeIncluded(props.contractType, excludedContractTypes))

    useEffect(() => {
        let isToggled = isContractTypeIncluded(props.contractType, excludedContractTypes);
        setToggled(isToggled);
    }, [excludedContractTypes, props.contractType]);

    function removeContractType() {
        const list = excludedContractTypes?.split(",") ?? []
        const removed = list.filter((it) => it !== props.contractType).join(",")
        if (removed)
            setExcludedContractTypes(removed)
        else
            clearExcludedContractTypes()
    }

    function addContractType() {
        const list = excludedContractTypes?.split(",") ?? []
        const added = new Set(list.concat([props.contractType]))
        setExcludedContractTypes(Array.from(added).join(","))
    }

    function toggle() {
        const platforms = excludedContractTypes?.split(",") ?? []
        if (excludedContractTypes && platforms.includes(props.contractType)) {
            removeContractType()
        } else {
            addContractType()
        }
    }

    const leadText = (toggled) ? `Remove : ` : `Include : `

    return <Tooltip title={leadText + props.contractType} arrow>
        <IconButton
            size="large"
            edge="start"
            onClick={() => {
                toggle();
            }}
            sx={{
                width: 40, height: 40, padding: 3, margin: "1px"
            }}
        >
            {getContractTypeIdIcon(props.contractType, toggled, false)}
        </IconButton>
    </Tooltip>
}
