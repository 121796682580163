import React from 'react';
import {
    Avatar, Badge,
    Card,
    CardContent, CardHeader,
    Link,
    Step,
    StepLabel,
    Stepper,
} from "@mui/material";
import {
    NoticeID,
    noticeIdName, noticePlatformLink,
    PlatformID,
    ProcurementNotice,
} from "../../types/ProcurementNoticeList";
import Typography from "@mui/material/Typography";
import {blue, brown, green, red, yellow} from "@mui/material/colors";
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import AlarmTwoToneIcon from '@mui/icons-material/AlarmTwoTone';
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import StopCircleTwoToneIcon from '@mui/icons-material/StopCircleTwoTone';
import {noticeIdLink} from "../Icons/PlatformAvatar";
import {getPlatformIdIcon} from "../Buttons/PlatformToggleButton";
import {FormTypeAvatar} from "../Icons/FormTypeAvatar";
import {PageKeys} from "../../types/PageKeys";
import {SearchKeys} from "../../types/SearchKeys";
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';

enum Milestone {
    PUBLISHED = "Published",
    PUBLISHED_DOFFIN = "Published to Doffin",
    PUBLISHED_TED = "Published to TED",
    DEADLINE = "Deadline",
    START_DATE = "Start Date",
    END_DATE = "End Date"
}

interface TimelineDateInterface {
    date: string | null,
    milestone: Milestone,
    label: string,
}

class TimelineDate implements TimelineDateInterface {
    constructor(
        noticeID: NoticeID
    ) {
        let internalUrl = `/${PageKeys.notice}?${SearchKeys.noticeID}=${noticeIdName(noticeID)}`;
        switch (noticeID.platform) {
            case PlatformID.DOFFIN: {
                this.date = noticeID.published
                this.milestone = Milestone.PUBLISHED_DOFFIN
                this.label = `${noticeID.platform}-${noticeID.id}`
                this.link = noticePlatformLink(noticeID)!!
                this.internalLink = internalUrl
                this.noticeID = noticeID
                break;
            }
            case PlatformID.TED: {
                this.date = noticeID.published
                this.milestone = Milestone.PUBLISHED_TED
                this.label = `${noticeID.platform}-${noticeID.id}`
                this.link = noticePlatformLink(noticeID)!!
                this.internalLink = internalUrl
                this.noticeID = noticeID
                break;
            }
        }
    }

    date: string | null;
    milestone: Milestone;
    label: string;
    link?: string;
    internalLink?: string;
    noticeID?: NoticeID;
}

function getTimeLine(notice: ProcurementNotice | undefined): TimelineDate[] {
    let steps: TimelineDate[] = [];
    if (notice === undefined) return steps
    if (notice.tedReferences)
        notice.tedReferences.map((val) => steps.push(new TimelineDate(val)))
    if (notice.doffinReferences)
        notice.doffinReferences.map((val) => steps.push(new TimelineDate(val)))
    if (notice.doffinID?.published)
        steps.push({date: notice.doffinID.published, milestone: Milestone.PUBLISHED, label: Milestone.PUBLISHED, link: `https://doffin.no/notices/${notice.doffinID.id}`});
    if (notice.tedID?.published)
        steps.push({date: notice.tedID.published, milestone: Milestone.PUBLISHED, label: Milestone.PUBLISHED, link: `https://ted.europa.eu/en/notice/-/detail/${notice.tedID.id}/`});
    if (notice.submissionDeadline)
        steps.push({date: notice.submissionDeadline, milestone: Milestone.DEADLINE, label: Milestone.DEADLINE});
    if (notice.period?.startDate)
        steps.push({date: notice.period.startDate, milestone: Milestone.START_DATE, label: Milestone.START_DATE});
    if (notice.period?.endDate)
        steps.push({date: notice.period.endDate, milestone: Milestone.END_DATE, label: Milestone.END_DATE});
    steps = steps.sort((n1,n2) => {
        if (n1.date === n2.date)
            return 0;
        if (!n1.date)
            return -1
        if (!n2.date)
            return 1;
        if (n1.date > n2.date)
            return 1;
        if (n1.date < n2.date)
            return -1;
        return 0;
    });
    return steps
}

function timelineIconBackground(val: TimelineDate) {
    switch (val.milestone) {
        case Milestone.PUBLISHED: return yellow[600]
        case Milestone.PUBLISHED_DOFFIN: return blue[50]
        case Milestone.PUBLISHED_TED: return green[50]
        case Milestone.DEADLINE: return red[500]
        case Milestone.START_DATE: return green[500]
        case Milestone.END_DATE: return blue[500]
        default: return "primary.dark"
    }
}

function TimelineCard(props: { result: ProcurementNotice | undefined }) {
    const dates: TimelineDate[] = getTimeLine(props.result)
    const activeStep: number = 0
    // const isDarkTheme = useTheme().palette.mode === 'dark';

    function getBorderColor(val: TimelineDate) {
        switch (val.milestone) {
            case Milestone.PUBLISHED: return yellow[800]
            case Milestone.PUBLISHED_DOFFIN: return "#0288d1"
            case Milestone.PUBLISHED_TED: return "#4caf50"
            case Milestone.DEADLINE: return red[700]
            case Milestone.START_DATE: return green[700]
            case Milestone.END_DATE: return blue[700]
            default: return "primary.dark"
        }
    }

    function getIcon(val: TimelineDate) {
        let borderColor = getBorderColor(val);
        let sxAttr = {
            width: 40,
            height: 40,
            bgcolor: timelineIconBackground(val)
        };
        let styleAttr = {
            border: '3px solid ' + borderColor,
        }
        switch (val.milestone) {
            case Milestone.PUBLISHED:return (
                <Avatar sx={sxAttr} style={styleAttr}>
                    <PendingActionsTwoToneIcon fontSize="small"/>
                </Avatar>
            )
            case Milestone.PUBLISHED_DOFFIN: return (
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={<FormTypeAvatar formTypes={val.noticeID!!.formTypes} size={30}/>}
                >
                    <Avatar sx={sxAttr} style={styleAttr} component="a" target="_blank" href={noticeIdLink(val.noticeID!!)}>
                        {getPlatformIdIcon(val.noticeID!!.platform)}
                    </Avatar>
                </Badge>
            )
            case Milestone.PUBLISHED_TED: return (
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={<FormTypeAvatar formTypes={val.noticeID!!.formTypes} size={30}/>}
                >
                    <Avatar sx={sxAttr} style={styleAttr} component="a" target="_blank" href={noticeIdLink(val.noticeID!!)}>
                        {getPlatformIdIcon(val.noticeID!!.platform)}
                    </Avatar>
                </Badge>
            )
            case Milestone.DEADLINE: return (
                <Avatar sx={sxAttr} style={styleAttr}>
                    <AlarmTwoToneIcon fontSize="small"/>
                </Avatar>
            )
            case Milestone.START_DATE: return (
                <Avatar sx={sxAttr} style={styleAttr}>
                    <PlayCircleFilledTwoToneIcon fontSize="medium"/>
                </Avatar>
            )
            case Milestone.END_DATE: return (
                <Avatar sx={sxAttr} style={styleAttr}>
                    <StopCircleTwoToneIcon fontSize="medium"/>
                </Avatar>
            )
        }
    }

    return (
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: brown[300]}}>
                        <CalendarMonthTwoToneIcon fontSize="medium"/>
                    </Avatar>
                }
                title={
                    <Typography component="h2">
                        Timeline
                    </Typography>
                }
            />
            <CardContent>
                {dates &&
                    <Stepper
                        activeStep={activeStep}
                        orientation={"vertical"}
                        sx={{
                            display: "flex",
                            "& .MuiStepConnector-line": {
                                borderColor: 'divider',
                                marginLeft: "5px",
                                borderWidth: "3px",
                            },
                        }}
                    >
                        {dates.map((val, index) =>
                            <Step key={index}>
                                <StepLabel icon={getIcon(val)}>
                                    {val.internalLink &&
                                        <>
                                            <Link href={val.internalLink} underline="always" target="_blank">
                                                <Typography variant="subtitle1">{val.label}</Typography>
                                            </Link>
                                            <Typography variant="caption">{val.date}</Typography>
                                        </>
                                    }
                                    {!val.internalLink &&
                                        <>
                                            <Typography variant="subtitle1">{val.label}</Typography>
                                            <Typography variant="caption">{val.date}</Typography>
                                        </>
                                    }
                                </StepLabel>
                            </Step>
                        )}
                    </Stepper>
                }
            </CardContent>
        </Card>
    );
}

export default TimelineCard;