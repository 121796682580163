import * as React from 'react';
import {useEffect, useState} from 'react';

// Widgets
import {
    DataGrid,
    GridColDef,
    GridRowHeightParams,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarProps,
    GridToolbarQuickFilter,
    useGridApiRef
} from '@mui/x-data-grid';
import {alpha, Avatar, Badge, Box, LinearProgress, Link, Snackbar, styled, Tooltip, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";

// Expandable
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Icons
import LinkIcon from '@mui/icons-material/Link';
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

// Auth
import {ApiAuth} from "../types/ApiAuth";
import {getConfig} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import ConsentRequiredAlert from "./ConsentRequiredAlert";
import LoginRequiredAlert from "./LoginRequiredAlert";

// Types
import {formatDate} from "../types/Assignment";
import {EmailMessage} from "../types/EmailMessage";
import {getBuyerBackgroundColor, getBuyerIcon, getColor, getIcon, getLink} from "./EventIcon";
import {getMessageEvents} from "../api/AssignmentsApi";
import WarningAlert from "./WarningAlert";
import IconButton from "@mui/material/IconButton";
import {GridFilterModel} from "@mui/x-data-grid/models/gridFilterModel";
import Typography from "@mui/material/Typography";

export const MOBILE_COLUMNS = {
    id: false,
    date: false,
    eventType: true,
    eventTopic: true,
    assignmentId: false,
    url: false,
    buyerName: false
};

export const ALL_COLUMNS = {
    id: false,
    date: true,
    eventType: true,
    eventTopic: true,
    assignmentId: true,
    url: false,
    buyerName: false
};

const SmallAvatar = styled(Avatar)(({theme}) => ({
    color: `${theme.palette.grey}`,
    width: 22,
    height: 22,
    border: `0.1px solid gray`
}));

enum MessageType {
    Assignment,
    DPS,
    Unknown
}

interface EventListProps {
    selectedAssignment?: (assignmentID: string) => void,
    selectedBuyer?: (buyerName: string) => void,
    filterTerm: { assignmentID: string }
}

export default function EventList(props: EventListProps) {
    const {audience} = getConfig()

    const {
        user,
        getAccessTokenSilently
    } = useAuth0();

    const apiAuth = new ApiAuth(
        user,
        audience,
        getAccessTokenSilently
    )

    const apiRef = useGridApiRef();

    const [error, setError] = React.useState<string | null>(null)
    const [eventSearchInProgress, setEventSearchInProgress] = React.useState<boolean>(false);
    const [rows, setRows] = useState(new Array<any>());
    const [transientError, setTransientError] = React.useState<string | null>(null)

    // TODO: figure out why eslint is so mad about this
    // eslint-disable-next-line
    useEffect(() => { fetchEvents(); }, [])

    const fetchEvents = (): void => {
        // console.log("fetchEvents")
        setError(null)
        setEventSearchInProgress(true)
        let updatedRows = new Array<any>();
        getMessageEvents(apiAuth)
            .then((events) => {
                const eventRows = events.map((e: EmailMessage) => {
                    return {
                        id: e.id,
                        assignmentId: e.assignmentId,
                        date: formatDate(new Date(e.date)),
                        url: e.url,
                        eventType: e.eventType,
                        eventTopic: e.eventTopic,
                        buyer: e.buyerName,
                        title: e.title,
                        kind: getKind(e.assignmentId, e.title, e.eventTopic)
                    };
                })
                updatedRows = eventRows
                setRows(eventRows)
                if (eventRows.length === 0)
                    setTransientError("No events were found, does the user have access to an organization?");
            })
            .catch((error) => {
                if (error.error !== undefined) {
                    let message = "WARN " + error.error;
                    console.log(message)
                    setTransientError(message);
                } else {
                    let message = "WARN " + error.message;
                    console.log(message)
                    setTransientError(message);
                }
            })
            .finally(() => {
                setEventSearchInProgress(false)
                console.log("Assignment events received : " + updatedRows.length)
            })
    }

    // NOTE: Explicitly avoiding the React optimization of not setting something to its current value
    // When the user erases the quick filter field filterTerm isn't reset, so the user cannot filter again for the
    // same term because React optimizes it out. Holding the terms in an object subverts the optimization.
    const [filterTerm, setFilterTerm] = React.useState(props.filterTerm)

    React.useEffect(() => {
        setFilterTerm(props.filterTerm)
    }, [props.filterTerm])

    React.useEffect(() => {
        let searchTerms = [filterTerm.assignmentID];
        apiRef.current.setQuickFilterValues(searchTerms)
    }, [filterTerm, apiRef]);

    // TODO this should be determined on the backend
    function getKind(assignmentId: string | null | undefined, title: string, topic: string | null | undefined) {
        if (assignmentId) {
            return MessageType.Assignment
        }

        function isDPS(haystack: string | null | undefined) {
            if (haystack) return haystack.toLowerCase().indexOf("dynamisk innkjøpsordning") >= 0;
            return false
        }

        function isRKO(haystack: string | null | undefined) {
            if (haystack) return haystack.toLowerCase().indexOf("rko") >= 0;
            return false
        }

        if (isDPS(title) || isDPS(topic) || isRKO(title)) {
            return MessageType.DPS
        }
        return MessageType.Unknown;
    }

    const [expanded, setExpanded] = React.useState<string | undefined>(undefined);

    const setIdAsExpended =
        (idExpanded: string) => (_event: React.SyntheticEvent, isCurrentlyExpanded: boolean) => {
            setExpanded(isCurrentlyExpanded ? idExpanded : undefined);
        };

    const columns: GridColDef[] = [
        {
            field: 'date', headerName: 'Date', flex: 0.8, renderCell: (params) => {
                return (
                    <Typography
                        variant="body2"
                        sx={{
                            paddingTop: '16px'
                        }}
                    >
                        {params.row.date}
                    </Typography>
                )
            }
        },
        {
            field: 'eventType', headerName: '', flex: 0.5, renderCell: (params) => {
                let buyerName = params.row.buyer
                let favicon = getBuyerIcon(buyerName);
                let backgroundColor = getBuyerBackgroundColor(buyerName);
                if (buyerName && !favicon && !backgroundColor) {
                    console.log(buyerName)
                    console.log(favicon)
                }
                return (
                    <>
                        {buyerName && (
                            <Badge
                                overlap="circular"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                badgeContent={
                                    <>
                                        {!favicon && (
                                            <Tooltip title={buyerName}>
                                                <IconButton
                                                    sx={{
                                                        width: 22,
                                                        height: 22
                                                    }}
                                                    onClick={() => {
                                                        if (props.selectedBuyer) {
                                                            console.log("props.selectedBuyer : " + params.row.buyer)
                                                            props.selectedBuyer(params.row.buyer)
                                                        }
                                                    }}
                                                >
                                                <SmallAvatar
                                                    alt={buyerName}
                                                    sx={{bgcolor: backgroundColor}}
                                                >
                                                    {buyerName.charAt(0).toUpperCase()}
                                                </SmallAvatar>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {favicon && (
                                            <Tooltip title={buyerName}>
                                                <IconButton
                                                    sx={{
                                                        width: 22,
                                                        height: 22
                                                    }}
                                                    onClick={() => {
                                                        if (props.selectedBuyer) {
                                                            console.log("props.selectedBuyer : " + params.row.buyer)
                                                            props.selectedBuyer(params.row.buyer)
                                                        }
                                                    }}
                                                >
                                                <SmallAvatar
                                                    alt={buyerName}
                                                    src={favicon}
                                                    sx={{bgcolor: grey[50]}}
                                                />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </>
                                }>
                                <Tooltip title={params.row.eventType}>
                                    <Avatar
                                        sx={{
                                            marginTop: expanded === params.row.id ? 1.3 : 0,
                                            bgcolor: getColor(params.row.eventType),
                                            width: 32,
                                            height: 32
                                        }}
                                        component="a"
                                        href={getLink(params.row.url)}
                                        target="_blank">
                                        {getIcon(params.row.eventType)}
                                    </Avatar>
                                </Tooltip>
                            </Badge>
                        )}
                        {!buyerName && (
                            <Tooltip title={params.row.eventType}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                    badgeContent={
                                        <Avatar color="default" sx={{width: 22, height: 22}}>
                                            <LinkIcon sx={{width: 20, height: 20}}/>
                                        </Avatar>
                                    }>
                                    <Avatar
                                        sx={{bgcolor: getColor(params.row.eventType), width: 32, height: 32}}
                                        component="a"
                                        href={getLink(params.row.url)}
                                        target="_blank">
                                        {getIcon(params.row.eventType)}
                                    </Avatar>
                                </Badge>
                            </Tooltip>
                        )}
                    </>
                )
            }
        },
        {
            field: 'eventTopic', headerName: 'Message', flex: 3, renderCell: (params) => {
                let topic = params.row.eventTopic;
                let title = params.row.title
                let text = (topic && title) ? (`${topic} (${title})`) : (topic ? topic : title)
                return (
                    <Accordion expanded={expanded === params.row.id} onChange={setIdAsExpended(params.row.id)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            {text}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link
                                href={getLink(params.row.url)}
                                underline="always"
                                target="_blank"
                            >
                                {text}
                            </Link>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        },
        {
            field: 'assignmentId', headerName: '', flex: 0.5, renderCell: (params) => {
                let kind = params.row.kind;
                // let title = params.row.title ? (params.row.title) : ("Could not match message")
                return (
                    <>
                        {(kind === MessageType.Assignment) && (
                            <Tooltip title={MessageType[kind] + ": " + params.row.title}>
                                <IconButton
                                    sx={{
                                        marginTop: 1,
                                        '&:hover': {
                                            backgroundColor: (theme) => theme.palette.primary.main
                                        },
                                        bgcolor: "primary.dark",
                                        width: 32,
                                        height: 32,
                                        display: 'flex'
                                    }}
                                    onClick={() => {
                                        if (props.selectedAssignment) {
                                            console.log("props.selectedAssignment : " + params.row.assignmentId)
                                            props.selectedAssignment(params.row.assignmentId)
                                        }
                                    }}
                                >
                                    <LinkIcon sx={{color: grey[100]}}/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {(kind === MessageType.DPS) && (
                            <Tooltip title={MessageType[kind] + ": " + params.row.title}>
                                <Avatar
                                    sx={{
                                        marginTop: 1,
                                        bgcolor: "primary.main",
                                        width: 32,
                                        height: 32
                                    }}>
                                    <ListRoundedIcon/>
                                </Avatar>
                            </Tooltip>
                        )}
                        {params.row.title && (kind === MessageType.Unknown) && (
                            <Tooltip title={params.row.title}>
                                <Avatar
                                    sx={{
                                        marginTop: 1,
                                        bgcolor: "primary.light",
                                        width: 32,
                                        height: 32
                                    }}>
                                    <LinkOffRoundedIcon/>
                                </Avatar>
                            </Tooltip>
                        )}
                        {!params.row.title && (kind === MessageType.Unknown) && (
                            <Tooltip title={"Could not match message"}>
                                <Avatar
                                    color="default"
                                    sx={{
                                        marginTop: 1,
                                        width: 32,
                                        height: 32
                                    }}>
                                    <LinkOffRoundedIcon/>
                                </Avatar>
                            </Tooltip>
                        )}
                    </>
                )
            }
        },
    ];

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS);
    const [showToolbar, setShowToolbar] = React.useState(true);

    React.useEffect(() => {
        const newColumns = matches ? ALL_COLUMNS : MOBILE_COLUMNS;
        setColumnVisible(newColumns);
        setShowToolbar(matches)
    }, [matches]);

    const CustomToolbar = (props: GridToolbarProps) => {
        const [hasContent, setHasContent] = React.useState(false);

        function getStrings(searchInput: string) {
            if (hasContent) {
                // TODO maybe clear filterTerms???
                setHasContent(false)
            }
            return [searchInput];
        }

        function makeString(values: NonNullable<GridFilterModel["quickFilterValues"]>) {
            let containsNonEmptyString = values.length > 0 && values[0];
            if (containsNonEmptyString) { setHasContent(true) }
            return values.length > 0 ? values[0] : "";
        }

        return (
            <>
                {showToolbar && (
                    <GridToolbarContainer>
                        <GridToolbarExport/>
                        <Badge
                            badgeContent={hasContent ? "Filtering" : 0}
                            color="secondary"
                            overlap="rectangular"
                            sx={{
                                padding: '10px'
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                        <GridToolbarQuickFilter
                            {...props.quickFilterProps}
                            debounceMs={200} // time before applying the new quick filter value
                            sx={{
                                backgroundColor: hasContent ? alpha(theme.palette.warning.light, 0.2) : "inherit"
                            }}
                            quickFilterParser={(searchInput: string) =>
                                getStrings(searchInput)
                            }
                            quickFilterFormatter={(values: NonNullable<GridFilterModel['quickFilterValues']>) =>
                                makeString(values)
                            }
                        />
                        </Badge>
                    </GridToolbarContainer>
                )}
                {!showToolbar && (
                    <GridToolbarContainer>
                        <GridToolbarQuickFilter
                            {...props.quickFilterProps}
                            debounceMs={200} // time before applying the new quick filter value
                            sx={{
                                width: '100%',
                                backgroundColor: hasContent ? alpha(theme.palette.warning.light, 0.2) : "inherit"
                            }}
                            quickFilterParser={(searchInput: string) =>
                                getStrings(searchInput)
                            }
                            quickFilterFormatter={(values: NonNullable<GridFilterModel['quickFilterValues']>) =>
                                makeString(values)
                            }
                        />
                    </GridToolbarContainer>
                )}
            </>
        );
    }

    return (
        <>
            {error === "consent_required" && (
                <ConsentRequiredAlert apiCall={fetchEvents}/>
            )}
            {error === "login_required" && (
                <LoginRequiredAlert apiCall={fetchEvents}/>
            )}
            {error && (
                <WarningAlert
                    warningText={"API error : " + error}/>
            )}
            {!audience && (
                <WarningAlert
                    warningText="App misconfiguration 'audience missing' - the API is not currently available"/>
            )}
            {(eventSearchInProgress) && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
            <Snackbar
                open={transientError != null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                autoHideDuration={6000}
                message={transientError}
                onClose={() => setTransientError(null)}
                ContentProps={{
                    sx: {
                        backgroundColor: alpha(theme.palette.warning.light, 0.9)
                    }
                }}
            />
            <Box sx={{width: '100%'}}>
                <DataGrid
                    apiRef={apiRef}
                    disableColumnMenu={!showToolbar}
                    rows={rows}
                    columns={columns}
                    getRowHeight={({ id/*, densityFactor*/ }: GridRowHeightParams) => {
                        if (expanded === id) {
                            return 'auto';
                        }

                        return null;
                    }}
                    autosizeOptions={{
                        columns: ['eventTopic'],
                        includeOutliers: true,
                        includeHeaders: false,
                        expand: true
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            // size : "small" (default), "medium"
                            // variant : "standard" (default), "filled", "outlined"
                            quickFilterProps: {size : "small", variant : "standard"}
                        },
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'date', sort: 'desc'}],
                        },
                        pagination: {paginationModel: {pageSize: 13}},
                    }}
                    columnVisibilityModel={columnVisible}
                    pageSizeOptions={[13, 50, 100]}
                />
            </Box>
        </>
    );
}
