import React from 'react';
import {Avatar, Card, CardContent, CardHeader, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Amounts} from "../../types/ProcurementNoticeList";
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone';
import {amber} from "@mui/material/colors";

export function getHighAmount(amounts: Amounts | null) {
    if (!amounts) return null;
    if (amounts.amounts) return amounts.amounts[0]
    if (amounts.ranges) return amounts.ranges[0].high
    return null
}

function AmountsCard(props: { amounts: Amounts | null }) {
    // let amount = getHighAmount(props.amounts)
    let amounts = props.amounts?.amounts
    let ranges = props.amounts?.ranges
    let text = props.amounts?.text
    return (
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: amber[500]}} aria-label="Value">
                        <PointOfSaleTwoToneIcon fontSize="medium"/>
                    </Avatar>
                }
                title={<Typography component="h2">Value</Typography>}
            />
            <CardContent>
                <Stack
                    direction="column"
                    sx={{justifyContent: 'space-between', flexGrow: '1', gap: 1}}>
                    <Stack
                        direction="row"
                        sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                        {amounts && amounts!!.map((amount, index) => {
                            return (
                                <Stack direction={"column"} key={index}>
                                    {amount.type && (
                                        <Typography variant="subtitle1" component="div">{amount.type}</Typography>
                                    )}
                                    {!amount.type && (
                                        <Typography variant="subtitle1" component="div">Amount</Typography>
                                    )}
                                    <Typography variant="h5" component="p">{amount.formattedValue}</Typography>
                                </Stack>
                            );
                        })}
                        {ranges && ranges!!.map((range) => {
                            return (
                                <Stack direction={"column"}>
                                    {range.type && (
                                        <Typography variant="subtitle1" component="div">{range.type}</Typography>
                                    )}
                                    {!range.type && (
                                        <Typography variant="subtitle1" component="div">Range</Typography>
                                    )}
                                    <Typography variant="h5" component="p">
                                        {range.low.formattedValue} - {range.high.formattedValue}
                                    </Typography>
                                </Stack>
                            );
                        })}
                        {!text && (
                            <Stack direction={"column"}>
                                <Typography variant="subtitle1" component="div">
                                    Description
                                </Typography>
                                <Typography variant="h5" component="p">
                                    {text}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default AmountsCard;