import React from "react";
import {Avatar, Tooltip} from "@mui/material";
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import PlumbingTwoToneIcon from '@mui/icons-material/PlumbingTwoTone';
import {
    deepPurple,
    green, grey,
    lightBlue,
    pink
} from "@mui/material/colors";
import {ContractType, NoticeMetaData} from "../../types/ProcurementNoticeList";

export function ContractTypeAvatar(props: { metaData: NoticeMetaData }) {

    function contractType() {
        const contractTypes = props.metaData.contractType
        if (contractTypes.length === 0) return "";
        return contractTypes[0].replace(/_/g, " ")
    }

    function getAvatarColor() {
        const contractTypes = props.metaData.contractType

        if (contractTypes.length === 0) return pink[500]

        switch (contractTypes[0]) {
            case ContractType.SERVICES:
                return deepPurple[400]
            case ContractType.WORKS:
                return green[600]
            case ContractType.SUPPLIES:
                return lightBlue[600]
            default:
                return grey[500]
        }
    }

    function contractTypeIcon() {
        const contractTypes = props.metaData.contractType
        const iconSize = "medium"

        if (contractTypes.length === 0) return <QuestionMarkTwoToneIcon fontSize={iconSize}/>;

        switch (contractTypes[0]) {
            case ContractType.SERVICES:
                return <DesignServicesTwoToneIcon fontSize={iconSize}/>
            case ContractType.WORKS:
                return <PlumbingTwoToneIcon fontSize={iconSize}/>
            case ContractType.SUPPLIES:
                return <CategoryTwoToneIcon fontSize={iconSize}/>
            default:
                return <QuestionMarkTwoToneIcon fontSize={iconSize}/>
        }
    }

    const chipSize = 32
    return (
        <Tooltip title={contractType()} arrow>
            <Avatar
                alt={contractType()}
                sx={{
                    bgcolor: getAvatarColor(),
                    width: chipSize,
                    height: chipSize,
                }}
            >
                {contractTypeIcon()}
            </Avatar>
        </Tooltip>
    )
}
