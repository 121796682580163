import React from 'react';
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {formatLocalizedString, ProcurementNotice} from "../../types/ProcurementNoticeList";

export function hasDescription(notice: ProcurementNotice) {
    return notice.description && (notice.description?.shortContractDescription || notice.description?.additionalInformation || notice.description?.economicOperatorsPersonalSituation || notice.description?.totalQuantityOrScope);
}

function DescriptionCard(props: { notice: ProcurementNotice }) {
    const notice = props.notice;

    return (notice &&
        <Card variant="outlined">
            <CardContent>
                {hasDescription(notice) &&
                    <>
                        {notice.description?.shortContractDescription &&
                            formatLocalizedString(notice.description?.shortContractDescription, true)?.map((paragraph, index) =>
                                <Typography variant="body2" sx={{wordBreak: "break-word"}} align={'left'} key={index}>
                                    {index > 0 ? `- ${paragraph}` : paragraph}
                                </Typography>
                            )
                        }
                        {notice.description?.additionalInformation &&
                            formatLocalizedString(notice.description?.additionalInformation, true)?.map((paragraph, index) =>
                                <Typography variant="body2" sx={{wordBreak: "break-word"}} align={'left'} key={index}>
                                    {index > 0 ? `- ${paragraph}` : paragraph}
                                </Typography>
                            )
                        }
                        {notice.description?.totalQuantityOrScope &&
                            formatLocalizedString(notice.description?.totalQuantityOrScope, true)?.map((paragraph, index) =>
                                <Typography variant="body2" sx={{wordBreak: "break-word"}} align={'left'} key={index}>
                                    {index > 0 ? `- ${paragraph}` : paragraph}
                                </Typography>
                            )
                        }
                        {notice.description?.economicOperatorsPersonalSituation &&
                            formatLocalizedString(notice.description?.economicOperatorsPersonalSituation, true)?.map((paragraph, index) =>
                                <Typography variant="body2" sx={{wordBreak: "break-word"}} align={'left'} key={index}>
                                    {index > 0 ? `- ${paragraph}` : paragraph}
                                </Typography>
                            )
                        }
                    </>
                }
            </CardContent>
        </Card>
    );
}

export default DescriptionCard;