import React, {useEffect} from "react";
import {ProcurementNotice} from "../types/ProcurementNoticeList";
import {alpha, Box, LinearProgress, Snackbar, useTheme} from "@mui/material";
import Grid from '@mui/material/Grid2';
import ProcurementNoticeTable from "../components/ProcurementNoticeTable";
import {useNotices} from "../hooks/NoticesProvider";

export function Notices() {
    const { notices, loading, error } = useNotices<ProcurementNotice>();
    const [transientError, setTransientError] = React.useState<string | null>(null)
    const theme = useTheme();

    useEffect(() => {
        if (error) {
            setTransientError("Could not load notices, is online : " + navigator.onLine + "\n" + error)
        }
    }, [error]);

    return <>
        {loading && (
            <Box sx={{width: "100%"}}>
                <LinearProgress/>
            </Box>
        )}
        {notices && (
            <Box sx={{width: "100%"}}>
                <Grid size={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <ProcurementNoticeTable notices={notices}/>
                </Grid>
            </Box>
        )}
        <Snackbar
            open={transientError != null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            autoHideDuration={6000}
            message={transientError}
            onClose={() => setTransientError(null)}
            ContentProps={{
                sx: {
                    backgroundColor: alpha(theme.palette.warning.light, 0.9)
                }
            }}
        />
    </>;
}
