export enum PageKeys {
    alerts = "alerts",
    assignments = "assignments",
    cpvs = "cpvs",
    // home = "home",
    notice = "notice",
    notices = "notices",
    organization = "organization",
    orgs = "orgs",
    profile = "profile",
    search = "search",
}
