import * as React from "react";
import {useEffect} from "react";
import {ApiAuthInterface} from "../types/ApiAuth";
import {
    DataGrid,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarProps,
    GridToolbarQuickFilter,
    useGridApiRef
} from "@mui/x-data-grid";
import {getAssignments, postAssignmentToBrainBase} from "../api/AssignmentsApi";
import {announcedDate, Assignment, hasAppliedFor, linkToNewEvent} from "../types/Assignment";
import {
    alpha,
    Avatar,
    AvatarGroup,
    Badge,
    Box,
    Chip,
    IconButton,
    LinearProgress,
    Link,
    Snackbar,
    Tooltip,
    useTheme
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import {grey} from "@mui/material/colors";
import {getBuyerBackgroundColor, getBuyerIcon, getColor, getIcon, getLink} from "./EventIcon";
import {AssignmentEvent} from "../types/AssignmentEvent";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import {GridFilterModel} from "@mui/x-data-grid/models/gridFilterModel";
import ConsentRequiredAlert from "./ConsentRequiredAlert";
import LoginRequiredAlert from "./LoginRequiredAlert";
import WarningAlert from "./WarningAlert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export const MOBILE_COLUMNS = {
    id: false,
    pinned: false,
    announced: false,
    title: true,
    buyer: true,
    events: false,
    applied: false,
    docs: false,
    brainBaseLink: false
};

export const ALL_COLUMNS = {
    id: true,
    pinned: true,
    announced: true,
    title: true,
    buyer: true,
    events: true,
    applied: true,
    docs: true,
    brainBaseLink: true
};

export default function AssignmentList(props: {
    selectedAssignment?: (assignmentID: string) => void,
    filterTerm: { buyerName: string; assignmentID: string },
    apiAuth: ApiAuthInterface,
    roles: Array<string>
}) {

    const apiRef = useGridApiRef();

    // NOTE: Explicitly avoiding the React optimization of not setting something to its current value
    // When the user erases the quick filter field filterTerm isn't reset, so the user cannot filter again for the
    // same term because React optimizes it out. Holding the terms in an object subverts the optimization.
    const [filterTerm, setFilterTerm] = React.useState(props.filterTerm)

    React.useEffect(() => {
        setFilterTerm(props.filterTerm)
    }, [props.filterTerm])

    React.useEffect(() => {
        let searchTerm = filterTerm.assignmentID ? filterTerm.assignmentID : filterTerm.buyerName;
        let searchTerms = [searchTerm];
        apiRef.current.setQuickFilterValues(searchTerms)
    }, [filterTerm, apiRef]);

    const [assignmentIdToPost, setAssignmentIdToPost] = React.useState<string | null>(null);
    const [rows, setRows] = React.useState(new Array<any>());
    const [error, setError] = React.useState<string | null>(null)
    const [transientError, setTransientError] = React.useState<string | null>(null)
    const [assignmentSearchInProgress, setAssignmentSearchInProgress] = React.useState<boolean>(false);

    const confirmPostToBrainBase = (id: string) => {
        setAssignmentIdToPost(id);
    };

    const handleClose = () => {
        setAssignmentIdToPost(null);
    };

    const postToBrainBase = (): void => {
        console.log("postToBrainBase: " + assignmentIdToPost)
        if (assignmentIdToPost === null) {
            handleClose()
            return
        }
        postAssignmentToBrainBase(props.apiAuth, assignmentIdToPost)
            .then(async (link) => {
                setRows((prevRows) =>
                    prevRows.map((row) =>
                        row.id === assignmentIdToPost ? {...row, brainBaseLink: link} : row,
                    ),
                );
                apiRef.current.updateRows([{id: assignmentIdToPost}])
            })
            .catch((error) => {
                if (error.error !== undefined) {
                    let message = "WARN " + error.error;
                    console.log(message)
                    setTransientError(message);
                } else {
                    let message = "WARN " + error.message;
                    console.log(message)
                    setTransientError(message);
                }
            })
            .finally(() => {
                console.log("postToBrainBase done for : " + assignmentIdToPost)
            })
        handleClose()
    }

    // TODO: figure out why eslint is so mad about this
    // eslint-disable-next-line
    useEffect(() => { fetchAssignments(); }, [])

    const fetchAssignments = (): void => {
        // console.log("fetchAssignments")
        setError(null)
        setAssignmentSearchInProgress(true)
        let updatedRows = new Array<any>();
        getAssignments(props.apiAuth)
            .then((assignments) => {
                const assignmentRows = assignments.map((a: Assignment) => {
                    return {
                        id: a.id,
                        events: a.events,
                        announced: announcedDate(a),
                        title: a.title,
                        link: linkToNewEvent(a),
                        buyer: a.buyerName,
                        pinned: false,
                        docs: "",
                        applied: hasAppliedFor(a),
                        brainBaseLink: null
                    };
                })
                updatedRows = assignmentRows
                setRows(assignmentRows)
                if (assignmentRows.length === 0)
                    setTransientError("No assignments were found, does the user have access to an organization?");
            })
            .catch((error) => {
                if (error.error !== undefined) {
                    let message = "WARN " + error.error;
                    console.log(message)
                    setTransientError(message);
                } else {
                    let message = "WARN " + error.message;
                    console.log(message)
                    setTransientError(message);
                }
            })
            .finally(() => {
                setAssignmentSearchInProgress(false)
                console.log("Assignments received : " + updatedRows.length)
            })
    }

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Id', flex: 0.1, renderCell: (params) => {
                return (
                    <Tooltip title="Events">
                        <IconButton
                            sx={{
                                marginTop: 1,
                                '&:hover': {
                                    backgroundColor: (theme) => theme.palette.primary.main
                                },
                                bgcolor: "primary.dark",
                                width: 32,
                                height: 32,
                                display: 'flex'
                            }}
                            onClick={() => {
                                if (props.selectedAssignment) {
                                    console.log("props.selectedAssignment : " + params.row.assignmentId)
                                    props.selectedAssignment(params.row.id)
                                }
                            }}
                        >
                            <LinkIcon sx={{color: grey[100]}}/>
                        </IconButton>
                    </Tooltip>
                )
            }
        },
        {
            field: 'announced', headerName: 'Announced', flex: 0.3
        },
        {
            field: 'buyer', headerName: '', flex: 0.1, renderCell: (params) => {
                let buyerName = params.row.buyer
                let favicon = getBuyerIcon(buyerName);
                let backgroundColor = getBuyerBackgroundColor(buyerName);
                return (
                    <>
                        {favicon && (
                            <Tooltip title={params.row.buyer}>
                                <IconButton
                                    aria-label={params.row.buyer}
                                    onClick={() => setFilterTerm({assignmentID: "", buyerName: params.row.buyer})}
                                    sx={{
                                        marginTop: 1,
                                        width: 32,
                                        height: 32,
                                        display: 'flex'
                                    }}
                                >
                                    <Avatar
                                        src={favicon}
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            bgcolor: grey[50]
                                        }}
                                        style={{
                                            border: '1px solid gray'
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!favicon && (
                            <Tooltip title={params.row.buyer}>
                                <IconButton
                                    aria-label={params.row.buyer}
                                    onClick={() => setFilterTerm({assignmentID: "", buyerName: params.row.buyer})}
                                    sx={{
                                        marginTop: 1,
                                        width: 32,
                                        height: 32,
                                        display: 'flex'
                                    }}
                                >
                                    <Avatar
                                        alt={params.row.buyer}
                                        sx={{
                                            bgcolor: backgroundColor,
                                            width: 32,
                                            height: 32,
                                        }}
                                        style={{
                                            border: '0.1px solid gray'
                                        }}
                                    >
                                        {params.row.buyer.charAt(0).toUpperCase()}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )
            }
        },
        {
            field: 'title', headerName: 'Assignment', flex: 1.7, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.title}>
                        <Link href={params.row.link} underline="always" target="_blank">
                            {params.row.title}
                        </Link>
                    </Tooltip>
                )
            }
        },
        {
            field: 'events', headerName: 'Events', flex: 0.8, renderCell: (params) => {
                return (
                    <AvatarGroup max={10}>
                        {params.row.events && params.row.events.map((event: AssignmentEvent) => (
                            <Tooltip title={event.eventTopic} key={event.id}>
                                <Avatar
                                    sx={{
                                        marginTop: 1,
                                        bgcolor: getColor(event.eventType),
                                        width: 32,
                                        height: 32
                                    }}
                                    component="a"
                                    href={getLink(event.url)}
                                    target="_blank">
                                    {getIcon(event.eventType)}
                                </Avatar>
                            </Tooltip>
                        ))}
                    </AvatarGroup>
                )
            }
        },
        {
            field: 'brainBaseLink', headerName: 'BrainBase', flex: 0.4, renderCell: (params) => {
                let role = "maintainer";
                let enable = props.roles.includes(role);
                return (
                    <>
                        {params.row.brainBaseLink && (
                            <Tooltip title="Edit on BrainBase">
                                <Chip
                                    label="BrainBase"
                                    icon={<DriveFileRenameOutlineRoundedIcon/>}
                                    color="success"
                                    component="a"
                                    target="_blank"
                                    href={params.row.brainBaseLink}
                                    clickable={enable}
                                    disabled={!enable}
                                />
                            </Tooltip>
                        )}
                        {!params.row.brainBaseLink && (
                            <Tooltip title="Post on BrainBase">
                                <Chip
                                    label="BrainBase"
                                    icon={<CloudUploadOutlinedIcon/>}
                                    color="default"
                                    clickable={enable}
                                    disabled={!enable}
                                    onClick={() => confirmPostToBrainBase(params.row.id)}
                                />
                            </Tooltip>
                        )}
                    </>
                )
            }
        },
    ];

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS);
    const [showToolbar, setShowToolbar] = React.useState(true);

    React.useEffect(() => {
        const newColumns = matches ? ALL_COLUMNS : MOBILE_COLUMNS;
        setColumnVisible(newColumns);
        setShowToolbar(matches)
    }, [matches]);

    const CustomToolbar = (props: GridToolbarProps) => {
        const [hasContent, setHasContent] = React.useState(false);

        function getStrings(searchInput: string) {
            if (hasContent) {
                // TODO maybe clear filterTerms???
                setHasContent(false)
            }
            return [searchInput];
        }

        function makeString(values: NonNullable<GridFilterModel["quickFilterValues"]>) {
            let containsNonEmptyString = values.length > 0 && values[0];
            if (containsNonEmptyString) {
                setHasContent(true)
            }
            return values.length > 0 ? values[0] : "";
        }

        return (
            <>
                {showToolbar && (
                    <GridToolbarContainer>
                        <GridToolbarColumnsButton/>
                        <GridToolbarFilterButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                        <Badge
                            badgeContent={hasContent ? "Filtering" : 0}
                            color="secondary"
                            overlap="rectangular"
                            sx={{
                                padding: '10px'
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <GridToolbarQuickFilter
                                {...props.quickFilterProps}
                                debounceMs={200} // time before applying the new quick filter value
                                sx={{
                                    backgroundColor: hasContent ? alpha(theme.palette.warning.light, 0.2) : "inherit"
                                }}
                                quickFilterParser={(searchInput: string) =>
                                    getStrings(searchInput)
                                }
                                quickFilterFormatter={(values: NonNullable<GridFilterModel['quickFilterValues']>) =>
                                    makeString(values)
                                }
                            />
                        </Badge>
                    </GridToolbarContainer>
                )}
                {!showToolbar && (
                    <GridToolbarContainer>
                        <GridToolbarQuickFilter
                            {...props.quickFilterProps}
                            debounceMs={200} // time before applying the new quick filter value
                            sx={{
                                width: '100%',
                                backgroundColor: hasContent ? alpha(theme.palette.warning.light, 0.2) : "inherit"
                            }}
                            quickFilterParser={(searchInput: string) =>
                                getStrings(searchInput)
                            }
                            quickFilterFormatter={(values: NonNullable<GridFilterModel['quickFilterValues']>) =>
                                makeString(values)
                            }
                        />
                    </GridToolbarContainer>
                )}
            </>
        );
    }

    return (
        <>
            {error === "consent_required" && (
                <ConsentRequiredAlert apiCall={fetchAssignments}/>
            )}
            {error === "login_required" && (
                <LoginRequiredAlert apiCall={fetchAssignments}/>
            )}
            {error && (
                <WarningAlert
                    warningText={"API error : " + error}/>
            )}
            {!props.apiAuth.audience && (
                <WarningAlert
                    warningText="App misconfiguration 'audience missing' - the API is not currently available"/>
            )}
            {(assignmentSearchInProgress) && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
            <Snackbar
                open={transientError != null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                autoHideDuration={6000}
                message={transientError}
                onClose={() => setTransientError(null)}
                ContentProps={{
                    sx: {
                        backgroundColor: alpha(theme.palette.warning.light, 0.9)
                    }
                }}
            />
            <DataGrid
                apiRef={apiRef}
                loading={assignmentSearchInProgress}
                disableColumnMenu={!showToolbar}
                rows={rows}
                columns={columns}
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        // size : "small" (default), "medium"
                        // variant : "standard" (default), "filled", "outlined"
                        quickFilterProps: {size: "small", variant: "standard"}
                    },
                }}
                initialState={{
                    filter: {
                        filterModel: {
                            items: [],
                            // set by apiRef.current.setQuickFilterValues
                            quickFilterValues: [filterTerm.buyerName, filterTerm.assignmentID].filter((value) => value !== null),
                            quickFilterExcludeHiddenColumns: false,
                        },
                    },
                    sorting: {
                        sortModel: [{field: 'announced', sort: 'desc'}],
                    },
                    pagination: {paginationModel: {pageSize: 13}},
                }}
                columnVisibilityModel={columnVisible}
                pageSizeOptions={[13, 50, 100]}
            />
            <React.Fragment>
                <Dialog
                    open={assignmentIdToPost !== null}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Post assignment on BrainBase?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will create a draft post of this assignment on BrainBase.
                            Once the draft is created a link will appear where the button is now.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={postToBrainBase} autoFocus>
                            Post to BrainBase
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    );
}
