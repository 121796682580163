import React from "react";
import {Avatar, Tooltip} from "@mui/material";
import {getAvatarColor, getCpvCategory, getCpvIcon} from "./CpvAvatar";

export function CpvButton(props: { code: string, title: string | null | undefined } ) {
    const avatarColor = getAvatarColor(props.code)
    let tooltip = (props.title) ? props.title : getCpvCategory(props.code);
    return (
        <Tooltip title={`${tooltip} (${props.code})`} arrow>
            <Avatar
                sx={{
                    bgcolor: avatarColor,
                    width: 32,
                    height: 32
                }}
            >
                {getCpvIcon(props.code)}
            </Avatar>
        </Tooltip>
    );
}
