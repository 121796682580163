import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {SetURLSearchParams, useSearchParams} from 'react-router';
import {SearchKeys, toSearchKey} from "../types/SearchKeys";
import {toNumber} from "../types/ProcurementNoticeList";

const SearchParamsStateContext = createContext<[
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams
]>([
    new URLSearchParams(""),
    (_search: unknown) => {},
]);

interface DefaultSearchParamsProp extends PropsWithChildren<{}> {
    defaults: string[][];
}

const SearchParams: React.FC<DefaultSearchParamsProp> = ({ defaults, children }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        defaults.forEach(pair => {
            if (pair.length === 2) {
                let key = pair[0];
                let value = pair[1];
                const searchKey = toSearchKey(key)
                if (searchKey) {
                    //    setSearchParams(params, {       preventScrollReset: true,     });
                    setSearchParams(searchParams => {
                        searchParams.set(searchKey, encodeURIComponent(value))
                        return searchParams;
                    });
                }
            }
        })
    }, [defaults, setSearchParams]);

    return (
        <SearchParamsStateContext.Provider
            value={[searchParams, setSearchParams]}
            key="SearchParamsStateContext.Provider" // TODO ??
        >
            {children}
        </SearchParamsStateContext.Provider>
    );
};

function isNumber(p: any): boolean {
    return typeof p === "number";
}

export function useParams<T extends string | number | boolean = string>(
    key: SearchKeys,
    fallback: T | null
): readonly [searchParamsState: T | null, setSearchParamsState: (newState: (T | null)) => void, deleteSearchParamsState: () => void] {
    const [searchParams, setSearchParams] = useContext(SearchParamsStateContext);
    const [internal, setInternal] = useState<T | null>(null);

    function decode(value: string) {
        return isNumber(internal) ? toNumber(value) : decodeURIComponent(value);
    }
    const acquiredSearchParam = searchParams.get(key);
    const param = acquiredSearchParam == null ? fallback : decode(acquiredSearchParam) as T;

    const setParam = (newState: T | null) => {
        if (newState && newState !== searchParams.get(key)) {
            setInternal(newState)
            setSearchParams(searchParams => {
                if (isNumber(newState)) {
                    searchParams.set(key, newState.toString())
                } else {
                    searchParams.set(key, encodeURIComponent(newState))
                }
                return searchParams;
            });
        }
    };

    const deleteParam = () => {
        setSearchParams(searchParams => {
            searchParams.delete(key)
            return searchParams;
        });
    };

    return [param, setParam, deleteParam];
}

export default SearchParams;
