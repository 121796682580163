import * as React from "react";
import {Tooltip, useTheme} from "@mui/material";
import {uniqueBy} from "../../types/ProcurementNoticeList";
import {makeURLs} from "../Cards/LinksCard";
import {
    getProcurementPlatform, procurementPlatform,
    ProcurementPlatformID
} from "../../types/ProcurementPlatform";
import {getProcurementPlatformIcon} from "../Input/ProcurementPlatformField";

export function procurementPlatformLink(links: string[] | null) {
    if (!links) return null
    const filtered = makeURLs(links)
        .filter((url) => getProcurementPlatform(url.href) !== ProcurementPlatformID.UNKNOWN)
    let unique = uniqueBy(filtered, (v1, v2) => v1.href.toString() === v2.href.toString())
        .sort((a, b) => a.href.localeCompare(b.href));
    return (unique.length > 0) ? unique[0].href : null
}

export function ProcurementPlatformAvatar(props: {
    link: string | null,
    noticeID: string
}) {
    let platformID = getProcurementPlatform(props.link)
    let option = procurementPlatform(platformID)
    let favicon = option.icon
    let platformName = option.title;
    const isDarkTheme = useTheme().palette.mode === 'dark';
    return (
        <>
            {props.link && favicon && (
                <Tooltip title={platformName} key={props.noticeID} arrow>
                    {/*<Avatar*/}
                    {/*    src={favicon}*/}
                    {/*    sx={{*/}
                    {/*        width: 32,*/}
                    {/*        height: 32,*/}
                    {/*        bgcolor: grey[50]*/}
                    {/*    }}*/}
                    {/*    style={{*/}
                    {/*        border: '1px solid gray'*/}
                    {/*    }}*/}
                    {/*    component="a"*/}
                    {/*    target="_blank"*/}
                    {/*    href={props.link}*/}
                    {/*/>*/}
                    {getProcurementPlatformIcon(option.id, 30, isDarkTheme, props.link)}
                </Tooltip>
            )}
            {props.link && !favicon && (
                <Tooltip title={platformName} key={props.noticeID} arrow>
                    {getProcurementPlatformIcon(option.id, 30, isDarkTheme)}
                </Tooltip>
            )}
            {!props.link && !favicon && (
                <Tooltip title={platformName} key={props.noticeID} arrow>
                    { getProcurementPlatformIcon(option.id, 30, isDarkTheme) }
                </Tooltip>
            )}
        </>
    );
}
