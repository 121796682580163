import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {getOrganizations} from "../api/ProcurementSystemApi";
import {orgName, OrganizationRecord} from "../types/OrganizationRecordList";

interface OrganizationsContextType<T> {
    orgs: T[];
    nameToOrgMap: Map<string, T>;
    numberToOrgMap: Map<string, T>;
    loading: boolean;
    error: Error | null;
}

// Create the context
const OrganizationsContext = createContext<OrganizationsContextType<any> | undefined>(undefined);

type OrganizationsProviderProps = {
    children: ReactNode;
};

// Create a provider component
export const OrganizationsProvider = ({ children }: OrganizationsProviderProps) => {
    const [orgs, setOrgs] = useState<any>(null);
    const [nameToOrgMap, setNameToOrgMap] = React.useState<Map<string, OrganizationRecord>>(new Map());
    const [numberToOrgMap, setNumberToOrgMap] = React.useState<Map<string, OrganizationRecord>>(new Map());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                setLoading(true);
                const result = await getOrganizations();
                const hits: OrganizationRecord[] = result.hits.filter((record: OrganizationRecord) => !!record.nationalID && orgName(record) !== "OMITTED");
                const nameMap: Map<string, OrganizationRecord> = new Map();
                const numberMap: Map<string, OrganizationRecord> = new Map();
                hits.forEach((record) => {
                    let name = orgName(record)
                    if (!nameMap.has(name)) {
                        nameMap.set(name, record)
                    }
                    let number = record.nationalID
                    if (number && !numberMap.has(number)) {
                        numberMap.set(number, record)
                    }
                })
                let records = Array.from(nameMap.values())
                let list = [...records]
                // setOptions(list);
                setNameToOrgMap(nameMap)
                setNumberToOrgMap(numberMap)
                setOrgs(list);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizations().then(() => console.log("getOrganizations"));
    }, []);

    return (
        <OrganizationsContext.Provider value={{ orgs, nameToOrgMap, numberToOrgMap, loading, error }}>
            {children}
        </OrganizationsContext.Provider>
    );
};

// Custom hook to use the API context
export function useOrganizations<T>(): OrganizationsContextType<T> {
    const context = useContext(OrganizationsContext);
    if (context === undefined) {
        throw new Error('useOrganizations must be used within an OrganizationsProvider');
    }
    return context as OrganizationsContextType<T>;
}
