import React from 'react';
import {LocalizedText, nameOrgNumber, NoticeLot, OrgNumber, Winner} from "../../types/ProcurementNoticeList";
import Typography from "@mui/material/Typography";
import {Avatar, Card, CardContent, CardHeader, Stack} from "@mui/material";
import {getBuyerBackgroundColor, getBuyerIcon} from "../EventIcon";
import {useNavigate} from 'react-router';
import {amber, green, grey} from "@mui/material/colors";
import {SearchKeys} from "../../types/SearchKeys";
import {PageKeys} from "../../types/PageKeys";
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';

function winnerName(winner: Winner) {
    let id = winner.id;
    if (id.officialName)
        return id.officialName
    if (id.name) {
        let localizedName = id.name?.list;
        let norwegian = localizedName?.filter((localized: LocalizedText) => localized.languageID === "NOR" || localized.languageID === "NOB" || localized.languageID === "NNO")
        if (norwegian && norwegian.length > 0)
            return norwegian[0].text
        let english = localizedName?.filter((localized: LocalizedText) => localized.languageID === "ENG")
        if (english && english.length > 0)
            return english[0].text
    }
    return "OMITTED";
}

export function hasWinners(lots: NoticeLot[] | null) {
    if (!lots) return false
    let count = 0
    lots.forEach((lot) => {
        lot.winner?.forEach((_) => {
            count++
        })
    })
    return count > 0
}

function WinnerCard(props: { winners: NoticeLot[], orgNumber: string | null }) {

    const navigate = useNavigate();

    const handleClick = (nationalId: OrgNumber | null) => {
        if (nationalId) {
            navigate(`/${PageKeys.organization}?${SearchKeys.orgNumber.toString()}=${nationalId.registerID}-${nationalId.id}`);
        }
    };

    const handleMiddleClick = (nationalId: OrgNumber | null, event: any) => {
        if (nationalId && event.button === 1) {
            window.open(`/${PageKeys.organization}?${SearchKeys.orgNumber.toString()}=${nationalId.registerID}-${nationalId.id}`, '_blank')
        }
    };

    function currentWinner(lots: NoticeLot[] | null) {
        if (props.orgNumber && lots != null) {
            const lotsWon = lots.find(lot =>
                lot.winner?.some(
                    winner => winner.id.nationalID && nameOrgNumber(winner.id.nationalID) === props.orgNumber
                )
            );
            return lotsWon?.winner?.find(winner => winner.id.nationalID && nameOrgNumber(winner.id.nationalID) === props.orgNumber);
        }
        return undefined;
    }

    let highlightedWinner = currentWinner(props.winners);
    let awardColor = highlightedWinner ? green[600] : amber[500];
    return (
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            <CardHeader
                sx={{marginBottom: 1}}
                avatar={
                    <Avatar sx={{bgcolor: awardColor}} aria-label="Winners">
                        <EmojiEventsTwoToneIcon fontSize="medium"/>
                    </Avatar>
                }
                title={<Typography variant="h5">Winners</Typography>}
                subheader={highlightedWinner && (
                    <Typography variant="subtitle1" sx={{color: 'text.secondary'}}>
                        {`Won by ${winnerName(highlightedWinner)}`}
                    </Typography>
                )}
            />
            <CardContent>
                {props.winners.map((lot, lotIndex) =>
                    lot.winner?.map((val, index) => {
                        let nameOfWinner = winnerName(val);
                        let backgroundColor = getBuyerBackgroundColor(nameOfWinner);
                        let winnerIcon = getBuyerIcon(nameOfWinner);
                        let isWinner = props.orgNumber === nameOrgNumber(val.id.nationalID);
                        return (
                            <Stack key={`${lotIndex}-${index}`}>
                                <Card
                                    key={val.id.nationalID?.id}
                                    variant="outlined"
                                    data-testid="card"
                                    sx={{
                                        backgroundColor: isWinner ? "success.dark" : "",
                                        color: isWinner ? "success.contrastText" : "",
                                        cursor: "pointer",
                                        transition: "transform 0.2s, box-shadow 0.2s",
                                        '&:hover': {
                                            transform: "scale(1.05)",
                                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                        },
                                    }}
                                    onClick={() => handleClick(val.id.nationalID)}
                                    onMouseDown={(event) => handleMiddleClick(val.id.nationalID, event)}
                                >
                                    <Stack gap={2} direction="row">
                                        <CardHeader
                                            sx={{width: '50%'}}
                                            avatar={
                                                <Avatar
                                                    alt={nameOfWinner}
                                                    src={winnerIcon}
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        bgcolor: winnerIcon ? grey[50] : backgroundColor
                                                    }}
                                                    style={{
                                                        border: '0.2px solid gray'
                                                    }}
                                                >
                                                    {nameOfWinner.charAt(0).toUpperCase()}
                                                </Avatar>
                                            }
                                            title={nameOfWinner}
                                        />
                                        <CardContent>
                                            {val.address.map((add, index) => (
                                                <Typography
                                                    key={index}
                                                    sx={{
                                                        color:
                                                            isWinner
                                                                ? "success.contrastText"
                                                                : 'text.secondary',
                                                    }}
                                                >
                                                    {add.country} {add.countrySubEntity?.text}
                                                </Typography>
                                            ))}
                                        </CardContent>
                                    </Stack>
                                </Card>
                            </Stack>
                        );
                    })
                )}
            </CardContent>
        </Card>
    );
}

export default WinnerCard;
