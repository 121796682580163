/* eslint-disable @typescript-eslint/no-explicit-any */

// https://github.com/manishgcodes/example-indexed-db/blob/main/src/hooks/useIndexedDB.ts
// https://github.com/manishgcodes/example-indexed-db/blob/main/src/App.tsx

import { useEffect, useState } from "react";
export const Database = {
    name: "turtletrace-db",
    version: 1,
    notices: "notices",
};

// Interface defining the return type for useIndexedDB hook
interface UseIndexedDBResult {
    getValue: (tableName: string, id: number) => Promise<any>;
    getAllValue: (tableName: string) => Promise<any[]>;
    putValue: (tableName: string, value: object) => Promise<IDBValidKey | null>;
    putBulkValue: (tableName: string, values: object[], getIndex: (notice: object) => string) => Promise<any[]>;
    updateValue: (params: {
        tableName: string;
        id: number;
        newItem: any;
    }) => void;
    deleteValue: (tableName: string, id: number) => number | null;
    deleteAll: (tableName: string) => void;
    isDBConnecting: boolean;
}

export const useIndexedDB = (
    databaseName: string,
    tableNames: string[]
): UseIndexedDBResult => {
    const [db, setDB] = useState<IDBDatabase | null>(null);
    const [isDBConnecting, setIsDBConnecting] = useState<boolean>(true);

    useEffect(() => {
        const initDB = () => {

            // var req = indexedDB.deleteDatabase(databaseName);
            // req.onsuccess = function () {
            //     console.log("[useIndexedDB] Deleted database successfully");
            // };
            // req.onerror = function () {
            //     console.log("[useIndexedDB] Couldn't delete database");
            // };
            // req.onblocked = function () {
            //     console.log("[useIndexedDB] Couldn't delete database due to the operation being blocked");
            // };

            const request = indexedDB.open(databaseName, Database.version);

            // Handle database upgrade
            request.onupgradeneeded = (event) => {
                console.log(`[useIndexedDB] Upgrade needed from ${event.oldVersion}`);
                const database = request.result;
                tableNames.forEach((tableName) => {
                    if (!database.objectStoreNames.contains(tableName)) {
                        let options = {
                            // autoIncrement: false,
                            // keyPath: "id",
                        };
                        database.createObjectStore(tableName, options);
                    }
                });
            };

            // Handle successful database connection
            request.onsuccess = () => {
                setDB(request.result);
                setIsDBConnecting(false);
            };

            // Handle errors in database connection
            request.onerror = () => {
                console.error("Error initializing IndexedDB:", request.error);
                setIsDBConnecting(false);
            };
        };

        if (!db) {
            initDB();
        }
    }, [databaseName, tableNames, db]);

    // Helper function to get a transaction for a specific table
    const getTransaction = (tableName: string, mode: IDBTransactionMode) => {
        if (!db) throw new Error("ERROR [getTransaction] Database is not initialized");
        return db.transaction(tableName, mode).objectStore(tableName);
    };

    // Function to get a specific value from the table by ID
    const getValue = (tableName: string, id: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                const store = getTransaction(tableName, "readonly");
                const request = store.get(id);
                request.onsuccess = () => resolve(request.result);
                request.onerror = () => reject(request.error);
            } catch (error) {
                reject(error);
            }
        });
    };
    // const getValue = useCallback(
    //     (tableName: string, id: number): Promise<any> => {
    //         return new Promise((resolve, reject) => {
    //             try {
    //                 if (!db) throw new Error("ERROR [getValue] Database is not initialized");
    //                 const store = getTransaction(tableName, "readonly");
    //                 const request = store.get(id);
    //                 request.onsuccess = () => resolve(request.result);
    //                 request.onerror = () => reject(request.error);
    //             } catch (error) {
    //                 reject(error);
    //             }
    //         });
    //     },
    //     [db]
    // );

    // Function to get all values from a specific table
    const getAllValue = (tableName: string): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            try {
                const store = getTransaction(tableName, "readonly");
                const request = store.getAll();
                request.onsuccess = () => resolve(request.result);
                request.onerror = () => reject(request.error);
            } catch (error) {
                reject(error);
            }
        });
    };

    // Function to insert or update a single value in a specific table
    const putValue = (
        tableName: string,
        value: object
    ): Promise<IDBValidKey | null> => {
        return new Promise((resolve, reject) => {
            try {
                const store = getTransaction(tableName, "readwrite");
                const request = store.put(value);
                request.onsuccess = () => resolve(request.result);
                request.onerror = () => reject(request.error);
            } catch (error) {
                reject(error);
            }
        });
    };

    // Function to insert or update multiple values in a specific table
    const putBulkValue = async (
        tableName: string,
        values: object[],
        getIndex: (notice: object) => string
    ): Promise<any[]> => {
        try {
            const store = getTransaction(tableName, "readwrite");
            values.forEach((value) => store.put(value, getIndex(value)));
            return getAllValue(tableName);
        } catch (error) {
            throw new Error("Bulk insert failed: " + error);
        }
    };

    // Function to update a specific value by ID in a specific table
    const updateValue = ({
                             tableName,
                             id,
                             newItem,
                         }: {
        tableName: string;
        id: number;
        newItem: any;
    }) => {
        try {
            const store = getTransaction(tableName, "readwrite");
            const request = store.get(id);
            request.onsuccess = () => {
                const data = request.result;
                const updatedItem = data ? { ...data, ...newItem } : { id, newItem };
                store.put(updatedItem);
            };
        } catch (error) {
            console.error("Update value failed: ", error);
        }
    };

    // Function to delete a specific value by ID from a specific table
    const deleteValue = (tableName: string, id: number): number | null => {
        try {
            const store = getTransaction(tableName, "readwrite");
            store.delete(id);
            return id;
        } catch (error) {
            console.error("Delete value failed: ", error);
            return null;
        }
    };

    // Function to delete all values from a specific table
    const deleteAll = (tableName: string) => {
        try {
            const store = getTransaction(tableName, "readwrite");
            store.clear();
        } catch (error) {
            console.error("Delete all values failed: ", error);
        }
    };

    return {
        getValue,
        getAllValue,
        putValue,
        putBulkValue,
        updateValue,
        deleteValue,
        deleteAll,
        isDBConnecting,
    };
};
