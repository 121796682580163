import React from "react";
import {ContactPoint, ProcurementNotice} from "../../types/ProcurementNoticeList";
import {Avatar, Card, CardContent, CardHeader, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {blue} from "@mui/material/colors";
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

export function hasContactPoint(notice: ProcurementNotice) {
    return notice.buyers && notice.buyers[0].contactPoint
}

// Test case: DOFFIN-2015-109575
export function ContactPointCard(props: { contactPoint: ContactPoint }) {
    return (
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: blue[700]}} aria-label="Contact Point">
                        <AccountCircleTwoToneIcon fontSize="medium"/>
                    </Avatar>
                }
                title={<Typography component="h2">Contact Point</Typography>}
            />

            {/*</CardHeader>*/}
            <CardContent>
                <Stack sx={{
                    alignItems: "left",
                    wordBreak: "break-word"
                }}>
                    {props.contactPoint.name &&
                        <Typography variant="subtitle1" component="p">
                            {props.contactPoint.name}
                        </Typography>
                    }
                    {props.contactPoint.email &&
                        <Typography variant="subtitle1" component="p">
                            {props.contactPoint.email}
                        </Typography>
                    }
                    {props.contactPoint.telephone &&
                        <Typography variant="subtitle1" component="p">
                            {props.contactPoint.telephone}
                        </Typography>
                    }
                    {props.contactPoint.fax &&
                        <Typography variant="subtitle1" component="p">
                            {props.contactPoint.fax}
                        </Typography>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
}
