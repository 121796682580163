import {cpvCategories, CpvCategory, getCpvCategoriesFromString} from "../../types/CpvCategory";
import {Autocomplete, Avatar, Box, Chip, Stack, TextField, Tooltip, useTheme} from "@mui/material";
import * as React from "react";
import {useParams} from "../../hooks/searchParams.hooks";
import {SearchKeys} from "../../types/SearchKeys";
import {getAvatarColor, getCpvIcon} from "../Icons/CpvAvatar";
import Typography from "@mui/material/Typography";

export function CpvCategoryField() {
    const [categories, setCategories, clearCategories] = useParams<string>(SearchKeys.cpvCategories, null);
    const selectedValues = React.useMemo(
        () => getCpvCategoriesFromString(categories),
        [categories],
    );
    const isDarkTheme = useTheme().palette.mode === 'dark';

    function changeCategories(ids: CpvCategory[] | null) {
        if (!ids || ids.length === 0) {
            clearCategories()
        } else {
            let categoryIDs = new Set(ids.map(value => value.id))
            setCategories(Array.from(categoryIDs).join(','))
        }
    }

    return (
        <Autocomplete
            sx={{
                flex: 5,
                minWidth: 0,
                alignSelf: "stretch",
                "& .MuiOutlinedInput-root": {
                    paddingRight: "15px!important",
                },
            }}
            multiple
            disableClearable
            id="cpv-categories"
            key="cpv-categories"
            options={cpvCategories}
            // Controlled Autocomplete needs to deal with value
            // https://mui.com/material-ui/react-autocomplete/#controlled-states
            value={selectedValues}
            onChange={(_event, selectedRecords: CpvCategory[] | null) => {
                changeCategories(selectedRecords)
            }}
            defaultValue={getCpvCategoriesFromString(categories)}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.title}
            renderTags={(value: readonly CpvCategory[], getTagProps) =>
                value.map((option: CpvCategory, index: number) => {
                    const {key, ...tagProps} = getTagProps({index});
                    let prefix = option.iconPrefix;
                    let code = prefix + "000000";
                    let foregroundColor = isDarkTheme ? "black" : "white";
                    return (
                        <Tooltip title={option.title} key={option.id} arrow>
                            <Chip
                                {...tagProps}
                                key={option.id}
                                variant="outlined"
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        padding: "1px",
                                        alignSelf: "center",
                                    },
                                    justifyContent: 'unset'
                                }}
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: getAvatarColor(code),
                                            width: 20,
                                            height: 20
                                        }}
                                        style={{
                                            color: foregroundColor
                                        }}
                                    >
                                        {getCpvIcon(code)}
                                    </Avatar>
                                }
                            />
                        </Tooltip>
                    );
                })
            }
            renderOption={(props, option) => {
                const {key, ...optionProps} = props;
                let prefix = option.iconPrefix;
                let code = prefix + "000000";
                let foregroundColor = isDarkTheme ? "black" : "white";
                return (
                    <Box
                        {...optionProps}
                        key={option.id}
                        component="li"
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                alignItems: "left",
                                gap: 1,
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: getAvatarColor(code),
                                    width: 30,
                                    height: 30
                                }}
                                style={{
                                    color: foregroundColor,
                                    alignSelf: "center",
                                }}
                            >
                                {getCpvIcon(code)}
                            </Avatar>
                            <Typography style={{alignSelf: "center"}}>
                                {option.title}
                            </Typography>
                        </Stack>
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    key={"cpv-category-field"}
                    variant="outlined"
                    label={(categories) ? "Cpv Categories" : "All Cpv Categories"}
                    placeholder="Search"
                />
            )}
        />
    );
}
