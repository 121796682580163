import * as React from "react";
import {PlatformID} from "../../types/ProcurementNoticeList";
import {useParams} from "../../hooks/searchParams.hooks";
import {SearchKeys} from "../../types/SearchKeys";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import {Tooltip} from "@mui/material";


export function getPlatformIdIcon(platformID: PlatformID, toggled: Boolean = true) {
    function getColor() {
        switch (platformID) {
            case PlatformID.TED:
                return "success";
            case PlatformID.DOFFIN:
                return "info";
        }
    }

    const color = (toggled) ? getColor() : "disabled"

    switch (platformID) {
        case PlatformID.TED:
            return <ForumTwoToneIcon fontSize="medium" color={color}/>;
        case PlatformID.DOFFIN:
            return <BarChartTwoToneIcon fontSize="medium" color={color}/>;
    }
}

export function isPlatformIncluded(platformID: PlatformID, excludedPlatforms: string | null) {
    if (!excludedPlatforms) {
        return true
    }
    return !excludedPlatforms.split(",").includes(platformID);
}

export function PlatformToggleButton(props: { platformID: PlatformID }) {
    const [excludedPlatforms, setExcludedPlatforms, clearExcludedPlatforms] = useParams<string>(SearchKeys.excludedNoticePlatforms, null);
    const [toggled, setToggled] = useState(isPlatformIncluded(props.platformID, excludedPlatforms))

    useEffect(() => {
        let isToggled = isPlatformIncluded(props.platformID, excludedPlatforms);
        setToggled(isToggled);
    }, [excludedPlatforms, props.platformID]);

    function removePlatform() {
        const list = excludedPlatforms?.split(",") ?? []
        const removed = list.filter((it) => it !== props.platformID).join(",")
        if (removed)
            setExcludedPlatforms(removed)
        else
            clearExcludedPlatforms()
    }

    function addPlatform() {
        const list = excludedPlatforms?.split(",") ?? []
        const added = new Set(list.concat([props.platformID]))
        setExcludedPlatforms(Array.from(added).join(","))
    }

    function toggle() {
        const platforms = excludedPlatforms?.split(",") ?? []
        if (excludedPlatforms && platforms.includes(props.platformID)) {
            removePlatform()
        } else {
            addPlatform()
        }
    }

    const leadText = (toggled) ? "Exclude " : "Include "

    return <Tooltip title={leadText + props.platformID}>
        <IconButton
            size="large"
            edge="start"
            onClick={() => {
                toggle();
            }}
            sx={{
                width: 40, height: 40, padding: 3, margin: "1px"
            }}
        >
            {getPlatformIdIcon(props.platformID, toggled)}
        </IconButton>
    </Tooltip>
}
