import React from "react";
import {AddressKind, getLanguageString, Place, ProcurementNotice} from "../../types/ProcurementNoticeList";
import {Avatar, Card, CardContent, CardHeader, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {lightGreen} from "@mui/material/colors";
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';

export function hasAddress(notice: ProcurementNotice) {
    // console.log("hasAddress")
    if (notice.buyers.length === 0)
        return false
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    return addresses && addresses.length > 0
}

function AddressItem(props: { addr: Place, title: string }) {
    // console.log(props.title)
    return <>
        <CardHeader
            avatar={
                <Avatar sx={{bgcolor: lightGreen[700]}}>
                    {props.title === AddressKind.OFFICIAL_OCCURRENCE &&
                        <EmailTwoToneIcon fontSize="medium"/>
                    }
                    {props.title === AddressKind.NOTICE_OCCURRENCE &&
                        <HomeTwoToneIcon fontSize="medium"/>
                    }
                    {props.title === AddressKind.PROFILE_OCCURRENCE &&
                        <ContactMailTwoToneIcon fontSize="medium"/>
                    }
                </Avatar>
            }
            title={
                <Typography component="h2">
                    {props.title}
                </Typography>
            }
        />
        <CardContent>
            <Stack sx={{
                alignItems: "left",
                gap: 1, wordBreak: "break-word"
            }}>
                {props.addr.street &&
                    <Typography>
                        {props.addr.street}<br/>
                        {props.addr.postcode} {props.addr.town}<br/>
                        {props.addr.country}<br/>
                    </Typography>
                }
                {!props.addr.street &&
                    <Typography>
                        {getLanguageString(props.addr.desc)?.replace(/[;]/g, ",")}
                    </Typography>
                }
            </Stack>
        </CardContent>
    </>;
}

export function AddressCard(props: {
    buyerOfficialAddress: undefined | Place,
    buyerNoticeAddress: undefined | Place,
    buyerProfileAddress: undefined | Place
}) {
    // console.log("AddressCard")
    // console.log(JSON.stringify(props.buyerProfileAddress))
    // console.log(JSON.stringify(props.buyerNoticeAddress))
    // console.log(JSON.stringify(props.buyerProfileAddress))
    return (
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            {props.buyerOfficialAddress &&
                <AddressItem
                    addr={props.buyerOfficialAddress}
                    title={AddressKind.OFFICIAL_OCCURRENCE}
                />
            }
            {props.buyerNoticeAddress &&
                <AddressItem
                    addr={props.buyerNoticeAddress}
                    title={AddressKind.NOTICE_OCCURRENCE}
                />
            }
            {props.buyerProfileAddress &&
                <AddressItem
                    addr={props.buyerProfileAddress}
                    title={AddressKind.PROFILE_OCCURRENCE}
                />
            }
        </Card>
    );
}