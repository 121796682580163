import {
    alpha,
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    Snackbar,
    Stack,
    useTheme
} from "@mui/material";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import OrganizationDescriptionCard from "src/components/Cards/OrganizationDescriptionCard";
import WonNoticesCard from "src/components/Cards/WonNoticesCard";
import {getBuyerBackgroundColor, getBuyerIcon} from "src/components/EventIcon";
import {getOrganization} from "../api/ProcurementSystemApi";
import PublishedNoticesCard from "../components/Cards/PublishedNoticesCard";
import {orgName, OrganizationRecord} from "../types/OrganizationRecordList";
import {buildOrgNumber, ProcurementNotice} from "../types/ProcurementNoticeList";
import {grey} from "@mui/material/colors";
import {SearchKeys} from "../types/SearchKeys";
import {useNotices} from "../hooks/NoticesProvider";

function Organization() {
    const location = useLocation(); // Use React Router's location object
    const searchParams = new URLSearchParams(location.search);
    const orgNumber = (searchParams.get(SearchKeys.orgNumber.toString()) as string) || null;
    const [searchInProgress, setSearchInProgress] = React.useState<boolean>(false);
    const [result, setResult] = useState<OrganizationRecord | undefined>(undefined);
    const [publishedNotice, setPublishedNotice] = useState<ProcurementNotice[]>();
    const [winnerNotices, setWinnerNotices] = useState<ProcurementNotice[]>();

    const { notices, loading, error } = useNotices<ProcurementNotice>();
    const [transientError, setTransientError] = React.useState<string | null>(null)
    const theme = useTheme();

    useEffect(() => {
        if (error) {
            setTransientError("Could not load notices, is online : " + navigator.onLine + "\n" + error)
        }
    }, [error]);

    useEffect(() => {

        function getPublishedProcurementNotices(notices: ProcurementNotice[] | null) {
            if (notices !== null) {
                return notices.filter(notice =>
                    `${notice.buyers[0].id.nationalID?.registerID}-${notice.buyers[0].id.nationalID?.id}` === orgNumber
                );
            }
            return [];
        }

        function getWonProcurementNotices(notices: ProcurementNotice[] | null) {
            if (notices !== null) {
                return notices.filter(notice =>
                    notice.noticeLots?.some(lot =>
                        lot.winner?.some(
                            winner => winner.id.nationalID && `${winner.id.nationalID?.registerID}-${winner.id.nationalID?.id}` === orgNumber
                        )
                    )
                );
            }
            return [];
        }

        function initBuyer() {
            let published = getPublishedProcurementNotices(notices);
            setPublishedNotice(published);
            let won = getWonProcurementNotices(notices);
            setWinnerNotices(won);
        }

        if (orgNumber && notices.length !== 0)
            initBuyer();
    }, [notices, orgNumber]);

    useEffect(() => {

        if (orgNumber && result === undefined && !searchInProgress) {
            setSearchInProgress(true)
            getOrganization(orgNumber)
                .then((data) => {
                    setResult(data.hits[0]);
                })
                .finally(() => {
                    setSearchInProgress(false)
                });

        }
    }, [orgNumber, result, searchInProgress]);

    let nameOfBuyer = orgName(result);
    let buyerIcon = getBuyerIcon(nameOfBuyer);
    let backgroundColor = getBuyerBackgroundColor(nameOfBuyer);
    return (
        <div>
            {(searchInProgress || loading) && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            {result && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginY: "16px", display: "block", height: "100vh" }}>
                    <Card variant={"outlined"}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    alt={nameOfBuyer}
                                    src={buyerIcon}
                                    sx={{
                                        // width: 24,
                                        // height: 24,
                                        bgcolor: buyerIcon ? grey[50] : backgroundColor
                                    }}
                                    style={{
                                        border: '0.2px solid gray'
                                    }}
                                >
                                    {nameOfBuyer.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            title={
                                <Stack>
                                    <Typography component={"p"} sx={{wordBreak: "break-word"}} variant={"h4"}>
                                        {nameOfBuyer}
                                    </Typography>
                                    <Typography component={"p"} sx={{wordBreak: "break-word"}} variant={"h5"}>
                                        {result.nationalID?.toUpperCase() || ""}
                                    </Typography>
                                </Stack>
                            }
                        >
                        </CardHeader>
                        <CardContent sx={{ display: "inline-flex" }}>
                            <Grid container
                                spacing={2} sx={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    width: "92vw",
                                    padding: "16px"
                                }}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Stack gap={2} direction="column">
                                        <OrganizationDescriptionCard orgNumber={buildOrgNumber(orgNumber)} organization={result}></OrganizationDescriptionCard>
                                        {(winnerNotices && winnerNotices.length > 0)  &&
                                            <WonNoticesCard notices={winnerNotices} orgNumber={orgNumber} />
                                        }
                                        {(publishedNotice && publishedNotice.length > 0) &&
                                            <PublishedNoticesCard notices={publishedNotice} orgNumber={orgNumber} />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
            <Snackbar
                open={transientError != null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                autoHideDuration={6000}
                message={transientError}
                onClose={() => setTransientError(null)}
                ContentProps={{
                    sx: {
                        backgroundColor: alpha(theme.palette.warning.light, 0.9)
                    }
                }}
            />
        </div>
    );
}


export default Organization;