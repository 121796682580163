import {formatLocalizedString, toLocalizedString} from "./ProcurementNoticeList";

export interface OrganizationRecordList {
    numHitsTotal: number
    numHitsAccessible: number
    hits: OrganizationRecord[]
}
export interface OrganizationRecord {
    orgID?: string | null,
    nationalID?: string | null,
    registerID?: string |null, // TODO Can be dropped now?
    orgProfileIDs?: string[] | null,
    officialName?: string | null,
    name?: string | null,
    internetAddress?:  string[] | null,
    department?: string | null,
    street?: string | null,
    town?: string | null,
    postcode?: string | null,
    country?: string | null,
    countrySubEntity?: string | null,
    region?: string | null,
    description?: string | null,
    nace?: string[] | null,
    parentNationalID?: string | null,
    addressDescription?: string | null,
    orgRoles?: string[] | null,
    kinds?: string[] | null,
    source?: string[] | null
}

export function orgName(buyer: OrganizationRecord | undefined) {
    if (!buyer) return "OMITTED";
    if (buyer.officialName)
        return buyer.officialName
    if (buyer.name) {
        let localized = toLocalizedString(buyer.name);
        let names = formatLocalizedString(localized, false);
        if (names.length > 0) return names[0]
    }
    return "OMITTED";
}
