export interface ProcurementStatus {
    id: ProcurementStatusID,
    title: string,
}

export enum ProcurementStatusID {
    // Doffin Search
    ACTIVE = "ACTIVE",
    AWARDED = 'AWARDED',
    CANCELLED = "CANCELLED",
    EXPIRED = "EXPIRED",
    PERIODIC = "PERIODIC",

    // Fallback
    UNKNOWN = "UNKNOWN"
}

let active = {
    id: ProcurementStatusID.ACTIVE,
    title: "Active",
} as ProcurementStatus;

let awarded = {
    id: ProcurementStatusID.AWARDED,
    title: "Awarded",
} as ProcurementStatus;

let cancelled = {
    id: ProcurementStatusID.CANCELLED,
    title: "Cancelled",
} as ProcurementStatus;

let expired = {
    id: ProcurementStatusID.EXPIRED,
    title: "Expired",
} as ProcurementStatus;

let periodic = {
    id: ProcurementStatusID.PERIODIC,
    title: "Periodic",
} as ProcurementStatus;

let unknownProcurementStatus = {
    id: ProcurementStatusID.UNKNOWN,
    title: "Unknown",
} as ProcurementStatus;

export const procurementStatuses = [
    active,
    awarded,
    cancelled,
    expired,
    periodic,
    unknownProcurementStatus
];

export function toProcurementStatus(id: string): ProcurementStatus | null {
    let category = procurementStatuses.find((it) => it.id === id);
    return category ? category : null
}

export function getProcurementStatuss(ids: string[]) {
    return ids.map((it) => toProcurementStatus(it)).filter((it) => it !== null)
}

export function getProcurementStatussFromString(ids: string | null) {
    if (!ids) return []
    return getProcurementStatuss(ids.split(","))
}
