import * as React from "react";
import {useParams} from "../../hooks/searchParams.hooks";
import {SearchKeys} from "../../types/SearchKeys";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import ShoppingBasketTwoToneIcon from '@mui/icons-material/ShoppingBasketTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import {Tooltip} from "@mui/material";
import {OrgRoleType} from "../../types/ProcurementNoticeList";

export function getRoleIdIcon(roleID: OrgRoleType, toggled: Boolean,  disabled: Boolean) {
    function getColor() {
        if (disabled)
            return "disabled"
        switch (roleID) {
            case OrgRoleType.WINNER:
                return "success";
            case OrgRoleType.BUYER:
                return "info";
        }
    }

    const color = (toggled) ? getColor() : "disabled"

    switch (roleID) {
        case OrgRoleType.WINNER:
            return <EmojiEventsTwoToneIcon fontSize="medium" color={color}/>;
        case OrgRoleType.BUYER:
            return <ShoppingBasketTwoToneIcon fontSize="medium" color={color}/>;
    }
}

export function isRoleIncluded(roleID: OrgRoleType, excludedRoles: string | null) {
    if (!excludedRoles) return true
    return !excludedRoles.split(",").includes(roleID);
}

export function RoleToggleButton(props: { roleType: OrgRoleType }) {
    const [orgNumber] = useParams<string>(SearchKeys.orgNumber, null);
    const [excludedRoles, setExcludedRoles, clearExcludedRoles] = useParams<string>(SearchKeys.excludedRoles, null);
    const [toggled, setToggled] = useState(isRoleIncluded(props.roleType, excludedRoles))

    useEffect(() => {
        let isToggled = isRoleIncluded(props.roleType, excludedRoles);
        setToggled(isToggled);
    }, [excludedRoles, props.roleType]);

    function removeRole() {
        const list = excludedRoles?.split(",") ?? []
        const removed = list.filter((it) => it !== props.roleType).join(",")
        if (removed)
            setExcludedRoles(removed)
        else
            clearExcludedRoles()
    }

    function addRole() {
        const list = excludedRoles?.split(",") ?? []
        const added = new Set(list.concat([props.roleType]))
        setExcludedRoles(Array.from(added).join(","))
    }

    function toggle() {
        const roles = excludedRoles?.split(",") ?? []
        if (excludedRoles && roles.includes(props.roleType)) {
            removeRole()
        } else {
            addRole()
        }
    }

    function getLeadText() {
        if (orgNumber === null) {
            return "Organization filter: "
        }
        return (toggled) ? `Remove filter: ` : `Apply filter: `;
    }

    const leadText = getLeadText()

    return <Tooltip title={leadText + props.roleType} arrow>
        <div>
            <IconButton
                size="large"
                edge="start"
                disabled={orgNumber === null}
                onClick={() => {
                    toggle();
                }}
                sx={{
                    width: 40, height: 40, padding: 3, margin: "1px"
                }}
            >
                {getRoleIdIcon(props.roleType, toggled, (orgNumber === null))}
            </IconButton>
        </div>
    </Tooltip>
}
