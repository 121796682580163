import React from "react";
import {Avatar, Tooltip} from "@mui/material";
import UnpublishedTwoToneIcon from '@mui/icons-material/UnpublishedTwoTone';
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import OfflinePinTwoToneIcon from '@mui/icons-material/OfflinePinTwoTone';
import ShoppingBasketTwoToneIcon from '@mui/icons-material/ShoppingBasketTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import {
    amber,
    blueGrey,
    green,
    grey,
    orange
} from "@mui/material/colors";
import {OrgRoleType} from "../../types/ProcurementNoticeList";
import {ProcurementStatusID} from "../../types/ProcurementStatus";

export function StatusTypeAvatar(props: { status: ProcurementStatusID | null, role: OrgRoleType | null}) {

    function getAvatarColor() {
        switch (props.status) {
            case ProcurementStatusID.ACTIVE:
                return (props.role) ? green[400] : green[600]
            case ProcurementStatusID.AWARDED:
                return (props.role) ? amber[400] : amber[600]
            case ProcurementStatusID.CANCELLED:
                return (props.role) ? orange[400] : orange[600]
            case ProcurementStatusID.EXPIRED:
                return (props.role) ? blueGrey[400] : blueGrey[600]
            case ProcurementStatusID.PERIODIC:
                return (props.role) ? grey[400] : grey[600]
            case ProcurementStatusID.UNKNOWN:
                return (props.role) ? grey[400] : grey[600]
            default:
                return (props.role) ? grey[400] : grey[500]
        }
    }

    function getAvatarIcon() {
        const iconSize = "medium"

        switch (props.role) {
            case OrgRoleType.WINNER:
                return <EmojiEventsTwoToneIcon fontSize={iconSize}/>
            case OrgRoleType.BUYER:
                return <ShoppingBasketTwoToneIcon fontSize={iconSize}/>
        }

        switch (props.status) {
            case ProcurementStatusID.ACTIVE:
                return <AddTaskTwoToneIcon fontSize={iconSize}/>
            case ProcurementStatusID.AWARDED:
                return <OfflinePinTwoToneIcon fontSize={iconSize}/>
            case ProcurementStatusID.CANCELLED:
                return <UnpublishedTwoToneIcon fontSize={iconSize}/>
            case ProcurementStatusID.EXPIRED:
                return <UnpublishedTwoToneIcon fontSize={iconSize}/>
            case ProcurementStatusID.PERIODIC:
                return <TimerTwoToneIcon fontSize={iconSize}/>
            case ProcurementStatusID.UNKNOWN:
                return <HelpTwoToneIcon fontSize={iconSize}/>
            default:
                return <HelpTwoToneIcon fontSize={iconSize}/>
        }
    }

    const chipSize = 32

    function label(): string {
        switch (props.role) {
            case OrgRoleType.WINNER: return "WON (" + props.status + ")";
            case OrgRoleType.BUYER: return props.role + " (" + props.status + ")"
        }

        let status = props.status;

        if (!status) return "Missing status"

        switch (status!!) {
            case ProcurementStatusID.ACTIVE: return status
            case ProcurementStatusID.AWARDED: return status
            case ProcurementStatusID.CANCELLED: return status
            case ProcurementStatusID.EXPIRED: return status
            case ProcurementStatusID.UNKNOWN: return status
            default: return status
        }
    }

    let text = label();
    return (
        <Tooltip title={text} arrow>
            <Avatar
                alt={text}
                sx={{
                    border: '0px',
                    bgcolor: getAvatarColor(),
                    width: chipSize,
                    height: chipSize,
                }}
            >
                {getAvatarIcon()}
            </Avatar>
        </Tooltip>
    )
}
