import * as React from "react";
import {
    GridApi,
    gridColumnDefinitionsSelector,
    gridColumnFieldsSelector,
    gridFilteredSortedRowIdsSelector
} from "@mui/x-data-grid";
import {buildCSV} from "./csvSerializer";

export const getJson = (apiRef: React.RefObject<GridApi>) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const columnsField = gridColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row: Record<string, any> = {};
        columnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(data, null, 2);
};

export const exportBlob = (blob: Blob, filename: string) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    });
};

export const exportJson = (apiRef: React.RefObject<GridApi>, filename: string = 'turtleTraceNotices.json') => {
    const jsonString = getJson(apiRef);
    const blob = new Blob([jsonString], {
        type: 'text/json',
    });
    exportBlob(blob, filename);
}

function getCsv(apiRef: React.RefObject<GridApi>) {
    const exportedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const exportedColumns = gridColumnDefinitionsSelector(apiRef);

    return buildCSV({
        columns: exportedColumns,
        rowIds: exportedRowIds,
        csvOptions: {
            delimiter: ';',
            shouldAppendQuotes: true,
            includeHeaders: true,
            includeColumnGroupsHeaders: true,
            escapeFormulas: true,
        },
        ignoreValueFormatter: false,
        apiRef,
    });
}

export const exportCsv = (apiRef: React.RefObject<GridApi>, filename: string = 'turtleTraceNotices.csv') => {
    let csv = getCsv(apiRef);

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
        type: 'text/csv',
    });

    exportBlob(blob, filename);
}

export function copyUrl() {
    navigator.clipboard.writeText(window.location.href).then(() => console.log("Url copied"));
}
