import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router";
import {OrgReg} from "../../types/OrgReg";
import {buildOrgNumber, nameOrgNumber} from "../../types/ProcurementNoticeList";
import {findCompany} from "../../api/BrregApi";
import {SearchKeys} from "../../types/SearchKeys";

export function BrregCard() {
    const location = useLocation(); // Use React Router's location object
    const searchParams = new URLSearchParams(location.search);
    const [org, setOrg] = React.useState<OrgReg | null>(null);
    const [orgNumber] = useState(buildOrgNumber(searchParams.get(SearchKeys.orgNumber.toString())));

    useEffect(() => {
        let name = nameOrgNumber(orgNumber)
        if (name) {
            try {
                findCompany(name).then((data) => {
                    setOrg(data)
                });
            } catch (error: unknown) {
                setOrg(null)
            }
        } else {
            setOrg(null)
        }
    }, [orgNumber])

    return <>
            {org && (
                <Stack sx={{
                    alignItems: "left",
                    gap: 1, wordBreak: "break-word"
                }}>
                    <Typography>
                        Official name : {org?.navn}
                    </Typography>
                    {org && org.naeringskode1 && (
                        <Typography variant="body1">
                            Industrial codes : {org.naeringskode1.beskrivelse} ({org.naeringskode1.kode})
                        </Typography>
                    )}
                    {org && org.forretningsadresse && (
                        <Typography variant="body1">
                            Address : {org.forretningsadresse.adresse} {org.forretningsadresse.postnummer} {org.forretningsadresse.poststed}
                        </Typography>
                    )}
                    {org && org.slettedato && (
                        <Typography variant="body1">
                            Deleted: {org.slettedato}
                        </Typography>
                    )}
                    {org.vedtektsfestetFormaal && (
                        <Typography variant="body1">
                        {/*// <Typography variant="body1" sx={{fontStyle: "italic"}}>*/}
                            Purpose: <i>"{org?.vedtektsfestetFormaal.join(" ")}"</i>
                        </Typography>
                    )}
                </Stack>
            )}
    </>;
}
