import * as React from "react";
import {Avatar, Tooltip, useTheme} from "@mui/material";
import {NoticeID, noticeIdName, PlatformID} from "../../types/ProcurementNoticeList";
import {getPlatformIdIcon} from "../Buttons/PlatformToggleButton";

export function noticeIdLink(id: NoticeID) {
    switch(id.platform) {
        case PlatformID.DOFFIN : return "https://doffin.no/notices/" + id.id;
        case PlatformID.TED: return "https://ted.europa.eu/en/notice/-/detail/" + id.id;
    }
}

export function PlatformAvatar(props: {
    noticeID: NoticeID
}) {
    const isDarkTheme = useTheme().palette.mode === 'dark';
    let backgroundColor = isDarkTheme ? "black" : "white";
    const chipSize = 40
    let borderColor = props.noticeID.platform === PlatformID.TED ? "#4caf50" : "#0288d1";
    let hoverColor = isDarkTheme ? "#454545" : "#DADBDD";
    return <Tooltip title={noticeIdName(props.noticeID)} arrow>
        <Avatar
            alt={noticeIdName(props.noticeID)}
            sx={{
                bgcolor: backgroundColor,
                width: chipSize,
                height: chipSize,
                '&:hover': {
                    backgroundColor: hoverColor,
                },
            }}
            style={{
                border: '3px solid ' + borderColor,
            }}
            component="a"
            target="_blank"
            href={noticeIdLink(props.noticeID)}
        >
            {getPlatformIdIcon(props.noticeID.platform)}
        </Avatar>
    </Tooltip>
}
