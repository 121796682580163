import React from "react";
import {Autocomplete, CircularProgress, InputAdornment, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
// TurtleTrace types
import {useParams} from "../../hooks/searchParams.hooks";
import {orgName, OrganizationRecord} from "../../types/OrganizationRecordList";
import {SearchKeys} from "../../types/SearchKeys";
import {useOrganizations} from "../../hooks/OrganizationsProvider";
import WarningAlert from "../WarningAlert";

function SpinnerOrClear(props: { loading: boolean, onClick: () => void }) {
    return <InputAdornment
        position="end"
    >
        {props.loading && (
            <CircularProgress color="inherit" size={20}/>
        )}
        {!props.loading && (
            <IconButton
                aria-label={"clear input field"}
                onClick={props.onClick}
                edge="end"
                size="small"
            >
                <ClearIcon fontSize="inherit"/>
            </IconButton>
        )}
    </InputAdornment>;
}

export function OrgSearchField() {
    const [orgNumber, setOrgNumber, clearOrgNumber] = useParams<string>(SearchKeys.orgNumber, null);
    const { orgs, nameToOrgMap, numberToOrgMap, loading, error } = useOrganizations<OrganizationRecord>();

    function changeOrgNumber(orgNumberName: string | null | undefined) {
        if (orgNumberName && numberToOrgMap.has(orgNumberName)) {
            setOrgNumber(orgNumberName)
        }
    }

    function nameToNumber(name: string | null) {
        return name ? nameToOrgMap.get(name)?.nationalID : null;
    }

    function orgToNumber(org: OrganizationRecord | null) {
        return org ? org.nationalID : null;
    }

    function currentOrgName() {
        let org = (orgNumber) ? numberToOrgMap.get(orgNumber) : null;
        return org ? orgName(org) : "";
    }

    return <Autocomplete
        key="org" // TODO???
        selectOnFocus
        clearOnEscape
        sx={{
            flex: 5,
            minWidth: 0,
            alignSelf: "stretch",
            "& .MuiOutlinedInput-root": {
                paddingRight: "15px!important",
            },
        }}
        onChange={(_event, selectedRecord: OrganizationRecord | null) => {
            changeOrgNumber(orgToNumber(selectedRecord))
        }}
        onInputChange={(_event, currentInput: string) => {
            changeOrgNumber(nameToNumber(currentInput))
        }}
        isOptionEqualToValue={(left, right) => {
            let leftID = left.nationalID;
            if (!leftID) return false
            let rightID = right.nationalID;
            if (!rightID) return false
            return leftID === rightID;
        }}
        getOptionLabel={(option) => orgName(option) || ""}
        options={orgs}
        loading={loading}
        renderInput={(params) => {
            let orgName = currentOrgName();
            return (
                <>
                    <TextField
                        {...params}
                        id="OrgSearchField"
                        placeholder={orgName}
                        label={orgName ? orgName : "Organization"}
                        onChange={(event) => {
                            let currentInput = event.target.value.toUpperCase();
                            changeOrgNumber(nameToNumber(currentInput))
                        }}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: <SpinnerOrClear loading={loading} onClick={clearOrgNumber}/>
                            },
                        }}
                    />
                    {error && (
                        <WarningAlert warningText={"getOrganizations API error : " + error}/>
                    )}
                </>
            );
        }}
    />;
}
