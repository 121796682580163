import {Assignment} from "../types/Assignment";
import {EmailMessage} from "../types/EmailMessage";
import {ApiAuth, ApiAuthInterface} from "../types/ApiAuth";

export const getNumberOfAssignments = async (
    apiAuth: ApiAuth
) : Promise<number> => {
    console.log("[getNumberOfAssignments] Call backend api '/api/getNumberOfAssignments'")
    try {
        if (apiAuth.authenticatedUser === undefined) {
            console.log("[getNumberOfAssignments][WARN] User is not logged in")
            return Promise.reject(new Error("User is not logged in"))
        }
        if (apiAuth.accessTokenSilently === undefined) {
            return Promise.reject(new Error("accessTokenSilently undefined"))
        }

        const token = await apiAuth.accessTokenSilently();
        const response = await window.fetch('/api/getNumberOfAssignments', {
            method: 'GET', headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        if (response.ok) {
            let assignmentResponse = await response.json();
            if (assignmentResponse !== undefined) {
                return assignmentResponse
            } else {
                console.log("[getNumberOfAssignments][WARN] No response")
                return Promise.reject(new Error(`No response`))
            }
        } else {
            console.log("[getNumberOfAssignments][ERROR] Error occurred while fetching number of assignments")
            return Promise.reject(new Error("Error occurred while fetching number of assignments"))
        }
    } catch (error: unknown) {
        console.log("[getNumberOfAssignments][ERROR] Error occurred during backend api call '/api/getNumberOfAssignments'")
        return Promise.reject(error)
    }
};

export const getAssignments = async (
    apiAuth: ApiAuth
) : Promise<Assignment[]> => {
    // console.log("[getAssignments] Call backend api '/api/getAssignments'")
    try {
        if (apiAuth.authenticatedUser === undefined) {
            console.log("[getAssignments][WARN] User is not logged in")
            return Promise.reject(new Error("User is not logged in"))
        }
        if (apiAuth.accessTokenSilently === undefined) {
            return Promise.reject(new Error("accessTokenSilently undefined"))
        }

        const token = await apiAuth.accessTokenSilently();
        const response = await fetch('/api/getAssignments', {
            method: 'GET', headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/json;charset=UTF-8',
            },
        })

        if (response.ok) {
            let assignmentResponse = await response.json();
            if (assignmentResponse !== undefined) {
                return assignmentResponse
            } else {
                console.log("[getAssignments][WARN] No response")
                return Promise.reject(new Error(`No response`))
            }
        } else {
            console.log("[getAssignments][ERROR] Error occurred while fetching assignments")
            return Promise.reject(new Error("Error occurred while fetching assignments"))
        }
    } catch (error: unknown) {
        console.log("[getAssignments][ERROR] Error occurred during backend api call '/api/getAssignments'")
        return Promise.reject(error)
    }
};

export const getMessageEvents = async (
    apiAuth: ApiAuth
) : Promise<EmailMessage[]> => {
    // console.log("[getMessageEvents] Call backend api '/api/getMessageEvents'")
    try {
        if (apiAuth.authenticatedUser === undefined) {
            console.log("[getMessageEvents][WARN] User is not logged in")
            return Promise.reject(new Error("User is not logged in"))
        }
        if (apiAuth.accessTokenSilently === undefined) {
            return Promise.reject(new Error("accessTokenSilently undefined"))
        }

        const token = await apiAuth.accessTokenSilently();
        const response = await window.fetch('/api/getMessageEvents', {
            method: 'GET', headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/json;charset=UTF-8',
            },
        })

        if (response.ok) {
            let eventResponse = await response.json();
            if (eventResponse !== undefined) {
                return eventResponse
            } else {
                console.log("[getMessageEvents][WARN] No response")
                return Promise.reject(new Error(`No response`))
            }
        } else {
            console.log("[getMessageEvents][ERROR] Error occurred while fetching message events")
            return Promise.reject(new Error("Error occurred while fetching message events"))
        }
    } catch (error: unknown) {
        console.log("[getMessageEvents][ERROR] Error occurred during backend api call '/api/getMessageEvents'")
        return Promise.reject(error)
    }
};

export const postAssignmentToBrainBase = async (
    apiAuth: ApiAuthInterface,
    assignmentID: string
) : Promise<string> => {
    console.log("[postAssignmentToBrainBase] Call backend api '/api/postAssignmentToBrainBase'")
    try {
        if (apiAuth.authenticatedUser === undefined) {
            console.log("[postAssignmentToBrainBase][WARN] User is not logged in")
            return Promise.reject(new Error("User is not logged in"))
        }
        if (apiAuth.accessTokenSilently === undefined) {
            return Promise.reject(new Error("accessTokenSilently undefined"))
        }
        const request = {
            assignmentID: assignmentID
        }

        const token = await apiAuth.accessTokenSilently();
        const response = await window.fetch('/api/postAssignmentToBrainBase', {
            method: 'POST', headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify(request)
        })

        if (response.ok) {
            console.log("[postAssignmentToBrainBase] response ok")
            let linkToBrainBaseDraft = await response.text();
            console.log("[postAssignmentToBrainBase] link " + linkToBrainBaseDraft)
            if (linkToBrainBaseDraft !== undefined) {
                return linkToBrainBaseDraft
            } else {
                console.log("[postAssignmentToBrainBase][WARN] No response")
                return Promise.reject(new Error(`No response`))
            }
        } else {
            console.log("[postAssignmentToBrainBase][ERROR] Error occurred while posting to API")
            return Promise.reject(new Error("Error occurred while posting to API"))
        }
    } catch (error: unknown) {
        console.log("[postAssignmentToBrainBase][ERROR] Error occurred during backend api call '/api/postAssignmentToBrainBase'")
        return Promise.reject(error)
    }
};
