import React from "react";
import {Box, Chip, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";

export function FlagAvatar(props: { countryCode: string | null }) {
    function countryName() {
        if (!props.countryCode) return "?"
        if (props.countryCode === "NOR") return "Norway"
        if (props.countryCode === "SWE") return "Sweden"
        if (props.countryCode === "DAN") return "Denmark"
        return "?"
    }
    // https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg
    function countryFlagUrl() {
        if (!navigator.onLine) return "";
        if (!props.countryCode) return "?"
        if (props.countryCode === "NOR") return "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg"
        if (props.countryCode === "SWE") return "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg"
        if (props.countryCode === "DAN") return "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg"
        return ""
    }

    let flagUrl = countryFlagUrl();
    let backgroundImage = (flagUrl) ? `url("${flagUrl}")` : null;
    return (
        <Tooltip title={countryName()} arrow>
            <Box key={"Flag"} aria-label={countryName()}>
                {backgroundImage && (
                    <Chip
                        variant="outlined"
                        style={{
                            border: '0px',
                            width: 32,
                            height: 32,
                            backgroundImage: backgroundImage!!,
                            backgroundRepeat: "cover",
                            backgroundPosition: "center",
                            backgroundSize: 50
                        }}
                    />
                )}
                {!backgroundImage && (
                    <Typography>
                        {props.countryCode}
                    </Typography>
                )}
            </Box>
        </Tooltip>
    );
}

