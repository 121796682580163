import * as React from "react";
import {Chip, Tooltip} from "@mui/material";
import {getBuyerBackgroundColor, getBuyerIcon} from "./EventIcon";
import {Buyer, nameFromBuyer, nameOrgNumber} from "../types/ProcurementNoticeList";
import {SearchKeys} from "../types/SearchKeys";
import {PageKeys} from "../types/PageKeys";

export function BuyerAvatar(props: {
    buyer: Buyer
}) {
    let name = nameFromBuyer(props.buyer);
    let favicon = getBuyerIcon(name);
    let backgroundColor = getBuyerBackgroundColor(name);
    let initial = buyerInitial(name)

    function buyerInitial(buyer: string | undefined) {
        if (buyer && buyer.length > 1)
            return buyer.charAt(0).toUpperCase();
        return "?"
    }

    let orgNumber = nameOrgNumber(props.buyer.id.nationalID);
    let href = (orgNumber) ? `/${PageKeys.organization}?${SearchKeys.orgNumber.toString()}=${orgNumber}` : "";
    return (<>
        {favicon && (
            <Tooltip title={name}>
                <Chip
                    variant="outlined"
                    style={{
                        width: 30,
                        height: 30,
                        // marginTop: 10,
                        backgroundColor: "white",
                        backgroundImage: `url("${favicon}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: 30
                    }}
                    component="a"
                    target="_blank"
                    href={href}
                    clickable={!!href}
                    label=""
                />
            </Tooltip>
        )}
        {!favicon && (
            <Tooltip title={name}>
                <Chip
                    sx={{
                        padding: '0 8px',
                        '& .MuiChip-label': {
                            overflow: 'visible',
                            fontSize: 18,
                            color: "black"
                        },
                    }}
                    style={{
                        width: 30,
                        height: 30,
                        backgroundColor: backgroundColor,
                    }}
                    component="a"
                    target="_blank"
                    href={href}
                    clickable={!!href}
                    label={initial}
                />
            </Tooltip>
        )}
    </>);
}