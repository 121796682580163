import {ProcurementNoticeList} from "../types/ProcurementNoticeList";
import {OrganizationRecordList} from "../types/OrganizationRecordList";

export const getPurchasingSystems = async () : Promise<ProcurementNoticeList> => {
    let endpoint = "getPurchasingSystems"
    console.log(`[${endpoint}] Call backend api '/api/${endpoint}'`)
    return await makeCall(
        endpoint,
        {}
    );
};

export const getNotice = async (id: string) : Promise<ProcurementNoticeList> => {
    let endpoint = "getNotice"
    // console.log(`[${endpoint}] Call backend api '/api/${endpoint}'`)
    const request = { notice_id: id }
    return await makeCall(
        endpoint,
        request
    );
};

export const getOrganization = async (orgNumber: string) : Promise<OrganizationRecordList> => {
    let endpoint = "getOrganization"
    // console.log(`[${endpoint}] Call backend api '/api/${endpoint}'`)
    const request = { org_number: orgNumber }
    return await makeCall(
        endpoint,
        request
    );
};

export const getOrganizations = async () : Promise<OrganizationRecordList> => {
    let endpoint = "getOrganizations"
    // console.log(`[${endpoint}] Call backend api '/api/${endpoint}'`)
    return await makeCall(
        endpoint,
        {}
    );
};

async function makeCall(
    endpoint: string,
    request: object | null
) {
    let url = `/api/${endpoint}`;
    try {
        // console.log(`[${endpoint}][WARN] Fetch ${url}`)
        const response = await window.fetch(url, {
            method: 'POST', headers: {'content-type': 'application/json;charset=UTF-8'},
            body: request ? JSON.stringify(request) : null
        })

        if (response.ok) {
            let eventResponse = await response.json();
            if (eventResponse !== undefined) {
                return eventResponse
            } else {
                console.log(`[${endpoint}][WARN] No response`)
                return Promise.reject(new Error(`[${endpoint}][WARN] No response`))
            }
        } else {
            console.log(`[${endpoint}][ERROR] Error occurred while doing search`)
            return Promise.reject(new Error(`[${endpoint}][ERROR] Error occurred while doing search`))
        }
    } catch (error: unknown) {
        console.log(`[${endpoint}][ERROR] Error occurred during backend api call '${url}'`)
        return Promise.reject(error)
    }
}
