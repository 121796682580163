import React from "react";
import {AvatarGroup, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid2';
import {CpvButton} from "../components/Icons/CpvButton";
import {cpvCategories} from "../types/CpvCategory";

export function CpvCategories() {
    return (
        <Grid container spacing={2}>
            {cpvCategories.map((category) => {
                return (
                    <Grid size={{xs: 4}}>
                        <Stack direction="row" spacing={2}>
                            <AvatarGroup spacing="small" max={999}>
                                {category.prefixes.map((prefix: string) => {
                                    return (
                                        <CpvButton code={prefix + "000000"} title={null}/>
                                    );
                                })}
                            </AvatarGroup>
                        </Stack>
                        <Typography>
                            {category.descriptions.map((desc: string) => {
                                return <>{desc} <br/></>
                            })}
                        </Typography>
                    </Grid>
                );
            })}
        </Grid>
    );
}
