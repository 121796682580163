import * as React from 'react';

import {BrowserRouter, Route, Routes} from "react-router";
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AppBar from "./components/AppBar";
import {useAuth0} from "@auth0/auth0-react";
import Organization from "./views/Organization";

// styles
import "./App.css";

// fontawesome
import {Box, CssBaseline, CSSInterpolation, LinearProgress} from "@mui/material";

import SearchParams from "./hooks/searchParams.hooks";

// pages
import Alerts from "./views/Alerts";
import AssignmentView from "./views/AssignmentView";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Notice from "./views/Notice";
import {PageKeys} from "./types/PageKeys";
import {OrganizationsProvider} from "./hooks/OrganizationsProvider";
import {NoticesProvider} from "./hooks/NoticesProvider";

const App = () => {
    const {isLoading, error} = useAuth0();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(() => createTheme({
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
        },
        typography: {
            fontSize: 16,
        },
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        background: "transparent",
                        boxShadow: "none",
                        margin: "0px",
                        "&.Mui-expanded": {
                            margin: "0px"
                        },
                    } as CSSInterpolation,
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        marginLeft: "0px",
                        paddingLeft: "0px",
                        justifyContent: "left",
                        textWrap: "nowrap",
                        overflow: "visible",
                        textOverflow: 'ellipsis',
                        flexDirection: 'row-reverse',
                    } as CSSInterpolation,
                    content: {
                        margin: "0px"
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: "2px",
                        paddingBottom: "12px"
                    } as CSSInterpolation,
                }
            }
        },
    }), [prefersDarkMode],);
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <OrganizationsProvider>
                    <NoticesProvider>
                        <SearchParams defaults={[[]]}>
                            <AppBar/>
                            <Container maxWidth={false} disableGutters={false}>
                                <Routes>
                                    <Route path='/' element={<Home/>}/>
                                    <Route path={`/${PageKeys.notices}`} element={<Home/>}/>
                                    <Route path={`/${PageKeys.orgs}`} element={<Home/>}/>
                                    <Route path={`/${PageKeys.cpvs}`} element={<Home/>}/>
                                    <Route path={`/${PageKeys.profile}`} element={<Profile/>}/>
                                    <Route path={`/${PageKeys.assignments}`} element={<AssignmentView/>}/>
                                    <Route path={`/${PageKeys.alerts}`} element={<Alerts/>}/>
                                    <Route path={`/${PageKeys.notice}`} element={<Notice/>}/>
                                    <Route path={`/${PageKeys.organization}`} element={<Organization/>}/>
                                </Routes>
                            </Container>
                        </SearchParams>
                    </NoticesProvider>
                </OrganizationsProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
