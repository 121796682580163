import React from "react";
import {CpvCode} from "../../types/ProcurementNoticeList";
import {Avatar} from "@mui/material";
import AgricultureTwoToneIcon from "@mui/icons-material/AgricultureTwoTone";
import LocalGasStationTwoToneIcon from "@mui/icons-material/LocalGasStationTwoTone";
import EngineeringTwoToneIcon from "@mui/icons-material/EngineeringTwoTone";
import BakeryDiningTwoToneIcon from "@mui/icons-material/BakeryDiningTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import CheckroomTwoToneIcon from "@mui/icons-material/CheckroomTwoTone";
import ColorLensTwoToneIcon from '@mui/icons-material/ColorLensTwoTone';
import StorageTwoToneIcon from "@mui/icons-material/StorageTwoTone";
import ElectricalServicesTwoToneIcon from "@mui/icons-material/ElectricalServicesTwoTone";
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import LocalPoliceTwoToneIcon from "@mui/icons-material/LocalPoliceTwoTone";
import ChairTwoToneIcon from "@mui/icons-material/ChairTwoTone";
import ScienceTwoToneIcon from "@mui/icons-material/ScienceTwoTone";
import PrecisionManufacturingTwoToneIcon from "@mui/icons-material/PrecisionManufacturingTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import BuildTwoToneIcon from "@mui/icons-material/BuildTwoTone";
import HotelTwoToneIcon from "@mui/icons-material/HotelTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import ShowChartTwoToneIcon from "@mui/icons-material/ShowChartTwoTone";
import ArchitectureTwoToneIcon from "@mui/icons-material/ArchitectureTwoTone";
import ComputerTwoToneIcon from "@mui/icons-material/ComputerTwoTone";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import MedicalServicesTwoToneIcon from "@mui/icons-material/MedicalServicesTwoTone";
import RecyclingTwoToneIcon from "@mui/icons-material/RecyclingTwoTone";
import SpaTwoToneIcon from '@mui/icons-material/SpaTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';
import LocalPostOfficeTwoToneIcon from '@mui/icons-material/LocalPostOfficeTwoTone';
import TungstenTwoToneIcon from '@mui/icons-material/TungstenTwoTone';
import BatteryChargingFullTwoToneIcon from '@mui/icons-material/BatteryChargingFullTwoTone';
import WaterDropTwoToneIcon from '@mui/icons-material/WaterDropTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import RadioTwoToneIcon from '@mui/icons-material/RadioTwoTone';
import CarpenterTwoToneIcon from '@mui/icons-material/CarpenterTwoTone';
import BiotechTwoToneIcon from '@mui/icons-material/BiotechTwoTone';
import DrawTwoToneIcon from '@mui/icons-material/DrawTwoTone';
import ForestTwoToneIcon from '@mui/icons-material/ForestTwoTone';
import ParkTwoToneIcon from '@mui/icons-material/ParkTwoTone';
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone';
import LightTwoToneIcon from '@mui/icons-material/LightTwoTone';
import DiamondTwoToneIcon from '@mui/icons-material/DiamondTwoTone';
import SmokingRoomsTwoToneIcon from '@mui/icons-material/SmokingRoomsTwoTone';
import PianoTwoToneIcon from '@mui/icons-material/PianoTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    pink,
    purple,
    red,
    teal,
    yellow
} from "@mui/material/colors";

export function getAvatarColor(code: string) {
    switch(code.substring(0,2)) {
        case "01": return green[600]
        case "02": return green[500]
        case "03": return green[600]
        case "09": return grey[600]
        case "11": return grey[600]
        case "14": return blueGrey[700]
        case "15": return lightGreen[800]
        case "16": return grey[500]
        case "17": return teal[800]
        case "18": return teal[500]
        case "19": return teal[800]
        case "20": return green[500]
        case "22": return brown[300]
        case "24": return red[900]
        case "25": return red[900]
        case "26": return yellow[600]
        case "27": return blue[300]
        case "28": return blue[500]
        case "29": return grey[600]
        case "30": return purple[300]
        case "31": return yellow[800]
        case "32": return grey[700]
        case "33": return red[600]
        case "34": return grey[700]
        case "35": return red[600]
        case "36": return brown[300]
        case "37": return pink[700]
        case "38": return red[900]
        case "39": return cyan[500]
        case "40": return yellow[700]
        case "41": return blue[700]
        case "42": return grey[600]
        case "43": return blueGrey[700]
        case "44": return blueGrey[700]
        case "45": return yellow[700]
        case "48": return deepPurple[300]
        case "50": return blueGrey[800]
        case "51": return amber[900]
        case "55": return blue[400]
        case "60": return grey[600]
        case "63": return teal[500]
        case "64": return lightBlue[400]
        case "65": return yellow[600]
        case "66": return green[500]
        case "67": return green[800]
        case "70": return teal[500]
        case "71": return grey[600]
        case "72": return deepPurple[300]
        case "73": return green[500]
        case "74": return amber[800]
        case "75": return blue[500]
        case "76": return grey[700]
        case "77": return green[600]
        case "78": return purple[300]
        case "79": return grey[700]
        case "80": return brown[300]
        case "85": return pink[400]
        case "90": return green[800]
        case "92": return lightGreen[500]
        case "98": return indigo[600]
        default: return pink[500]
    }
}

export function getCpvCategory(code: string) {
    switch(code.substring(0,2)) {
        // 01000000,"Agricultural, horticultural, hunting and related products.",03000000,"Agricultural, farming, fishing, forestry and related products."
        case "01": return "Agricultural, horticultural, hunting and related products."
        // 02000000,Forestry and logging products.,03400000,Forestry and logging products.
        case "02": return "Forestry and logging products."
        case "03": return "Agricultural, farming, fishing, forestry and related products."
        // TODO 05000000,"Fish, fishing products and other by-products of the fishing industry.",03310000,"Fish, crustaceans and aquatic products."
        case "09": return "Petroleum products, fuel, electricity and other sources of energy."
        // TODO 10000000,"Coal, lignite, peat and other coal-related products.",09240000,Oil and Coal-related products.
        // 11000000,"Petroleum, natural gas, oil and associated products.",09000000,"Petroleum products, fuel, electricity and other sources of energy."
        case "11": return "Petroleum, natural gas, oil and associated products."
        // TODO 12000000,Uranium and thorium ores.,14613000,Uranium and thorium ores.
        // TODO 13000000,Metal ores.,14610000,Metal ores.
        // 14000000,"Mining, quarrying and other associated products.",14000000,"Mining, basic metals and related products."
        case "14": return "Mining, quarrying and other associated products."
        // 15000000,Food products and beverages.,15000000,"Food, beverages, tobacco and related products."
        case "15": return "Food products and beverages."
        // 16000000,"Tobacco, tobacco goods and supplies.",15990000,"Tobacco, tobacco goods and supplies."
        case "16": return "Tobacco, tobacco goods and supplies."
        // 17000000,Textiles and textile articles.,19000000,"Leather and textile fabrics, plastic and rubber materials."
        case "17": return "Textiles and textile articles."
        // 18000000,Clothing and accessories.,18000000,"Clothing, footwear, luggage articles and accessories."
        case "18": return "Clothing, footwear, luggage articles and accessories."
        // 19000000-6;Leather and textile fabrics, plastic and rubber materials.;17000000-2;Textiles and textile articles.;;;;;;
        case "19": return "Leather and textile fabrics, plastic and rubber materials."
        // 20000000,"Wood, wood products, cork products, basketware and wickerwork.",03410000,Wood.
        case "20": return "Wood, wood products, cork products, basketware and wickerwork."
        // TODO 21000000,"Various types of pulp, paper and paper products.",30197600,Processed paper and paperboard.
        // 22000000-0;Printed matter and related products.;22000000-0;Various types of printed matter and articles for printing.;;;;;;
        case "22": return "Printed matter and related products."
        // TODO 23000000,Petroleum products and fuels.,09100000,Fuels.
        // 24000000-4;Chemical products.;24000000-4;Chemicals, chemical products and man-made fibres.;;;;;;
        case "24": return "Chemical products."
        // 25000000,"Rubber, plastic and film products.",19510000,Rubber products.
        case "25": return "Rubber, plastic and film products."
        // 26000000,Non-metallic mineral products.,14800000,Miscellaneous non-metallic mineral products.
        case "26": return "Non-metallic mineral products."
        // 27000000,Basic metals and associated products.,14700000,Basic metals.
        case "27": return "Basic metals and associated products."
        // 28000000,Fabricated products and materials.,31300000,Insulated wire and cable.
        case "28": return "Fabricated products and materials."
        // 29000000,"Machinery, equipment, appliances, apparatus and associated products.",16000000,Agricultural machinery.
        case "29": return "Machinery, equipment, appliances, apparatus and associated products."
        // 30000000-9;Office and computing machinery, equipment and supplies except furniture and software packages.;30000000-9;Office and computing machinery, equipment and supplies.;;;;;;
        case "30": return "Office and computing machinery, equipment and supplies except furniture and software packages."
        // 31000000-6;"Electrical machinery, apparatus, equipment and consumables; Lighting.";31000000-6;Electrical machinery, apparatus, equipment and consumables.;;;;;;
        case "31": return "Electrical machinery, apparatus, equipment and consumables; Lighting."
        // 32000000-3;Radio, television, communication, telecommunication and related equipment.;32000000-3;Radio, television, communication, telecommunication and related equipment and apparatus.;;;;;;
        case "32": return "Radio, television, communication, telecommunication and related equipment."
        // 33000000-0;Medical equipments, pharmaceuticals and personal care products.;33000000-0;Medical and laboratory devices, optical and precision devices, watches and clocks, pharmaceuticals and related medical consumables.;;;;;;
        case "33": return "Medical equipments, pharmaceuticals and personal care products."
        // 34000000-7;Transport equipment and auxiliary products to transportation.;34000000-7;Motor vehicles, trailers and vehicle parts.;;;;;;
        case "34": return "Transport equipment and auxiliary products to transportation."
        // 35000000-4;Security, fire-fighting, police and defence equipment.;29000000-9;Machinery, equipment, appliances, apparatus and associated products.;;;;;;
        case "35": return "Security, fire-fighting, police and defence equipment."
        // 36000000,"Manufactured goods, furniture, handicrafts, special-purpose products and associated consumables.",39000000,"Furniture (incl. office furniture), furnishings, domestic appliances (excl. lighting) and cleaning products."
        case "36": return "Manufactured goods, furniture, handicrafts, special-purpose products and associated consumables."
        // ??? BUG ???
        // 37000000,"Musical instruments, sport goods, games, toys, handicraft, art materials and accessories."
        // 37000000,Recovered secondary raw materials.,14900000,Recovered secondary raw materials.
        case "37": return "Musical instruments, sport goods, games, toys, handicraft, art materials and accessories."
        // 38000000-5;Laboratory, optical and precision equipments (excl. glasses).;33200000-2;Instruments and appliances for measuring, checking, testing and navigating.;;;;;;
        case "38": return "Laboratory, optical and precision equipments (excl. glasses)."
        // 39000000-2;Furniture (incl. office furniture), furnishings, domestic appliances (excl. lighting) and cleaning products.;36000000-1;Manufactured goods, furniture, handicrafts, special-purpose products and associated consumables.;;;;;;
        case "39": return "Furniture (incl. office furniture), furnishings, domestic appliances (excl. lighting) and cleaning products."
        // 40000000,"Electricity, gas, nuclear energy and fuels, steam, hot water and other sources of energy.",09000000,"Petroleum products, fuel, electricity and other sources of energy."
        case "40": return "Electricity, gas, nuclear energy and fuels, steam, hot water and other sources of energy."
        // 41000000-9;Collected and purified water.;41000000-9;Collected and purified water.;;;;;;
        case "41": return "Collected and purified water."
        // 42000000-6;Industrial machinery.;29000000-9;Machinery, equipment, appliances, apparatus and associated products.;;;;;;
        case "42": return "Industrial machinery."
        // 43000000-3;Machinery for mining, quarrying, construction equipment.;29000000-9;Machinery, equipment, appliances, apparatus and associated products.;;;;;;
        case "43": return "Machinery for mining, quarrying, construction equipment."
        // 44000000-0;"Construction structures and materials; auxiliary products to construction (excepts electric apparatus).";28000000-2;Fabricated products and materials.;;;;;;
        case "44": return "Construction structures and materials; auxiliary products to construction (excepts electric apparatus)."
        // 45000000-7;Construction work.;45000000-7;Construction work.;;;;;;
        case "45": return "Construction work."
        // 48000000-8;Software package and information systems.;30240000-3;Software.;;;;;;
        case "48": return "Software package and information systems."
        // 50000000-5;Repair and maintenance services.;50000000-5;Repair, maintenance and installation services.;;;;;;
        case "50": return "Repair and maintenance services."
        // 51000000-9;Installation services (except software).;50000000-5;Repair, maintenance and installation services.;;;;;;
        case "51": return "Installation services (except software)."
        // TODO 52000000,Retail trade services.,55900000,Retail trade services.
        // 55000000-0;Hotel, restaurant and retail trade services.;55000000-0;Hotel and restaurant services.;;;;;;
        case "55": return "Hotel, restaurant and retail trade services."
        // 60000000-8;Transport services (excl. Waste transport).;60000000-8;Land transport services and transport via pipeline services.;;;;;;
        case "60": return "Transport services (excl. Waste transport)."
        // TODO 61000000,Water transport services.,60600000,Water transport services.
        // TODO 62000000,Air transport services.,60400000,Air transport services.
        // 63000000-9;"Supporting and auxiliary transport services; travel agencies services.";63000000-9;"Supporting and auxiliary transport services; travel agencies services.";;;;;;
        case "63": return "Supporting and auxiliary transport services; travel agencies services."
        // 64000000-6;Postal and telecommunications services.;64000000-6;Postal and telecommunications services.;;;;;;
        case "64": return "Postal and telecommunications services."
        // 65000000-3;Public utilities.;65000000-3;Public utilities.;;;;;;
        case "65": return "Public utilities."
        // 66000000-0;Financial and insurance services.;66000000-0;Financial intermediation services.;;;;;;
        case "66": return "Financial and insurance services."
        // 67000000,Services auxiliary to financial intermediation.,66000000,Financial and insurance services.
        case "67": return "Services auxiliary to financial intermediation."
        // 70000000-1;Real estate services.;70000000-1;Real estate services.;;;;;;
        case "70": return "Real estate services."
        // 71000000-8;Architectural, construction, engineering and inspection services.;74000000-9;Architectural, engineering, construction, legal, accounting and other professional services.;;;;;;
        case "71": return "Architectural, construction, engineering and inspection services."
        // 72000000-5;IT services: consulting, software development, Internet and support.;72000000-5;Computer and related services.;;;;;;
        case "72": return "IT services: consulting, software development, Internet and support."
        // 73000000-2(3);Research and development services and related consultancy services.;73000000-2;Research and development services and related consultancy services.(4);;;;;;
        case "73": return "Research and development services and related consultancy services."
        // 74000000,"Architectural, engineering, construction, legal, accounting and other professional services.",71000000,"Architectural, construction, engineering and inspection services."
        case "74": return "Architectural, engineering, construction, legal, accounting and other professional services."
        // 75000000-6;Administration, defence and social security services.;75000000-6;Administration, defence and social security services.;;;;;;
        case "75": return "Administration, defence and social security services."
        // 76000000-3;Services related to the oil and gas industry.;76000000-3;Services related to the oil and gas industry.;;;;;;
        case "76": return "Services related to the oil and gas industry."
        // 77000000-0;Agricultural, forestry, horticultural, aquacultural and apicultural services.;77000000-0;Agricultural, forestry and horticultural services.;;;;;;
        case "77": return "Agricultural, forestry, horticultural, aquacultural and apicultural services."
        // 78000000,"Printing, publishing advertising and marketing services.",79800000,Printing and related services.
        case "78": return "Printing, publishing advertising and marketing services."
        // 79000000-4;Business services: law, marketing, consulting, recruitment, printing and security.;74100000-0;Legal, accounting, auditing, business, management and related services.;;;;;;
        case "79": return "Business services: law, marketing, consulting, recruitment, printing and security."
        // 80000000-4;Education and training services.;80000000-4;Education services.;;;;;;
        case "80": return "Education and training services."
        // 85000000-9;Health and social work services.;85000000-9;Health and social work services.;;;;;;
        case "85": return "Health and social work services."
        // 90000000-7;Sewage-, refuse-, cleaning-, and environmental services.;90000000-7;Sewage- and refuse-disposal services, sanitation and environmental services.;;;;;;
        case "90": return "Sewage-, refuse-, cleaning-, and environmental services."
        // 92000000,"Recreational, cultural and sporting services.",92000000,"Recreational, cultural and sporting services."
        case "92": return "Recreational, cultural and sporting services."
        // 98000000,"Other community, social and personal services.",,
        case "98": return "Other community, social and personal services."
        // TODO 99000000,Services provided by extra-territorial organisations and bodies.,98900000,Services provided by extra-territorial organisations and bodies.
        default: return ""
    }
}

export function getCpvIcon(code: string) {
    switch(code.substring(0,2)) {
        case "01": return <AgricultureTwoToneIcon fontSize="small"/>
        case "02": return <ForestTwoToneIcon fontSize="small"/>
        case "03": return <ForestTwoToneIcon fontSize="small"/>
        case "09": return <LocalGasStationTwoToneIcon fontSize="small"/>
        case "11": return <LocalGasStationTwoToneIcon fontSize="small"/>
        case "14": return <EngineeringTwoToneIcon fontSize="small"/>
        case "15": return <BakeryDiningTwoToneIcon fontSize="small"/>
        case "16": return <SmokingRoomsTwoToneIcon fontSize="small"/>
        case "17": return <CheckroomTwoToneIcon fontSize="small"/>
        case "18": return <DiamondTwoToneIcon fontSize="small"/>
        case "19": return <CheckroomTwoToneIcon fontSize="small"/>
        case "20": return <ParkTwoToneIcon fontSize="small"/>
        case "22": return <MenuBookTwoToneIcon fontSize="small"/>
        case "24": return <ScienceTwoToneIcon fontSize="small"/>
        case "25": return <MedicalServicesTwoToneIcon fontSize="small"/>
        case "26": return <LightTwoToneIcon fontSize="small"/>
        case "27": return <CategoryTwoToneIcon fontSize="small"/>
        case "28": return <CategoryTwoToneIcon fontSize="small"/>
        case "29": return <PrecisionManufacturingTwoToneIcon fontSize="small"/>
        case "30": return <StorageTwoToneIcon fontSize="small"/>
        case "31": return <ElectricalServicesTwoToneIcon fontSize="small"/>
        case "32": return <RadioTwoToneIcon fontSize="small"/>
        case "33": return <MedicalServicesTwoToneIcon fontSize="small"/>
        case "34": return <LocalShippingTwoToneIcon fontSize="small"/>
        case "35": return <LocalPoliceTwoToneIcon fontSize="small"/>
        case "36": return <ChairTwoToneIcon fontSize="small"/>
        case "37": return <PianoTwoToneIcon fontSize="small"/>
        case "38": return <ScienceTwoToneIcon fontSize="small"/>
        case "39": return <ChairTwoToneIcon fontSize="small"/>
        case "40": return <BatteryChargingFullTwoToneIcon fontSize="small"/>
        case "41": return <WaterDropTwoToneIcon fontSize="small"/>
        case "42": return <PrecisionManufacturingTwoToneIcon fontSize="small"/>
        case "43": return <PrecisionManufacturingTwoToneIcon fontSize="small"/>
        case "44": return <CarpenterTwoToneIcon fontSize="small"/>
        case "45": return <HandymanTwoToneIcon fontSize="small"/>
        case "48": return <AnalyticsTwoToneIcon fontSize="small"/>
        case "50": return <BuildTwoToneIcon fontSize="small"/>
        case "51": return <BuildTwoToneIcon fontSize="small"/>
        case "55": return <HotelTwoToneIcon fontSize="small"/>
        case "60": return <LocalShippingTwoToneIcon fontSize="small"/>
        case "63": return <LocalShippingTwoToneIcon fontSize="small"/>
        case "64": return <LocalPostOfficeTwoToneIcon fontSize="small"/>
        case "65": return <TungstenTwoToneIcon fontSize="small"/>
        case "66": return <AccountBalanceTwoToneIcon fontSize="small"/>
        case "67": return <ShowChartTwoToneIcon fontSize="small"/>
        case "70": return <ApartmentTwoToneIcon fontSize="small"/>
        case "71": return <ArchitectureTwoToneIcon fontSize="small"/>
        case "72": return <ComputerTwoToneIcon fontSize="small"/>
        case "73": return <BiotechTwoToneIcon fontSize="small"/>
        case "74": return <GavelTwoToneIcon fontSize="small"/>
        case "75": return <FeedTwoToneIcon fontSize="small"/>
        case "76": return <LocalGasStationTwoToneIcon fontSize="small"/>
        case "77": return <AgricultureTwoToneIcon fontSize="small"/>
        case "78": return <DrawTwoToneIcon fontSize="small"/>
        case "79": return <PrintTwoToneIcon fontSize="small"/>
        case "80": return <SchoolTwoToneIcon fontSize="small"/>
        case "85": return <MedicalServicesTwoToneIcon fontSize="small"/>
        case "90": return <RecyclingTwoToneIcon fontSize="small"/>
        case "92": return <ColorLensTwoToneIcon fontSize="small"/>
        case "98": return <SpaTwoToneIcon fontSize="small"/>
        default: return <ErrorTwoToneIcon fontSize="small"/>
    }
}

export function CpvAvatar(props: { cpvCode: CpvCode }) {
    const cpv = props.cpvCode.id.code
    let avatarColor = getAvatarColor(cpv);
    return <Avatar
        sx={{
            bgcolor: avatarColor,
            width: 32,
            height: 32
        }}
    >
        {getCpvIcon(cpv)}
    </Avatar>;
}
