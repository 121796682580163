import {ProcurementStatusID} from "./ProcurementStatus";

export interface ProcurementNoticeList {
    numHitsTotal: number
    numHitsAccessible: number
    hits: ProcurementNotice[]
}

export enum PlatformID {
    DOFFIN = "DOFFIN",
    TED = "TED"
}

export enum ContractingSystem {
    DYNAMIC_PURCHASING_SYSTEM = "DYNAMIC_PURCHASING_SYSTEM",
    DYNAMIC_PURCHASING_SYSTEM_N = "DYNAMIC_PURCHASING_SYSTEM_N",
    FRAMEWORK_AGREEMENT_MIXED_REOPENING = "FRAMEWORK_AGREEMENT_MIXED_REOPENING",
    FRAMEWORK_AGREEMENT_WITH_REOPENING = "FRAMEWORK_AGREEMENT_WITH_REOPENING",
    FRAMEWORK_AGREEMENT_WITHOUT_REOPENING = "FRAMEWORK_AGREEMENT_WITHOUT_REOPENING",
    FRAMEWORK_AGREEMENT = "FRAMEWORK_AGREEMENT",
    QUALIFICATION_SYSTEM = "QUALIFICATION_SYSTEM",
    NONE = "NONE"
}

export enum ContractType {
    WORKS = "WORKS",
    SUPPLIES = "SUPPLIES",
    SERVICES = "SERVICES",
    UNKNOWN = "UNKNOWN"
}

export function toContractTypes(id: string): ContractType | null {
    let role = Object.keys(ContractType).find((it) => it === id);
    return role ? role as ContractType : null
}

export function getContractTypes(ids: string[]): ContractType[] {
    return ids.map((it) => toContractTypes(it)).filter((it) => it !== null)
}

export function getContractTypesFromString(ids: string | null): ContractType[] {
    if (!ids) return []
    return getContractTypes(ids.split(","))
}

export enum OrgRoleType {
    // TurtleTrace
    WINNER = 'WINNER',
    BUYER = 'BUYER',
}

export function toOrgRoleType(id: string): OrgRoleType | null {
    let role = Object.keys(OrgRoleType).find((it) => it === id);
    return role ? role as OrgRoleType : null
}

export function getOrgRoleTypes(ids: string[]): OrgRoleType[] {
    return ids.map((it) => toOrgRoleType(it)).filter((it) => it !== null)
}

export function getOrgRoleTypesFromString(ids: string | null): OrgRoleType[] {
    if (!ids) return []
    return getOrgRoleTypes(ids.split(","))
}

export interface DoffinID {
    id: string
    published: string | null
    platform: PlatformID // enum PlatformID
}

export interface TedID {
    id: string
    coll: string | null
    no: string | null
    published: string | null
    noDoc: string | null
    validated: boolean
    platform: PlatformID // enum PlatformID
}

export function doffinToNoticeID(id: DoffinID): NoticeID {
    return {
        id: id.id,
        published: id.published,
        platform: id.platform,
        formTypes: []
    } as NoticeID
}

export function tedToNoticeID(id: TedID): NoticeID {
    return {
        id: id.id,
        published: id.published,
        platform: id.platform,
        formTypes: []
    } as NoticeID
}

export interface NoticeID {
    id: string
    published: string | null
    platform: PlatformID // enum PlatformID
    formTypes: string[] // enum NoticeFormType
}

export interface ProcurementID {
    id: string
    platform: string // enum PlatformID
}

export interface CustomID {
    id: string
}

export function noticeID(notice: ProcurementNotice): NoticeID {
    const id = (notice.doffinID) ? doffinToNoticeID(notice.doffinID) : tedToNoticeID(notice.tedID!!)
    return id!!
}

export function toNoticeID(noticeID: string | null): NoticeID | null {
    if (!noticeID) return null
    let id = noticeID.substring(noticeID.indexOf('-') + 1);
    let platform = noticeID.substring(0, noticeID.indexOf('-'));
    return {
        id: id,
        published: null,
        platform: platform as PlatformID,
        formTypes: []
    }
}

export function noticeIdName(notice: NoticeID): string {
    return `${notice.platform}-${notice.id}`
}

export function noticePlatformLink(noticeID: NoticeID | null): string | null {
    if (!noticeID) return null
    switch (noticeID.platform) {
        case PlatformID.TED: return `https://ted.europa.eu/en/notice/-/detail/${noticeID.id}`;
        case PlatformID.DOFFIN: return `https://doffin.no/notices/${noticeID.id}`;
    }
}

export interface ProcurementNotice {
    doffinID: DoffinID | null
    tedID: TedID | null
    norskLysingsBladID: ProcurementID[] | null
    doffinSearchID: ProcurementID[] | null
    procurementIDs: ProcurementID[] | null
    customID: CustomID[] | null
    submissionDeadline: string | null
    buyers: Buyer[]
    informationProvider: any | null
    title: LocalizedString | null
    metaData: NoticeMetaData
    doffinReferences: NoticeID[] | null
    tedReferences: NoticeID[] | null
    links: string[] | null
    mailtoDomains: string[] | null
    productClassification: ProductClassification | null
    contractingSystemFA: boolean | null
    contractingSystemDPS: boolean | null
    contractingSystemPC: boolean | null
    dividedIntoLots: boolean | null
    numberOfLots: number | null
    location: Location
    amounts: Amounts | null
    period: Period | null
    noticeLots: NoticeLot[] | null
    offersReceived: number | null
    recordMetaData: RecordMetaData
    country: string | null // enum CountryCode
    description: NoticeDescription | null
    note: LocalizedString | null
    status: ProcurementStatusID | null
    correction: null // TODO : Correction.kt
}

export function getNoticeID(notice: ProcurementNotice) {
    if (notice.doffinID) {
        let noticeID = notice.doffinID;
        return noticeID?.platform + "-" + noticeID?.id;
    } else {
        let noticeID = notice.tedID;
        return noticeID?.platform + "-" + noticeID?.id;
    }
}

export function getDescription(val: ProcurementNotice) {
    let noticeDescription = getNoticeDescription(val.description);
    if (noticeDescription) {
        return noticeDescription
    }
    let lotDescriptions = val.noticeLots?.map((lot: NoticeLot) => lot.description);
    if (lotDescriptions && lotDescriptions.length === 1 && lotDescriptions[0]) {
        let lotDescription = getNoticeDescription(lotDescriptions[0]);
        if (lotDescription) {
            return lotDescription
        }
    }
    return null;
}

export function getNoticeDescription(noticeDescription: NoticeDescription | null) {
    if (noticeDescription) {
        if (noticeDescription.shortContractDescription)
            return noticeDescription.shortContractDescription
        if (noticeDescription.totalQuantityOrScope)
            return noticeDescription.totalQuantityOrScope
        if (noticeDescription.additionalInformation)
            return noticeDescription.additionalInformation
        if (noticeDescription.economicOperatorsPersonalSituation)
            return noticeDescription.economicOperatorsPersonalSituation
    }
    return null;
}

export interface Buyer {
    id: OrgIdentifier
    address: Place[] | null
    contactPoint: ContactPoint | null
    source: string[] | null // enum OrgSource
}

export function nameFromBuyer(buyer: Buyer | undefined) {
    if (!buyer) return "OMITTED";
    let id = buyer.id;
    if (id.officialName)
        return id.officialName
    if (id.name) {
        let localizedName = id.name?.list;
        let norwegian = localizedName?.filter((localized: LocalizedText) => localized.languageID === "NOR" || localized.languageID === "NOB" || localized.languageID === "NNO")
        if (norwegian && norwegian.length > 0)
            return norwegian[0].text
        let english = localizedName?.filter((localized: LocalizedText) => localized.languageID === "ENG")
        if (english && english.length > 0)
            return english[0].text
    }
    return "OMITTED";
}

export enum AddressKind {
    OFFICIAL_OCCURRENCE = "Official Address",
    NOTICE_OCCURRENCE = "Notice Address",
    PROFILE_OCCURRENCE = "Profile Address"
}

export function getNoticeBuyerAddress(notice: ProcurementNotice) {
    if (notice.buyers.length === 0)
        return undefined
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    if (!addresses || addresses.length === 0)
        return undefined
    let noticeAddr = addresses.filter((addr: Place) => addr.kinds.some((kind: string) => kind === "NOTICE_OCCURRENCE"))
    if (noticeAddr && noticeAddr.length > 0)
        return noticeAddr[0]
    return undefined
}

export function getProfileBuyerAddress(notice: ProcurementNotice) {
    if (notice.buyers.length === 0)
        return undefined
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    if (!addresses || addresses.length === 0)
        return undefined
    let profile = addresses.filter((addr: Place) => addr.kinds.some((kind: string) => kind === "PROFILE_OCCURRENCE"))
    if (profile && profile.length > 0)
        return profile[0]
    return undefined
}

export function getOfficialBuyerAddress(notice: ProcurementNotice) {
    if (notice.buyers.length === 0)
        return undefined
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    if (!addresses || addresses.length === 0)
        return undefined
    let official = addresses.filter((addr: Place) => addr.kinds.some((kind: string) => kind === "OFFICIAL_OCCURRENCE"))
    if (official && official.length > 0)
        return official[0]
    return undefined
}

export interface ContactPoint {
    name: string | null
    email: string | null
    telephone: string | null
    fax: string | null
}

export interface OrgIdentifier {
    name: LocalizedString
    officialName: string | null
    nationalID: OrgNumber | null
    profileID: OrgProfileID[] | null
    orgID: OrgID
    source: string[] | null // enum OrgSource
}

export interface OrgNumber {
    id: string
    registerID: string // enum OrgNumberRegister
}

export function nameOrgNumber(number: OrgNumber | null): string | null {
    if (!number) return null
    return number.registerID + "-" + number.id
}

export function buildOrgNumber(num: string | null): OrgNumber | null {
    if (!num) return null
    if (!num.includes("-"))
        return {id : num, registerID: "BRREG"} as OrgNumber
    let [registerID, ...rest] = num.split('-')
    let id = rest.join('-')
    return {id : id, registerID: registerID} as OrgNumber
}

export interface OrgProfileID {
    id: string
    platform: string
}

export interface OrgID {
    id: string
    documentID: DocumentID | null
}

export interface DocumentID {
    id: string
}

export interface Place {
    department: string | null
    street: string | null
    town: string | null
    postcode: string | null
    country: string | null // enum CountryCode
    countrySubEntity: TextCode | null
    region: string | null
    desc: LocalizedString | null
    kinds: string[] // enum AddressKind
}

export interface TextCode {
    code: string
    listName: string
    text: string
}

export interface NoticeMetaData {
    formTypes: string[]          // enum NoticeFormType
    procedureType: string[]      // enum NoticeProcedureType
    contractType: string[]       // enum NoticeContractType
    legalBasis: string[]         // NoticeLegalBasis
    contractingSystems: string[] // NoticeContractingSystem
}

// export interface FormType {
//     code: string
//     label: string
// }
//
// export interface ContractType {
//     code: string
//     label: string
// }
//
// export interface LegalBasis {
//     code: string
//     label: string
// }

export interface ProductClassification {
    mainCpv: CpvCode[] | null
    additionalCpvs: CpvCode[] | null
    commodityCodes: CommodityContextList | null
}

export function cpvCodes(classification: ProductClassification | null): CpvCode[] {
    if (!classification) return []
    let mainCpv: CpvCode[] | null = (classification.mainCpv) ? classification.mainCpv : []
    let additionalCpvs: CpvCode[] | null = (classification.additionalCpvs) ? classification.additionalCpvs : []
    return [...mainCpv, ...additionalCpvs]
}

export function compareProductClassifications(v1: ProductClassification, v2: ProductClassification) {
    let first = cpvCodes(v1)
    let second = cpvCodes(v2)
    if (!first && !second) return 0
    if (!first) return 1
    if (!second) return -1
    return first[0].id.code.localeCompare(second[0].id.code)
}

export function uniqueBy<T>(arr: T[], fn: (a: T, b: T) => Boolean): any[] {
    return arr.reduce((acc: T[], v: T) => {
        if (!acc.some((x: any) => fn(v, x))) acc.push(v);
        return acc;
    }, []);
}

export function cpvUniqueByLabel(classification: ProductClassification | null): CpvCode[] {
    let all = cpvCodes(classification)
    let filterBy = (a: CpvCode, b: CpvCode) => getLanguageString(a.label) === getLanguageString(b.label);
    return uniqueBy(all, filterBy)
}

export interface CommodityContextList {
    contexts: CommodityContext[]
}

export interface CommodityContext {
    codes: CommodityCode[]
    type: string | null // enum CommodityContextType
}

export interface CommodityCode {
    id: string
    label: LocalizedString
}

export interface CpvCode {
    id: CpvID
    label: LocalizedString | null
    version: string
    correspondingCpv: CpvID | null
}

// export function getCpvCode(id: string): CpvCode {
//     const cpvID = {code: id} as CpvID
//     return {
//         id: cpvID,
//         label: null,
//         version: "",
//         correspondingCpv: null
//     }
// }

export interface CpvID {
    code: string
}

export interface Location {
    nutsContextList: NutsContextList | null
    locationContextList: LocationContextList | null
    locationDescription: LocationDescription | null
}

export interface NutsContextList {
    contexts: NutsContext[]
}

export interface NutsContext {
    codes: NutsCode[]
    type: string | null // enum NutsContextType
}

export interface LocationContextList {
    contexts: LocationContext[]
}

export interface LocationContext {
    codes: LocationCode[]
    type: string | null // enum LocationContextType
}

export interface LocationCode {
    id: string
    label: LocalizedString
}

export interface LocationDescription {
    location: string[]
}

export interface Amounts {
    ranges: AmountRange[] | null
    amounts: Amount[] | null
    text: string | null
}

export interface AmountRange {
    low: Amount,
    high: Amount,
    type: string | null // AmountKind enum
}

export interface Amount {
    value: number | null
    currency: string | null
    localizedText: string | null
    formattedValue: string | null
    excludingVAT: boolean | null
    type: string | null // AmountKind enum
}

export interface Duration {
    duration: string | null
    durationUnit: string | null // enum PeriodDuration
}

export interface Period {
    startDate: string | null
    endDate: string | null
    durations: Duration[] | null
}

export interface LotID {
    id: string,
    type: string
}

export interface LegacyLotID {
    id: string
}

export interface CustomLotID {
    id: string
}

export interface LotIdentifier {
    lotID: LotID,
    legacyLotID: LegacyLotID[] | null,
    customID: CustomLotID | null
}

// export interface Lot {
//     lotIdentifier: LotIdentifier
//     title: LocalizedString | null
//     description: NoticeDescription | null
//     amounts: Amounts | null
//     productClassification: ProductClassification | null
// }

export interface ContractID {
    id: string
}

// export interface WonLot {
//     lotIdentifier: LotIdentifier
//     contractID: ContractID | null
//     awardDate: string | null
//     winner: Winner[] | null
//     offersReceived: string | null
//     isLikelySubContracted: boolean | null
// }

export interface NoticeLot {
    lotIdentifier: LotIdentifier
    contractID: ContractID | null
    title: LocalizedString | null
    description: NoticeDescription | null
    amounts: Amounts | null
    productClassification: ProductClassification | null
    awardDate: string | null
    winner: Winner[] | null
    offersReceived: string | null
    isLikelySubContracted: boolean | null
}

export interface Winner {
    id: OrgIdentifier
    address: Place[]
    contactPoint: ContactPoint | null
    source: string[] // enum OrgSource
}

export interface LocalizedString {
    list: LocalizedText[]
}

export function toLocalizedText(input: string): LocalizedText {
    let pair: string[] = input.split(":", 2)
    return {
        languageID: pair[0],
        text: pair[1],
    }
}

export function toLocalizedString(input: string): LocalizedString | null {
    let parts: string[] = input.split(";");
    let list: LocalizedText[] = parts.map((part: string) => toLocalizedText(part))
    return {
        list: list
    }
}

export function formatLocalizedString(description: LocalizedString | null | undefined, makeBullets: boolean = true) {
    if (!description) return []
    const raw: string | undefined = getLanguageString(description);
    if (!raw) return []
    return (makeBullets) ? formatString(raw) : [raw]
}

export function formatString(raw: string | null) {
    if (!raw) return []
    return raw.replaceAll ('·', '•').replaceAll(" - ", " • ").split("•")
}

export function getLanguageString(localized: LocalizedString | null): string | undefined {
    if (!localized) return undefined
    let localizedList = localized.list;
    if (!localizedList || localizedList.length === 0) return undefined

    let norwegian = localizedList?.filter((localized: LocalizedText) => localized.languageID === "NOR" || localized.languageID === "NOB" || localized.languageID === "NNO")
    if (norwegian && norwegian.length > 0)
        return cleanLocalizedText(norwegian[0].text)

    let english = localizedList?.filter((localized: LocalizedText) => localized.languageID === "ENG")
    if (english && english.length > 0)
        return cleanLocalizedText(english[0].text)

    return cleanLocalizedText(localizedList[0].text)
}

export interface LocalizedText {
    languageID: string
    text: string
}

export function cleanLocalizedText(text: string) {
    return text
        .replace(/[#]/g, ":")
        .replace(/[;]/g, ",")
        .replace(/[¤]/g, ";");
}

export interface RecordMetaData {
    archiveName: string[]
    pathInArchive: string[]
    schemaName: string[]
    note: string[] | null
}

export interface NutsCode {
    id: string
    label: LocalizedString | null
    version: string // enum NutsVersionID
}

export interface NoticeDescription {
    shortContractDescription: LocalizedString | null
    economicOperatorsPersonalSituation: LocalizedString | null
    totalQuantityOrScope: LocalizedString | null
    additionalInformation: LocalizedString | null
}

export function toNumber(input: string): number {
    let number = parseInt(input, 10);
    // console.log("useParams : from " + input + " to " + number);
    return parseInt(number!!.toString(), 10);
}
