import React from "react";
import {Stack} from "@mui/material";
import {OrgSearchField} from "../components/Input/OrgSearchField";
import {PageKeys} from "../types/PageKeys";
import {useNavigate, useSearchParams} from "react-router";

export function Orgs() {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // https://turtletrace.no/organization?orgNumber=BRREG-974778882
        navigate(`/${PageKeys.organization}?${params.toString()}`);
    };

    return <Stack sx={{width: 350}}>
        <form onSubmit={handleSubmit}>
            <OrgSearchField/>
        </form>
    </Stack>;
}
