export interface CpvCategory {
    id: string,
    title: string,
    icon: string,
    iconPrefix: string,
    prefixes: string[],
    descriptions: string[],
}

// https://www.ssb.no/klass/klassifikasjoner/6
export const cpvCategories = [
    {
        id: "01",
        title: "Agriculture, forestry and fishing",
        icon: "🚜",
        iconPrefix: "01",
        prefixes: ["01", "02", "03", "20", "77"],
        descriptions: [
            "A - Agriculture, forestry and fishing"
        ]
    } as CpvCategory,
    {
        id: "02",
        title: "Petroleum, mining and quarrying",
        icon: "🛢",
        iconPrefix: "09",
        prefixes: ["09", "11", "14", "40", "43", "76"],
        descriptions: [
            "B - Petroleum, mining and quarrying"
        ]
    } as CpvCategory,
    {
        id: "03",
        title: "Industry and production",
        icon: "🏭",
        iconPrefix: "28",
        prefixes: ["17", "18", "19", "24", "25", "26", "27", "28", "29", "31", "36", "39", "42"],
        descriptions: [
            "C - Industry and production"
        ]
    } as CpvCategory,
    {
        id: "04",
        title: "Construction and utilities",
        icon: "🚧",
        iconPrefix: "45",
        prefixes: ["41", "44", "45", "50", "51", "71", "74", "90"],
        descriptions: [
            "D - Electricity, gas, steam and hot water supply",
            "E - Water supply, sewage and refuse collection",
            "F - Construction",
        ]
    } as CpvCategory,
    {
        id: "05",
        title: "Transport, vehicles and storage",
        icon: "🚚",
        iconPrefix: "34",
        prefixes: ["34", "60", "63"],
        descriptions: [
            "G - Wholesale trade, repair of motor vehicles",
            "H - Transport and storage",
        ]
    } as CpvCategory,
    {
        id: "06",
        title: "Accommodation and food service",
        icon: "🛎️",
        iconPrefix: "55",
        prefixes: ["15", "16", "55"],
        descriptions: [
            "I - Accommodation and food service activities",
        ]
    } as CpvCategory,
    {
        id: "07",
        title: "IT and communication",
        icon: "🖥",
        iconPrefix: "72",
        prefixes: ["22", "30", "32", "48", "64", "72", "78"],
        descriptions: [
            "J - Information and communication",
        ]
    } as CpvCategory,
    {
        id: "08",
        title: "Finance and business",
        icon: "💼",
        iconPrefix: "66",
        prefixes: ["66", "67", "70", "73", "79"],
        descriptions: [
            "K - Finance and insurance activities",
            "L - Real estate activities",
            "M - Professional, scientific and technical activities",
            "N - Business activities",
        ]
    } as CpvCategory,
    {
        id: "09",
        title: "Public administration and defence",
        icon: "🏛️",
        iconPrefix: "35",
        prefixes: ["35", "65", "75"],
        descriptions: [
            "O - Public administration and defence, and social security schemes under public administration",
        ]
    } as CpvCategory,
    {
        id: "10",
        title: "Education",
        icon: "🎓",
        iconPrefix: "80",
        prefixes: ["80"],
        descriptions: [
            "P - Education",
        ]
    } as CpvCategory,
    {
        id: "11",
        title: "Health and social work",
        icon: "🚑",
        iconPrefix: "33",
        prefixes: ["33", "38", "85"],
        descriptions: [
            "Q - Health and social work activities",
        ]
    } as CpvCategory,
    {
        id: "12",
        title: "Culture, entertainment and recreation",
        icon: "🎨",
        iconPrefix: "98",
        prefixes: ["37", "92", "98"],
        descriptions: [
            "R - Cultural, entertainment and recreational activities",
        ]
    } as CpvCategory,
];

export function toCpvCategory(id: string): CpvCategory | null {
    let category = cpvCategories.find((it) => it.id === id);
    return category ? category : null
}

export function getCpvCategories(ids: string[]) {
    return ids.map((it) => toCpvCategory(it)).filter((it) => it !== null)
}

export function getCpvCategoriesFromString(ids: string | null) {
    if (!ids) return []
    return getCpvCategories(ids.split(","))
}
