import React from "react";
import {Avatar, Tooltip} from "@mui/material";
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import BookTwoToneIcon from '@mui/icons-material/BookTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import {
    brown, cyan,
    grey, pink, teal
} from "@mui/material/colors";
import {ContractingSystem, NoticeMetaData} from "../../types/ProcurementNoticeList";

export function ContractingSystemAvatar(props: { metaData: NoticeMetaData }) {

    function contractingSystem() {
        const contractingSystems = props.metaData.contractingSystems
        if (contractingSystems.length === 0) return "Contract"

        switch (contractingSystems[0]) {
            case ContractingSystem.DYNAMIC_PURCHASING_SYSTEM:
                return "Dynamic Purchasing System"
            case ContractingSystem.DYNAMIC_PURCHASING_SYSTEM_N:
                return "Dynamic Purchasing System"
            case ContractingSystem.FRAMEWORK_AGREEMENT:
                return "Frame Agreement"
            case ContractingSystem.FRAMEWORK_AGREEMENT_MIXED_REOPENING:
                return "Frame Agreement"
            case ContractingSystem.FRAMEWORK_AGREEMENT_WITH_REOPENING:
                return "Frame Agreement"
            case ContractingSystem.FRAMEWORK_AGREEMENT_WITHOUT_REOPENING:
                return "Frame Agreement"
            case ContractingSystem.QUALIFICATION_SYSTEM:
                return "Qualification System"
            default:
                return "Contract"
        }
    }

    function getAvatarColor() {
        const contractingSystems = props.metaData.contractingSystems

        if (contractingSystems.length === 0) return pink[500]

        let dpsColor = teal[500];
        let faColor = brown[600];
        let qsColor = cyan[900];
        let otherColor = grey[500];

        switch (contractingSystems[0]) {
            case ContractingSystem.DYNAMIC_PURCHASING_SYSTEM:
                return dpsColor
            case ContractingSystem.DYNAMIC_PURCHASING_SYSTEM_N:
                return dpsColor
            case ContractingSystem.FRAMEWORK_AGREEMENT:
                return faColor
            case ContractingSystem.FRAMEWORK_AGREEMENT_MIXED_REOPENING:
                return faColor
            case ContractingSystem.FRAMEWORK_AGREEMENT_WITH_REOPENING:
                return faColor
            case ContractingSystem.FRAMEWORK_AGREEMENT_WITHOUT_REOPENING:
                return faColor
            case ContractingSystem.QUALIFICATION_SYSTEM:
                return qsColor
            default:
                return otherColor
        }
    }

    function contractingSystemIcon() {
        const contractingSystems = props.metaData.contractingSystems
        const iconSize = "medium"

        if (contractingSystems.length === 0) return <QuestionMarkTwoToneIcon fontSize={iconSize}/>;

        let dpsIcon = <Inventory2TwoToneIcon fontSize={iconSize}/>;
        let faIcon = <HandshakeTwoToneIcon fontSize={iconSize}/>;
        let qsIcon = <BookTwoToneIcon fontSize={iconSize}/>;
        let otherIcon = <AssignmentTwoToneIcon fontSize={iconSize}/>;

        switch (contractingSystems[0]) {
            case ContractingSystem.DYNAMIC_PURCHASING_SYSTEM:
                return dpsIcon
            case ContractingSystem.DYNAMIC_PURCHASING_SYSTEM_N:
                return dpsIcon
            case ContractingSystem.FRAMEWORK_AGREEMENT:
                return faIcon
            case ContractingSystem.FRAMEWORK_AGREEMENT_MIXED_REOPENING:
                return faIcon
            case ContractingSystem.FRAMEWORK_AGREEMENT_WITH_REOPENING:
                return faIcon
            case ContractingSystem.FRAMEWORK_AGREEMENT_WITHOUT_REOPENING:
                return faIcon
            case ContractingSystem.QUALIFICATION_SYSTEM:
                return qsIcon
            default:
                return otherIcon
        }
    }

    const chipSize = 32
    return (
        <Tooltip title={contractingSystem()} arrow>
            <Avatar
                alt={contractingSystem()}
                sx={{
                    bgcolor: getAvatarColor(),
                    width: chipSize,
                    height: chipSize,
                }}
            >
                {contractingSystemIcon()}
            </Avatar>
        </Tooltip>
    )
}
