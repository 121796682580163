import React, {useEffect, useState} from 'react';
import {Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import {CpvCode, getLanguageString, ProductClassification} from "../../types/ProcurementNoticeList";
import {CpvAvatar} from "../Icons/CpvAvatar";
import {brown} from "@mui/material/colors";
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import CasesTwoToneIcon from '@mui/icons-material/CasesTwoTone';

function CpvCodesCard(props: { classification: ProductClassification }) {
    const [additionalCpvs, setAdditionalCpvs] = useState<CpvCode[]>([]);

    useEffect(() => {
        if (props.classification.additionalCpvs) {
            let codes = props.classification.additionalCpvs;
            setAdditionalCpvs([...codes.sort((a, b) => a.id.code.localeCompare(b.id.code))]);
        }
    }, [props.classification]);
    let hasAdditionalCPVs = additionalCpvs && additionalCpvs.length > 0;
    let mainCPVs = props.classification.mainCpv;
    let hasMainCPVs = mainCPVs && mainCPVs.length > 0;
    return (
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            {hasMainCPVs && (
                <>
                    <CardHeader
                        avatar={
                            <Avatar sx={{bgcolor: brown[500]}}>
                                <WorkTwoToneIcon fontSize="medium"/>
                            </Avatar>
                        }
                        title={
                            <Typography component="h2">
                                Main CPVs
                            </Typography>
                        }
                    />
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: "column",
                            flex: 1,
                        }}>
                        <List>
                            {mainCPVs!!.map((val) => {
                                return (
                                    <ListItem key={val.id.code} data-testid="mainCpv">
                                        <ListItemAvatar>
                                            <CpvAvatar cpvCode={val}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={val.id.code}
                                            secondary={getLanguageString(val.label)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardContent>
                </>
            )}
            {hasAdditionalCPVs && (
                <>
                    <CardHeader
                        avatar={
                            <Avatar sx={{bgcolor: brown[700]}}>
                                <CasesTwoToneIcon fontSize="medium"/>
                            </Avatar>
                        }
                        title={
                            <Typography component="h2">
                                Additional CPVs
                            </Typography>
                        }
                    />
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: "column",
                            flex: 1,
                        }}>
                        <List
                            sx={{
                                flex: 1,
                            }}>
                            {additionalCpvs.map((val) => {
                                return (
                                    <ListItem key={val.id.code} data-testid="additionalCpvIds">
                                        <ListItemAvatar>
                                            <CpvAvatar cpvCode={val}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={val.id.code}
                                            secondary={getLanguageString(val.label)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardContent>
                </>
            )}
        </Card>
    );
}

export default CpvCodesCard;
