import React from 'react';
import {Card, CardContent, Chip, Stack} from "@mui/material";
import {ProcurementNotice} from "../../types/ProcurementNoticeList";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import {procurementPlatformLink} from "../Icons/ProcurementPlatformAvatar";
import {getProcurementPlatform, ProcurementPlatformID} from "../../types/ProcurementPlatform";

export function hasLinks(notice: ProcurementNotice) {
    let links = filterLinks(notice.links);
    let applyLink = procurementPlatformLink(notice.links);
    return !!((links && links.length > 0) || applyLink);
     // Add notice.mailtoDomains?
}

export function makeURL(link: string) {
    try {
        return new URL(link)
    } catch (e) {
        try {
            return new URL("https://" + link)
        } catch (e2) {
            console.log(`[makeURL] could not resolve link '${link}' \n ${e} \n ${e2}`)
        }
        return null
    }
}

export function makeURLs(links: string[]) {
    return links.map((link) => makeURL(link)).filter(x => x != null) as URL[]
}

function filterLinks(links: string[] | null) {
    if (!links) return null
    const filtered = makeURLs(links)
        .filter((url) => getProcurementPlatform(url.href) === ProcurementPlatformID.UNKNOWN)
    return (filtered.length > 0) ? filtered : null
}

function LinksCard(props: { notice: ProcurementNotice }) {
    const notice = props.notice;
    const applyLink = procurementPlatformLink(props.notice.links)

    return (notice &&
        <Card
            variant="outlined"
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            <CardContent>
                <Stack sx={{justifyContent: 'space-between', flexGrow: '1', gap: 1}}>
                    {applyLink && (
                        <Chip
                            label="Apply"
                            icon={<DriveFileRenameOutlineRoundedIcon/>}
                            color="success"
                            component="a"
                            target="_blank"
                            href={applyLink}
                            clickable={true}
                        />
                    )}
                    {hasLinks(notice) && (
                        notice.links && filterLinks(notice.links)?.map((url, index) =>
                            <Chip
                                key={index}
                                label={url.hostname}
                                color="info"
                                component="a"
                                target="_blank"
                                href={url.href}
                                clickable={true}
                            />
                        )
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

export default LinksCard;