import * as React from "react";
import {Chip} from "@mui/material";
import {OrgNumber} from "../../types/ProcurementNoticeList";
import MapsUgcTwoToneIcon from '@mui/icons-material/MapsUgcTwoTone';

// Denmark : DK-21262498     http://localhost:3000/organization?orgNumber=DK-21262498
// Sweden  : SE-202100-4284  http://localhost:3000/organization?orgNumber=SE-202100-4284
// Norway  : BRREG-920472338 http://localhost:3000/organization?orgNumber=BRREG-920472338
function getCountryUrl(orgNumber: OrgNumber) {
    switch (orgNumber.registerID) {
        case "BRREG": return "https://proff.no/bransjes%C3%B8k?q=";
        case "SE": return "https://www.allabolag.se/bransch-s%C3%B6k?q=";
        case "DK": return "https://proff.dk/branches%C3%B8g?q="
    }
    return "https://proff.no/bransjes%C3%B8k?q=";
}

export function ProffNoChip(props: { orgNumber: OrgNumber | null | undefined}) {
    if (!props.orgNumber) return <></>
    let countryUrl = getCountryUrl(props.orgNumber);
    let href = countryUrl + props.orgNumber.id;
    let url = new URL(href);
    let domain = url.hostname;
    return (
        <Chip
            variant="outlined"
            size="medium"
            sx={{
                padding: '20px 10px 20px 10px;',
                alignSelf: 'start'
            }}
            label={domain}
            icon={<MapsUgcTwoToneIcon/>}
            color="secondary"
            component="a"
            target="_blank"
            href={href}
            clickable={true}
        />
    );
}
