import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {getPurchasingSystems} from "../api/ProcurementSystemApi";
import {noticeID, noticeIdName, ProcurementNotice} from "../types/ProcurementNoticeList";
import {Database, useIndexedDB} from "./useIndexedDB";

interface NoticesContextType<T> {
    notices: T[];
    idToNoticeMap: Map<string, T>;
    loading: boolean;
    error: Error | null;
}

// Create the context
const NoticesContext = createContext<NoticesContextType<ProcurementNotice> | undefined>(undefined);

type NoticesProviderProps = {
    children: ReactNode;
};

// Create a provider component
export const NoticesProvider = ({ children }: NoticesProviderProps) => {
    const [notices, setNotices] = useState<ProcurementNotice[]>([]);
    const [idToNoticeMap, setIdToNoticeMap] = React.useState<Map<string, ProcurementNotice>>(new Map());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const [supportOffline] = useState<boolean>(false);

    const {
        getAllValue,
        putBulkValue,
        isDBConnecting
    } = useIndexedDB(Database.name, [Database.notices]);

    useEffect(() => {
        function getNoticesFromApi() {
            try {
                console.log("INFO [API] Fetch from the api")
                getPurchasingSystems()
                    .then((data) => {
                        setNotices(data.hits)
                        if (supportOffline) {
                            const getIndex = (notice: object): string => noticeIdName(noticeID(notice as ProcurementNotice))
                            putBulkValue(Database.notices, data.hits, getIndex).then(() => {
                                console.log("INFO [API] Wrote items to database : " + data.hits.length)
                            })
                        }
                    })
            } catch (error) {
                console.log("ERROR [API] Failed to fetch data : " + error)
                setError(error as Error);
            }
        }

        const fetchNotices = async () => {
            try {
                if (notices.length > 0) {
                    console.log("INFO Data is read")
                } else if (supportOffline) {
                    console.log("INFO [DB] Read from database table")
                    getAllValue(Database.notices)
                        .then((data) => {
                            console.log("INFO [DB] Got items from database table : " + data.length)
                            if (data.length > 0) {
                                setNotices(data)
                            } else {
                                console.log("WARN [DB] No data in the database");
                                getNoticesFromApi();
                            }
                        })
                } else {
                    console.log("WARN [API] Get data from the api");
                    getNoticesFromApi();
                }
            } catch (error) {
                console.log("WARN [DB] Failed to read data : " + error);
                getNoticesFromApi();
            } finally {
                console.log("INFO [DB] Done with loading notices : " + notices.length);
            }
        };

        if (!isDBConnecting && notices.length === 0) {
            fetchNotices().then(() => {
                console.log("INFO Got notices : " + notices.length)
            });
        }
    }, [isDBConnecting, notices, setError, supportOffline, getAllValue, putBulkValue]);

    useEffect(() => {
        function buildMap() {
            const numberMap: Map<string, ProcurementNotice> = new Map();
            notices.forEach((record) => {
                let notice = noticeID(record)
                let number = noticeIdName(notice)
                if (number && !numberMap.has(number)) {
                    numberMap.set(number, record)
                }
            })
            setIdToNoticeMap(numberMap)
            setLoading(false);
        }

        if (notices.length > 0 && idToNoticeMap.size === 0)
            buildMap();
    }, [notices, idToNoticeMap])

    return (
        <NoticesContext.Provider value={{ notices, idToNoticeMap, loading, error }}>
            {children}
        </NoticesContext.Provider>
    );
};

// Custom hook to use the API context
export function useNotices<T>(): NoticesContextType<T> {
    const context = useContext(NoticesContext);
    if (context === undefined) {
        throw new Error('useNotices must be used within an NoticesProvider');
    }
    return context as NoticesContextType<T>;
}
