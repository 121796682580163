import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    gridQuickFilterValuesSelector,
    GridToolbarQuickFilter,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
import {useParams} from "../../hooks/searchParams.hooks";
import {SearchKeys} from "../../types/SearchKeys";
import {debounce} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const parseSearch = (searchText: string | null): string[] => {
    if (!searchText) return [];
    return searchText.split(' ').filter((word) => word !== '');
};
const formatSearch = (values: Array<string>) => values.join(' ');

// Inspired by https://github.com/pagopa/pagopa-selfcare-frontend/blob/main/src/pages/iban/list/QuickFilterCustom.tsx
export function SearchField() {
    const [searchString, setSearchString, clearSearchString] = useParams<string>(SearchKeys.searchString, null);

    const apiRef = useGridApiContext();
    const quickFilterValues = useGridSelector(apiRef, gridQuickFilterValuesSelector);

    // Holds the value in the field
    const [searchValue, setSearchValue] = useState(() =>
        formatSearch(quickFilterValues ?? [searchString])
    );

    // When the searchString is updated in the url the page reloads so put focus back here if there is text in the field
    const [shouldAutoFocus, setShouldAutoFocus] = useState<boolean>(!!searchString)

    const [prevQuickFilterValues, setPrevQuickFilterValues] = useState(quickFilterValues);
    function isSame(first: any[] | undefined, second: any[] | undefined): boolean {
        if (first === undefined) return second === undefined;
        if (second === undefined) return false;
        return formatSearch(first) === formatSearch(second);
    }

    useEffect(() => {
        function update(updated: any) {
            if (updated.length === 0) {
                if (!(searchString === null || searchString?.length === 0)) {
                    clearSearchString()
                }
            } else if (updated !== searchString) {
                setSearchString(updated)
            }
        }
        if (!isSame(prevQuickFilterValues, quickFilterValues)) {
            // The model of quick filter value has been updated
            setPrevQuickFilterValues(quickFilterValues);

            // Update the input value if needed to match the new model
            setSearchValue((prevSearchValue: any) => {
                    let updated = isSame(parseSearch(prevSearchValue), quickFilterValues)
                        ? prevSearchValue
                        : formatSearch(quickFilterValues ?? []);
                    update(updated)
                    return updated;
                }
            );
        }
    }, [prevQuickFilterValues, quickFilterValues, searchString, setSearchString, clearSearchString]);

    const updateSearchValue = useCallback(
        (newSearchValue: string) => {
            apiRef.current.setQuickFilterValues(parseSearch(newSearchValue));
        },
        [apiRef]
    );

    const debounceMs = 500;
    const debouncedUpdateSearchValue = useMemo(
        () => debounce(updateSearchValue, debounceMs),
        [updateSearchValue, debounceMs]
    );

    const handleSearchValueChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newSearchValue = event.target.value;
            setSearchValue(newSearchValue);
            debouncedUpdateSearchValue(newSearchValue);
        },
        [debouncedUpdateSearchValue]
    );

    return <GridToolbarQuickFilter
        value={searchValue}
        onChange={handleSearchValueChange}
        autoFocus={shouldAutoFocus}
        onFocus={() => setShouldAutoFocus(true)}
        onFocusCapture={() => setShouldAutoFocus(true)}
        onBlur={() => setShouldAutoFocus(false)}
        onBlurCapture={() => setShouldAutoFocus(false)}
        variant="outlined"
        sx={{
            flex: 5,
            minWidth: 0,
            alignSelf: "stretch",
            marginBottom: 0,
            paddingBottom: 0
        }}
        slotProps={{
            input: {
                endAdornment: (
                    <IconButton
                        aria-label={"clear input field"}
                        onClick={() => {
                            setSearchValue("")
                            apiRef.current.setQuickFilterValues([])
                        }}
                        edge="end"
                        size="small"
                    >
                        <ClearIcon fontSize="inherit"/>
                    </IconButton>
                )
            }
        }}
    />;
}
