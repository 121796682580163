import {makeFavIconUrl} from "../components/EventIcon";
import {makeURL} from "../components/Cards/LinksCard";

export interface ProcurementPlatform {
    id: ProcurementPlatformID,
    title: string,
    icon: string | null,
    borderColor: string,
    backgroundColor: string,
    hostnames: string[],
}

export enum ProcurementPlatformID{
    ARTIFIK = "ARTIFIK",
    ACHILLES = "ACHILLES",
    EUSUPPLY = "EUSUPPLY",
    MERCELL = "MERCELL",
    TENDSIGN = "TENDSIGN",
    UNKNOWN = "UNKNOWN"
}

let artifik = {
    id: ProcurementPlatformID.ARTIFIK,
    title: "Artifik",
    icon: makeFavIconUrl("app.artifik.no"),
    borderColor: "#0a213d",
    backgroundColor: "#0a213d",
    hostnames: ["app.artifik.no"],
} as ProcurementPlatform;

let achilles = {
    id: ProcurementPlatformID.ACHILLES,
    title: "Achilles",
    icon: makeFavIconUrl("www.achilles.com"),
    borderColor: "#e81424",
    backgroundColor: "white",
    hostnames: ["achilles.com", "www.achilles.com", "www.transq.com", "www.sellihca.no"],
} as ProcurementPlatform;

let tendSign = {
    id: ProcurementPlatformID.TENDSIGN,
    title: "TendSign",
    icon: "https://tendsign.no/favicon.ico",
    borderColor: "#7854b4",
    backgroundColor: "white",
    hostnames: ["opic.com", "tendsign.com", "tendsign.no"],
} as ProcurementPlatform;

let euSupply = {
    id: ProcurementPlatformID.EUSUPPLY,
    title: "EuSupply",
    icon: makeFavIconUrl("eu.eu-supply.com"),
    borderColor: "#080484",
    backgroundColor: "#080484",
    hostnames: ["eu.eu-supply.com"],
} as ProcurementPlatform;

let mercell = {
    id: ProcurementPlatformID.MERCELL,
    title: "Mercell",
    icon: makeFavIconUrl("mercell.com"),
    borderColor: "black",
    backgroundColor: "white",
    hostnames: ["permalink.mercell.com"],
} as ProcurementPlatform;

let unknownProcurementPlatform = {
    id: ProcurementPlatformID.UNKNOWN,
    title: "Unknown",
    icon: null,
    borderColor: "text.secondary",
    backgroundColor: "text.secondary",
    hostnames: [],
} as ProcurementPlatform;

export const procurementPlatforms = [
    artifik,
    achilles,
    tendSign,
    euSupply,
    mercell,
    unknownProcurementPlatform
];

export function isProcurementPlatformIncluded(platformID: ProcurementPlatformID, excludedPlatforms: string | null) {
    if (!excludedPlatforms) {
        return true
    }
    return !excludedPlatforms.split(",").includes(platformID);
}

export function toProcurementPlatform(id: string): ProcurementPlatform | null {
    let category = procurementPlatforms.find((it) => it.id === id);
    return category ? category : null
}

export function getProcurementPlatforms(ids: string[]) {
    return ids.map((it) => toProcurementPlatform(it)).filter((it) => it !== null)
}

export function getProcurementPlatformsFromString(ids: string | null) {
    if (!ids) return []
    return getProcurementPlatforms(ids.split(","))
}

export function procurementPlatform(platform: ProcurementPlatformID) {
    switch (platform) {
        case ProcurementPlatformID.UNKNOWN: return unknownProcurementPlatform
        case ProcurementPlatformID.ARTIFIK: return artifik
        case ProcurementPlatformID.ACHILLES: return achilles
        case ProcurementPlatformID.EUSUPPLY: return euSupply
        case ProcurementPlatformID.TENDSIGN: return tendSign
        case ProcurementPlatformID.MERCELL: return mercell
    }
}

export function getProcurementPlatform(link: string | null) {
    if (!link) return ProcurementPlatformID.UNKNOWN
    let url = makeURL(link)
    if (!url) return ProcurementPlatformID.UNKNOWN
    if (url.href.toLowerCase().includes("eu.eu-supply.com/ctm/company/companyinformation"))
        return ProcurementPlatformID.UNKNOWN
    if (url.href.toLowerCase().includes("https://permalink.mercell.com/0.aspx"))
        return ProcurementPlatformID.UNKNOWN
    switch (url.hostname) {
        case "app.artifik.no":
            return ProcurementPlatformID.ARTIFIK
        case "www.achilles.com":
            return ProcurementPlatformID.ACHILLES
        case "www.transq.com":
            return ProcurementPlatformID.ACHILLES
        case "achilles.com":
            return ProcurementPlatformID.ACHILLES
        case "www.sellihca.no":
            return ProcurementPlatformID.ACHILLES
        case "eu.eu-supply.com":
            return ProcurementPlatformID.EUSUPPLY
        case "permalink.mercell.com":
            return ProcurementPlatformID.MERCELL
        case "opic.com":
            return ProcurementPlatformID.TENDSIGN
        case "tendsign.com":
            return ProcurementPlatformID.TENDSIGN
        case "tendsign.no":
            return ProcurementPlatformID.TENDSIGN
        default :
            return ProcurementPlatformID.UNKNOWN
    }
}
